import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { TPlainTextKey } from '../../../Translations';
import { RegisterResource } from '../DiscoveryPortal/RegisterResourceStore/Types';
import LockedAccess from './LockedAccess';
import SignIn from './SignIn';
import SignUp from './SignUp';
import { authenticationFormKind } from './Types';

interface Props {
  registerResource: RegisterResource;
  readyLockedAccess: (message: Maybe<TPlainTextKey>) => void;
}

const RequireAuthentication: React.FC<Props> = ({ registerResource, readyLockedAccess }) => {
  const { sharedInvitation, auth, emailAddress } = registerResource.payload;
  const { links } = registerResource;
  const authForm = authenticationFormKind(auth);

  switch (authForm.kind) {
    case 'sso-sign-in':
    case 'password-sign-in':
      return (
        <SignIn
          authForm={authForm}
          sharedInvitation={sharedInvitation}
          auth={auth}
          links={links}
          emailAddress={emailAddress}
          readyLockedAccess={readyLockedAccess}
        />
      );
    case 'sso-sign-up':
    case 'password-sign-up':
      return (
        <SignUp
          authForm={authForm}
          sharedInvitation={sharedInvitation}
          auth={auth}
          links={links}
          emailAddress={emailAddress}
        />
      );
    case 'locked-access':
      return <LockedAccess store={authForm.store} sharedInvitation={sharedInvitation} />;
  }
};

export default observer(RequireAuthentication);
