import { safeStringify } from 'jsonous';
import { observer } from 'mobx-react';
import { AppyError } from '../../Appy';
import {
  AutoLaunchableContext,
  AutoLaunchableSource,
} from '../../components/AutoLaunchable/AutoLaunchableContext';
import Loading from '../../components/Loading';
import PageLayoutWrapper from '../../Native/PageLayoutWrapper';
import registrationInvitationStore from '../../RegistrationInvitation/RegistrationInvitationStore';
import useRegistrationInvitationStore from '../../RegistrationInvitation/RegistrationInvitationStore/UseRegistrationInvitationStore';
import { autoEnrollProgramId } from '../../RegistrationInvitation/Types';
import AuthView from './AuthView';
import UseCaseView from './UseCaseView';

interface RegistrationProps {
  guid: string;
}

function Registration({ guid }: RegistrationProps) {
  useRegistrationInvitationStore(guid);

  switch (registrationInvitationStore.state.kind) {
    case 'registration-invitation-store-state-initialized':
    case 'registration-invitation-store-state-loading':
      return <Loading />;
    case 'registration-invitation-store-state-loaded':
      const registerResource = registrationInvitationStore.state.registrationInvitation;
      const contextValue: AutoLaunchableSource = autoEnrollProgramId(registerResource)
        .map<AutoLaunchableSource>(() => 'share_url_with_auto_enroll_program')
        .getOrElseValue('discovery_portal');

      return (
        <AutoLaunchableContext.Provider value={contextValue}>
          <PageLayoutWrapper>
            <UseCaseView />
          </PageLayoutWrapper>
        </AutoLaunchableContext.Provider>
      );
    case 'registration-invitation-store-state-error':
      return <_ErrorView error={registrationInvitationStore.state.error} guid={guid} />;
  }
}

function _ErrorView({ error, guid }: { error: AppyError; guid: string }) {
  if (error.kind === 'bad-status' && error.response.status === 401) {
    return <AuthView guid={guid} />;
  }
  return <_DisplayErrorView error={error} />;
}

function _DisplayErrorView({ error }: { error: AppyError }) {
  return <div>Error: ${safeStringify(error)}</div>;
}

export default observer(Registration);
