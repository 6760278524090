import {
  CaretDownIcon,
  Menu,
  NavBarItem,
  NavBarLink,
  useOpenable,
} from '@execonline-inc/execonline-ui';
import { fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import { useRef } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { handleEnterKeyPress } from '../../../../Accessibility/KeyboardEvents';
import { discoveryStore } from '../../../../DiscoveryStore';
import useDiscoveryStore from '../../../../DiscoveryStore/UseDiscoveryStore';
import { T } from '../../../../Translations';
import { NavigationMenuItems } from '../Common';
import { dropdownMenuItems } from './Types';

const menuId = 'useCaseNavigationMenu';

function UseCaseDropdownMenu() {
  useDiscoveryStore();
  const anchorElementRef = useRef<HTMLAnchorElement>(null);
  const { isOpen, onClose, onOpenChange } = useOpenable();

  return (
    <>
      <Route
        path="/register"
        children={({ match }: RouteComponentProps<{}>) => {
          return (
            <NavBarItem
              aria-haspopup="menu"
              aria-expanded={isOpen}
              data-testid="menu-bar_learning-collections-menu-item"
            >
              <NavBarLink
                data-testid="menu-bar_learning-collections-menu-item-link"
                id={menuId}
                ref={anchorElementRef}
                className="flex flex-shrink-0 items-center"
                active={fromNullable(match).isJust()}
                onClick={onOpenChange}
                onKeyDown={handleEnterKeyPress(onOpenChange)}
              >
                <T kind="Learning Collections" />
                <CaretDownIcon className="ml-2" size="xs" color="inherit" />
              </NavBarLink>
              <Menu
                data-testid="learning-collections-menu_dropdown"
                aria-labelledby={menuId}
                className="max-h-72 max-w-[28rem]"
                open={isOpen}
                anchorElement={anchorElementRef.current}
                onClose={onClose}
              >
                <NavigationMenuItems
                  items={dropdownMenuItems(discoveryStore.learningCollections)}
                />
              </Menu>
            </NavBarItem>
          );
        }}
      />
    </>
  );
}

export default observer(UseCaseDropdownMenu);
