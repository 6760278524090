import { useEffect } from 'react';
import useCaseStore from '.';
import { findLink } from '../../LinkyLinky';
import registrationInvitationStore from '../RegistrationInvitationStore';

function useUseCaseStore() {
  useEffect(() => {
    if (useCaseStore.state.kind === 'use-case-store-state-loaded') {
      return;
    }

    findLink('use-case', registrationInvitationStore.links).do(useCaseStore.load);
  }, [registrationInvitationStore.links]);
}

export default useUseCaseStore;
