import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import autoLaunchablesStore from '../../../AutoLaunchables/AutoLaunchablesStore';
import useAutoLaunchablesStore from '../../../AutoLaunchables/AutoLaunchablesStore/UseAutoLaunchablesStore';
import AutoLaunchableModalView from '../../../components/AutoLaunchableModal/AutoLaunchableModalView';
import ModalWrapping from '../../../components/AutoLaunchableModal/ModalWrapping';
import { findLink } from '../../../LinkyLinky';
import { UponCompletion } from '../../../SegmentStore/Types';

function handleClose(uponCompletion: Maybe<UponCompletion>) {
  uponCompletion.cata({
    Just: () => {
      autoLaunchablesStore.reload();
      window.location.reload();
    },
    Nothing: () => {
      autoLaunchablesStore.reload();
    },
  });
}

function RegistrationAutoLaunchable() {
  useAutoLaunchablesStore('discovery_portal');
  const state = autoLaunchablesStore.state;
  if (state.kind !== 'auto-launchables-store-ready') {
    return <></>;
  }
  const { title, segments } = state.autoLaunchablesResource.payload;
  const currentSegment = segments.find((s) => s.isCurrentSegment);
  if (!currentSegment) {
    return <></>;
  }
  const link = findLink('participant-postponables', currentSegment.links);
  return (
    <ModalWrapping
      title={title.text}
      closeM={link.map((link) => () => autoLaunchablesStore.postpone(link))}
    >
      <AutoLaunchableModalView currentSegment={currentSegment} onClose={handleClose} />
    </ModalWrapping>
  );
}

export default observer(RegistrationAutoLaunchable);
