import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import ProgramSequenceProductBadge from '../../../Native/AEP/Common/Experience/ExperienceFooter/ExperienceProductBadge/ProgramSequenceProductBadge';
import { ExperienceDictionaryEntryResponse } from '../../ExperiencesDictionary/Types';
import { usePortalExperienceContext } from '../PortalExperience/UsePortalExperienceContext';
import ExperienceProductDetailsBadge from './ExperienceProductDetailsBadge';
import ProgramProductBadge from './ProgramProductBadge';

function ExperienceProductBadge() {
  const experience = usePortalExperienceContext();

  return experience
    .andThen((lookupResults) => lookupResults.experience)
    .map((experienceResource) => <_ExperienceProductBadge experience={experienceResource} />)
    .getOrElse(emptyFragment);
}

const _ExperienceProductBadge = observer(
  ({ experience }: { experience: ExperienceDictionaryEntryResponse }) => {
    switch (experience.payload.offeringType) {
      case 'aep':
      case 'epc':
      case 'msuite':
        return <ProgramProductBadge />;
      case 'program-sequence':
        return <ProgramSequenceProductBadge />;
      case 'coaching':
      case 'group-coaching':
        return <ExperienceProductDetailsBadge />;
    }
  },
);

export default observer(ExperienceProductBadge);
