import { explicitMaybe, stringLiteral } from '@execonline-inc/decoders';
import { AlreadyTranslatedText } from '@execonline-inc/translations';
import { identity } from '@kofno/piper';
import { array, Decoder, field, number, oneOf, succeed } from 'jsonous';
import { just, Maybe, nothing } from 'maybeasy';
import { action, makeObservable, observable } from 'mobx';
import { Task } from 'taskarian';
import { callApi } from '../../../../Appy';
import { alreadyTranslatedTextDecoder } from '../../../../Decoders';
import {
  GroupCoachingSessionResources,
  ProgramSequenceProductDetailsSections,
} from '../../../../Native/AEP/Common/Experience/Types';
import {
  groupSessionResourcesDecoder,
  programSequenceProductDetailsSectionsDecoder,
  schoolPartnerResourceDecoder,
} from '../../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Experiences/Decoders';
import { resourceDecoder } from '../../../../Resource/Decoders';
import { Link, Resource } from '../../../../Resource/Types';
import { SchoolPartnerResource } from '../../../../SegmentStore/Types';

export interface CoachingProductDetails {
  kind: 'leadership-coaching';
  keyBenefitsHtml: Maybe<AlreadyTranslatedText>;
  pullQuoteReviewsHtml: Maybe<AlreadyTranslatedText>;
  howBestToUseHtml: Maybe<AlreadyTranslatedText>;
}

const coachingProductDetailsDecoder: Decoder<CoachingProductDetails> = succeed({})
  .assign('kind', field('kind', stringLiteral('leadership-coaching')))
  .assign(
    'keyBenefitsHtml',
    field('key_benefits_html', explicitMaybe(alreadyTranslatedTextDecoder)),
  )
  .assign(
    'pullQuoteReviewsHtml',
    field('pull_quote_reviews_html', explicitMaybe(alreadyTranslatedTextDecoder)),
  )
  .assign(
    'howBestToUseHtml',
    field('how_best_to_use_html', explicitMaybe(alreadyTranslatedTextDecoder)),
  );

export interface ProgramSequenceProductDetails {
  kind: 'program-sequence';
  schoolPartnerResources: SchoolPartnerResource[];
  sections: ProgramSequenceProductDetailsSections[];
  sectionsCount: number;
}

const programSequenceProductDetailsDecoder: Decoder<ProgramSequenceProductDetails> = succeed({})
  .assign('kind', field('kind', stringLiteral('program-sequence')))
  .assign(
    'schoolPartnerResources',
    field('school_partner_resources', array(schoolPartnerResourceDecoder)),
  )
  .assign('sections', field('sections', array(programSequenceProductDetailsSectionsDecoder)))
  .assign('sectionsCount', field('sections_count', number));

export interface GroupCoachingProductDetails {
  kind: 'group-coaching';
  id: number;
  title: Maybe<AlreadyTranslatedText>;
  description: Maybe<AlreadyTranslatedText>;
  groupCoachingSessions: Maybe<Resource<GroupCoachingSessionResources>>;
  remainingSeats: Maybe<number>;
  sessionDurationInMinutes: number;
}

const groupCoachingProductDetailsDecoder: Decoder<GroupCoachingProductDetails> = succeed({})
  .assign('kind', field('kind', stringLiteral('group-coaching')))
  .assign('id', field('id', number))
  .assign('title', field('title', explicitMaybe(alreadyTranslatedTextDecoder)))
  .assign(
    'groupCoachingSessions',
    field('group_coaching_sessions', explicitMaybe(resourceDecoder(groupSessionResourcesDecoder))),
  )
  .assign('remainingSeats', field('remaining_seats', explicitMaybe(number)))
  .assign('sessionDurationInMinutes', field('session_duration_in_minutes', number))
  .assign('description', field('description', explicitMaybe(alreadyTranslatedTextDecoder)));

export type ProductDetails =
  | CoachingProductDetails
  | ProgramSequenceProductDetails
  | GroupCoachingProductDetails;

const productDetailsDecoder: Decoder<ProductDetails> = oneOf([
  coachingProductDetailsDecoder.map<ProductDetails>(identity),
  programSequenceProductDetailsDecoder.map<ProductDetails>(identity),
  groupCoachingProductDetailsDecoder.map<ProductDetails>(identity),
]);

export type ProductDetailsValue = Maybe<Resource<ProductDetails>>;
type ProductDetailsStoreState = Record<string, ProductDetailsValue>;

export class ProductDetailsStore {
  @observable state: ProductDetailsStoreState = {};

  constructor() {
    makeObservable(this);
  }

  getProductDetails = async (link: Link): Promise<ProductDetailsValue> => {
    const productDetails = this.state[link.href];
    if (productDetails) {
      return productDetails;
    }

    return callApi(resourceDecoder(productDetailsDecoder), {}, link)
      .andThen((productDetails) => {
        const result = just(productDetails);
        this.setProductDetails(link.href, result);
        return Task.succeed(result);
      })
      .orElse((err) => {
        console.warn('Product details failed to fetch', err);
        this.setProductDetails(link.href, nothing());
        return Task.succeed(nothing());
      })
      .resolve();
  };

  @action
  private setProductDetails = (key: string, productDetails: ProductDetailsValue) => {
    this.state[key] = productDetails;
  };
}

const productDetailsStore = new ProductDetailsStore();
export default productDetailsStore;
