import {
  AppsIcon,
  emptyFragment,
  IconButton,
  Menu,
  MenuSection,
  useMediaQuery,
  useOpenable,
} from '@execonline-inc/execonline-ui';
import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import { useRef } from 'react';
import { T } from '../../../Translations';
import { useTranslationsContext } from '../../../Translations/UseTranslationsContext';
import { DropdownListItem } from '../../../components/Dropdown/Types';
import { getIconSize } from '../../../components/Platform/NavigationBar/ApplicationMenu/Types';
import { NavigationMenuItems } from '../../../components/Platform/NavigationBar/Common';
import navigationStore from './NavigationStore';

function ApplicationMenu() {
  return fromEmpty(navigationStore.applicationDropDownItems)
    .map((items) => <_ApplicationMenu items={items} />)
    .getOrElse(emptyFragment);
}

const menuId = 'applicationNavigationMenu';

const _ApplicationMenu = observer((props: { items: ReadonlyArray<DropdownListItem> }) => {
  const anchorElementRef = useRef<HTMLButtonElement>(null);
  const { isOpen, onClose, onOpenChange } = useOpenable();
  const breakPoint = useMediaQuery();
  const { translate } = useTranslationsContext();

  return (
    <>
      <IconButton
        data-testid="menu-bar_application-menu-item"
        id={menuId}
        aria-haspopup="menu"
        aria-expanded={isOpen}
        ref={anchorElementRef}
        className="mr-4"
        title={translate('Platform Navigation Dropdown Menu')}
        aria-label={translate('Platform Navigation Dropdown Menu')}
        onClick={onOpenChange}
      >
        <AppsIcon size={getIconSize(breakPoint)} color="black" />
      </IconButton>
      <Menu
        data-testid="application-menu_dropdown"
        aria-labelledby={menuId}
        open={isOpen}
        anchorElement={anchorElementRef.current}
        onClose={onClose}
      >
        <MenuSection title={<T kind="Switch to" />}>
          <NavigationMenuItems items={props.items} />
        </MenuSection>
      </Menu>
    </>
  );
});

export default observer(ApplicationMenu);
