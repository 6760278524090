import { action, computed, makeObservable, observable } from 'mobx';
import { AppyError, callApi } from '../../Appy';
import { UseCase } from '../../Native/AEP/Common/Experience/Types';
import { useCaseDecoder } from '../../Native/AEP/DiscoveryPortal/ExperienceSelection/Experiences/Decoders';
import { resourceDecoder } from '../../Resource/Decoders';
import { Link, Resource } from '../../Resource/Types';
import { UseCaseStoreState } from './Types';

export class UseCaseStore {
  @observable
  state: UseCaseStoreState = {
    kind: 'use-case-store-state-initialized',
  };

  constructor() {
    makeObservable(this);
  }

  @action
  load = (link: Link) => {
    if (this.isLoading) {
      return;
    }

    this.setLoading(link);

    callApi(resourceDecoder(useCaseDecoder), {}, link).fork(
      (err) => {
        console.error(err);
        this.setError(link, err);
      },
      (useCase) => {
        this.setLoaded(link, useCase);
      },
    );
  };

  @computed
  get isLoading() {
    return this.state.kind === 'use-case-store-state-loading';
  }

  @computed
  get links(): ReadonlyArray<Link> {
    switch (this.state.kind) {
      case 'use-case-store-state-initialized':
      case 'use-case-store-state-loading':
      case 'use-case-store-state-error':
        return [];
      case 'use-case-store-state-loaded':
        return this.state.useCase.links;
    }
  }

  @action
  private setLoading(link: Link) {
    this.state = {
      kind: 'use-case-store-state-loading',
      link,
    };
  }

  @action
  private setLoaded(link: Link, useCase: Resource<UseCase>) {
    this.state = {
      kind: 'use-case-store-state-loaded',
      link,
      useCase,
    };
  }

  @action
  private setError(link: Link, error: AppyError) {
    this.state = {
      kind: 'use-case-store-state-error',
      link,
      error,
    };
  }
}

const useCaseStore = new UseCaseStore();
export default useCaseStore;
