import { Maybe, just, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { AppyError, callApi } from '../Appy';
import { Avatar, avatarFrom, avatarInitials } from '../Avatar';
import { CountryOption } from '../CountryOptionsStore/Types';
import { CountryRegionOption } from '../CountryRegionOptionsStore/Types';
import { ContingentStore } from '../CurrentUser/Context/WhenUserDataLoaded/Types';
import { CurrentUserResource } from '../CurrentUser/Types';
import { findLink } from '../LinkyLinky';
import { PersonStoreContract } from '../Person/types';
import { Link } from '../Resource/Types';
import { profileResourceDecoder } from './Decoders';
import { ProfileStoreState, ProfileStoreStateLoaded } from './States';
import { ProfileResource } from './Types';

class ProfileStore {
  @observable state: ProfileStoreState = { kind: 'profile-store-initialized' };

  constructor() {
    makeObservable(this);
  }

  @action reset = (): void => {
    this.state = { kind: 'profile-store-initialized' };
  };

  @action private loading = (): void => {
    this.state = { kind: 'profile-store-loading' };
  };

  @action load = (link: Link, reload = false): void => {
    if (this.isLoading) {
      return;
    }

    if (this.isLoaded && !reload) {
      return;
    }

    this.loading();

    callApi<ProfileResource>(profileResourceDecoder, {}, link).fork(this.errored, this.loaded);
  };

  @action private loaded = (resource: ProfileResource): void => {
    this.state = { kind: 'profile-store-loaded', resource };
  };

  @action private errored = (error: AppyError): void => {
    this.state = { kind: 'profile-store-errored', error };
  };

  @computed get resource(): Maybe<ProfileResource> {
    return this.isLoadedGuard() ? just(this.state.resource) : nothing();
  }

  @computed get avatar(): Avatar {
    const fallbackInitials = 'ME';

    return this.resource
      .map((profileResource) => {
        const links = profileResource.links;
        const initials = profileResource.payload.initials;
        return avatarFrom(findLink('avatar', links), initials, fallbackInitials);
      })
      .getOrElse(() => avatarInitials(fallbackInitials));
  }

  @computed get personStoreContract(): Maybe<PersonStoreContract> {
    return this.resource.map(({ payload, links }) => ({
      id: payload.userId,
      kind: 'user' as const,
      name: payload.name,
      photo: findLink('avatar', links),
      initials: payload.initials,
      shortName: payload.shortName,
      email: just(payload.email),
      avatar: this.avatar,
      linkedInPage: nothing<string>(),
      facebookPage: nothing<string>(),
      twitterPage: nothing<string>(),
      position: nothing<string>(),
      division: nothing<string>(),
      workPhone: nothing<string>(),
      extension: nothing<string>(),
      cellPhone: nothing<string>(),
      organization: nothing<string>(),
      country: nothing<CountryOption>(),
      region: nothing<CountryRegionOption>(),
    }));
  }

  @computed get isLoaded(): boolean {
    return this.state.kind === 'profile-store-loaded';
  }

  @computed get isLoading(): boolean {
    return this.state.kind === 'profile-store-loading';
  }

  private isLoadedGuard(): this is ProfileStore & { state: ProfileStoreStateLoaded } {
    return this.isLoaded;
  }
}

class ContingentProfileStoreWrapper implements ContingentStore {
  store: ProfileStore;

  constructor(store: ProfileStore) {
    this.store = store;
  }

  @computed get resolution(): ContingentStore['resolution'] {
    if (this.store.isLoading) {
      return 'loading';
    }

    if (this.store.isLoaded) {
      return 'finished';
    }

    return 'waiting';
  }

  loading(currentUserResource: CurrentUserResource): void {
    findLink('profile', currentUserResource.links).do(this.store.load);
  }

  refreshing(currentUserResource: CurrentUserResource): void {
    findLink('profile', currentUserResource.links).do((link) => this.store.load(link, true));
  }
}

export type { ProfileStore };

export const profileStore = new ProfileStore();
export const contingentProfileStoreWrapper = new ContingentProfileStoreWrapper(profileStore);
