import { Error } from '../ErrorHandling';
import { Resource } from '../Resource/Types';
import { AlreadyTranslatedText } from '../Translations';

export interface ScheduledToast {
  activeAt: Date;
  inactiveAt: Date;
  startAt: Date;
  endAt: Date;
  title: AlreadyTranslatedText;
  id: number;
  uuid: string;
  kind: 'event-notification' | 'reminder-notification';
  conferenceRoom: ToastConferenceRoomResource;
}

export type ToastConferenceRoom =
  | ExternalConferenceRoom
  | GoToWebinarConferenceRoom
  | EmbedToastConferenceRoom;

export type ToastConferenceRoomResource =
  | ExternalConferenceRoomResource
  | GoToWebinarConferenceRoomResource
  | EmbedToastConferenceRoomResource;

export type EmbedToastConferenceRoomResource = Resource<EmbedToastConferenceRoom>;
export type GoToWebinarConferenceRoomResource = Resource<GoToWebinarConferenceRoom>;
export type ExternalConferenceRoomResource = Resource<ExternalConferenceRoom>;

export interface ExternalConferenceRoom {
  kind: 'external-live-meeting';
}

export interface GoToWebinarConferenceRoom {
  kind: 'gtw-live-meeting';
}

export interface EmbedToastConferenceRoom {
  kind: 'embed-toast-live-meeting';
}

export interface EventToast extends ScheduledToast {
  kind: 'event-notification';
}

export interface ReminderToast extends ScheduledToast {
  kind: 'reminder-notification';
}

export type Toast = EventToast | ReminderToast;

export interface Toasts {
  expiresAt: Date;
  toasts: Toast[];
}

export type ToastsResource = Resource<Toasts>;

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
}

interface Reloading {
  kind: 'reloading';
  toastsResource: ToastsResource;
}

interface Ready {
  kind: 'ready';
  toastsResource: ToastsResource;
}

export type State = Waiting | Loading | Reloading | Error | Ready;

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const ready = (toastsResource: ToastsResource): Ready => ({
  kind: 'ready',
  toastsResource,
});

export const reloading = (toastsResource: ToastsResource): Reloading => ({
  kind: 'reloading',
  toastsResource,
});
