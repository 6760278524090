import {
  Decoder,
  array,
  boolean,
  dateISO,
  field,
  nullable,
  number,
  string,
  succeed,
} from 'jsonous';
import { resourceDecoder } from '../Resource/Decoders';
import { Organization, OrganizationResource } from './Types';

const organizationDecoder: Decoder<Organization> = succeed({})
  .assign('id', field('id', number))
  .assign('accountUid', field('account_uid', nullable(string)))
  .assign('name', field('name', string))
  .assign('plans', field('active_license_titles', array(string)))
  .assign(
    'progressEmailsCommunicationPreference',
    field('progress_emails_communication_preference', string),
  )
  .assign(
    'registrationInvitationCommunicationPreference',
    field('registration_invitation_communication_preference', string),
  )
  .assign('chatPreference', field('chat_preference', string))
  .assign('activeIntegrations', field('active_integrations', array(string)))
  .assign('university', field('university', boolean))
  .assign('createdAt', field('created_at', dateISO));

export const organizationResourceDecoder: Decoder<OrganizationResource> =
  resourceDecoder(organizationDecoder);
