import { Radio, RadioGroup, TextField } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../Translations';
import { listOfOptions } from './utils';

interface Props {
  selectedOption: string;
  setSelectedOption: (value: string) => void;
  otherReason: string;
  setOtherReason: (value: string) => void;
}

function SelectOptions({ selectedOption, setSelectedOption, otherReason, setOtherReason }: Props) {
  return (
    <div>
      <RadioGroup
        name="dropReason"
        color="teal"
        size="md"
        value={selectedOption}
        onChange={setSelectedOption}
      >
        {listOfOptions.map((item, index) => (
          <Radio key={index} value={item}>
            {item === 'Other (Please explain)' ? (
              <TextField
                fullWidth
                className="max-w-[80%] focus:border-transparent focus:outline-none focus:ring-0"
                value={otherReason}
                onChange={(e) => setOtherReason(e.target.value)}
                placeholder={item}
                disabled={selectedOption !== 'Other (Please explain)'}
              />
            ) : (
              <T kind={item} />
            )}
          </Radio>
        ))}
      </RadioGroup>
    </div>
  );
}

export default observer(SelectOptions);
