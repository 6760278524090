import { stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import { Decoder, array, boolean, date, field, number, oneOf, string, succeed } from 'jsonous';
import { profileResourceDecoder } from '../../ProfileStore/Decoders';
import { resourceDecoder } from '../../Resource/Decoders';
import {
  DateString,
  OnDemand,
  StartDate,
  StartsAnytime,
  TeamMember,
  TeamMemberResource,
  TeamSummary,
  TeamSummaryResource,
  TeamsMetadata,
  TeamsSummaryResource,
} from '../Types';

const startsAnytimeDecoder: Decoder<StartsAnytime> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('starts-anytime')),
);

const onDemandDecoder: Decoder<OnDemand> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('on-demand')),
);

const dateStringDecoder: Decoder<DateString> = succeed({})
  .assign('kind', field('kind', stringLiteral('date-string')))
  .assign('date', field('date', date));

const startDateDecoder: Decoder<StartDate> = oneOf<StartDate>([
  startsAnytimeDecoder.map<StartDate>(identity),
  onDemandDecoder.map<StartDate>(identity),
  dateStringDecoder.map<StartDate>(identity),
]);

const teamMemberDecoder: Decoder<TeamMember> = succeed({})
  .assign('id', field('id', number))
  .assign('email', field('email', string))
  .assign('profile', field('profile', profileResourceDecoder));

export const teamMemberResourceDecoder: Decoder<TeamMemberResource> =
  resourceDecoder(teamMemberDecoder);

export const teamSummaryDecoder: Decoder<TeamSummary> = succeed({})
  .assign('name', field('name', string))
  .assign('courseId', field('course_id', number))
  .assign('startDate', field('start_date', startDateDecoder))
  .assign('id', field('id', number))
  .assign('teamEventCount', field('team_event_count', number))
  .assign('assignmentCount', field('assignment_count', number))
  .assign('hasConferenceRoom', field('has_conference_room', boolean))
  .assign('teamMembers', field('team_members', array(teamMemberResourceDecoder)));

const teamsSummaryMetadataDecoder: Decoder<TeamsMetadata> = succeed({})
  .assign('perPage', field('per_page', number))
  .assign('pageCount', field('page_count', number))
  .assign('currentPage', field('current_page', number))
  .assign('resultsCount', field('results_count', number));

export const teamResourceDecoder: Decoder<TeamSummaryResource> =
  resourceDecoder(teamSummaryDecoder);

export const teamsResourceDecoder: Decoder<TeamsSummaryResource> = resourceDecoder(
  array(teamResourceDecoder),
).assign('metadata', field('metadata', teamsSummaryMetadataDecoder));
