import {
  CloseIcon,
  DrawerBody,
  DrawerHeader,
  IconButton,
  List,
} from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import ApplicationBrand from '../../../components/Platform/NavigationBar/ApplicationBrand';
import LearningMenu from '../../../components/Platform/NavigationBar/SidebarNavigation/MainNavigation/LearningMenu';
import MainListMenu from '../../../components/Platform/NavigationBar/SidebarNavigation/MainNavigation/MainListMenu';
import { useTranslationsContext } from '../../../Translations/UseTranslationsContext';
import ApplicationMenu from './ApplicationMenu';
import SupportMenu from './SupportMenu';

interface Props {
  onSubMenuOpen: () => void;
  onClose: () => void;
}

function MainNavigation({ onSubMenuOpen, onClose }: Props) {
  const { translate } = useTranslationsContext();

  return (
    <>
      <DrawerHeader>
        <ApplicationMenu />
        <div className="flex w-full justify-center">
          <ApplicationBrand />
        </div>
        <IconButton aria-label={translate('Close Menu')} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerBody>
        <List role="menubar" aria-label={translate('Sidebar')}>
          <LearningMenu onClick={onSubMenuOpen} />
          <MainListMenu onClick={onClose} />
          <SupportMenu onClick={onClose} />
        </List>
      </DrawerBody>
    </>
  );
}

export default observer(MainNavigation);
