import { readVarT } from '@execonline-inc/environment';
import { appendAuthHeader, withReplacedAuthHeader } from '@execonline-inc/unified-auth.private';
import { assertNever } from '@kofno/piper';
import { Header, HttpError, RequestBuilder, del, header, post, put, toHttpTask } from 'ajaxian';
import { Decoder } from 'jsonous';
import { ok } from 'resulty';
import { Task } from 'taskarian';
import { findLinkT } from '../LinkyLinky';
import { Link, Rel } from '../Resource/Types';
import { handleRecovery } from '../Session';
import { requestSessionData } from '../Session/Reactions';
import { sessionStore } from '../Session/Store';

export interface MissingApplicationId {
  kind: 'missing-application-id';
}

export interface MissingApiCompatibility {
  kind: 'missing-api-compatibility';
}

export type AppyError = HttpError | MissingApplicationId | MissingApiCompatibility;

const missingApplicationId = (): MissingApplicationId => ({ kind: 'missing-application-id' });

const missingApiCompatibility = (): MissingApiCompatibility => ({
  kind: 'missing-api-compatibility',
});

const appId = readVarT('VITE_APPLICATION_ID').mapError<AppyError>(missingApplicationId);

const apiCompatibility =
  readVarT('VITE_API_COMPATIBILITY').mapError<AppyError>(missingApiCompatibility);

const applicationIdHeader = appId.map((id) => header('application-id', id));

const apiCompatibilityHeader = apiCompatibility.map((compatibility) =>
  header('api-compatibility', compatibility),
);

const requestBuilderData = (link: Link, payload: {}): {} | undefined => {
  switch (link.method) {
    case 'get':
    case 'head':
    case 'options':
      return undefined;
    case 'delete':
    case 'patch':
    case 'post':
    case 'put':
      return payload;
  }
};

export const clientHeadersT = (): Task<AppyError, Header[]> =>
  Task.succeed<AppyError, Header[]>([])
    .assign('appIdHeader', applicationIdHeader)
    .assign('apiCompHeader', apiCompatibilityHeader)
    .map(({ appIdHeader, apiCompHeader }) => [appIdHeader, apiCompHeader])
    .map(appendAuthHeader(sessionStore.sessionToken));

const toRequest = (link: Link, payload: {}, headers: Array<Header>): RequestBuilder<string> =>
  new RequestBuilder({
    url: link.href,
    decoder: ok,
    data: requestBuilderData(link, payload),
    method: link.method,
    timeout: 0,
    headers,
    withCredentials: true,
  });

export function request(link: Link, payload: {}): Task<AppyError, RequestBuilder<string>> {
  return clientHeadersT().map((headers) => toRequest(link, payload, headers));
}

export const retryOnSessionExpiration = <T>(
  originalRequest: Task<AppyError, RequestBuilder<T>>,
): Task<AppyError, T> =>
  new Task((reject, resolve) => {
    return originalRequest.andThen(toHttpTask).fork((err) => {
      switch (err.kind) {
        case 'bad-status':
          if (err.response.status === 401) {
            requestSessionData().fork(
              () => reject(err),
              handleRecovery((sessionData): void => {
                sessionStore.present(sessionData);
                originalRequest
                  .map(withReplacedAuthHeader(sessionData))
                  .andThen<T>(toHttpTask)
                  .fork(reject, resolve);
              }),
            );
          } else {
            reject(err);
          }
          break;
        case 'bad-payload':
        case 'bad-url':
        case 'missing-application-id':
        case 'missing-api-compatibility':
        case 'network-error':
        case 'timeout':
          reject(err);
          break;
        default:
          assertNever(err);
      }
    }, resolve);
  });

/**
 * Fetches JSON data from the provided link.
 *
 * This function sends a request to the specified link and returns a task that
 * resolves to a JSON string. If the session expires during the request, it will
 * automatically retry the request.
 *
 * @param link - The link to fetch JSON data from.
 * @returns A task that resolves to a JSON string or an AppyError.
 */
export function fetchJson(link: Link): Task<AppyError, string> {
  return retryOnSessionExpiration(request(link, {}));
}

/**
 * Decodes JSON data using the provided decoder and returns the decoded value or undefined if decoding fails.
 *
 * @template T - The type of the decoded value.
 * @param {Decoder<T>} decoder - The decoder to use for decoding the JSON data.
 * @returns {(jsonData: string) => T | undefined} A function that takes a JSON string and returns the decoded value or undefined if decoding fails.
 */
export function decodeOnSelect<T>(decoder: Decoder<T>): (jsonData: string) => T | undefined {
  return (jsonData: string) =>
    decoder.decodeJson(jsonData).cata({
      Ok: (value) => value,
      Err: (msg) => {
        console.error('Failed to decode JSON:', msg);
        return undefined;
      },
    });
}

/**
 * Queries a task as a promise by finding a link that matches the given relation,
 * fetching the JSON from that link, and resolving the result.
 *
 * @param rel - The relation to match against the links.
 * @param links - A readonly array of links to search through.
 * @returns A promise that resolves to a string containing the fetched JSON.
 */
export function queryTaskAsPromise(rel: Rel, links: ReadonlyArray<Link>): Promise<string> {
  return Task.succeed(links).andThen(findLinkT(rel)).andThen(fetchJson).resolve();
}

export function callApi<T>(decoder: Decoder<T>, payload: {}): (link: Link) => Task<AppyError, T>;
export function callApi<T>(decoder: Decoder<T>, payload: {}, link: Link): Task<AppyError, T>;
export function callApi<T>(decoder: Decoder<T>, payload: {}, link?: Link) {
  const doit = (link: Link): Task<AppyError, T> =>
    retryOnSessionExpiration(request(link, payload).map((r) => r.withDecoder(decoder.toJsonFn())));

  return typeof link === 'undefined' ? doit : doit(link);
}

/**
 * @deprecated Use the `useFetch` hook.
 */
export const postToApi =
  (payload: {}) =>
  (link: Link): Task<AppyError, string> =>
    retryOnSessionExpiration(
      clientHeadersT().map((headers) => {
        const request = post(link.href).withData(payload).setWithCredentials(false);
        return headers.reduce((req, header) => req.withHeader(header), request);
      }),
    );

/**
 * @deprecated Use the `useFetch` hook.
 */
export const deleteToApi = (link: Link): Task<AppyError, string> =>
  retryOnSessionExpiration(
    clientHeadersT().map((headers) => {
      const request = del(link.href).setWithCredentials(false);
      return headers.reduce((req, header) => req.withHeader(header), request);
    }),
  );

/**
 * @deprecated Use the `useFetch` hook.
 */
export function putToApi<Error extends AppyError>(payload: {}): (link: Link) => Task<Error, string>;
export function putToApi<Error extends AppyError>(payload: {}, link: Link): Task<Error, string>;
export function putToApi(payload: unknown, link?: Link) {
  const doit = (link: Link) =>
    retryOnSessionExpiration(
      clientHeadersT().map((headers) => {
        const request = put(link.href).withData(payload).setWithCredentials(false);
        return headers.reduce((req, header) => req.withHeader(header), request);
      }),
    );
  return typeof link === 'undefined' ? doit : doit(link);
}
