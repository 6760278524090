import { just } from 'maybeasy';
import { useExperienceEntry } from '../../ExperiencesDictionary';
import { ExperienceIDResponse } from '../ExperiencesStore';
import { PortalExperienceContext } from './PortalExperienceContext';

interface PortalExperiencesProviderProps {
  experience: ExperienceIDResponse;
  children: React.ReactNode;
}

export function PortalExperienceProvider({ experience, children }: PortalExperiencesProviderProps) {
  const experienceEntry = useExperienceEntry(experience);
  return (
    <PortalExperienceContext.Provider value={just(experienceEntry)}>
      {children}
    </PortalExperienceContext.Provider>
  );
}
