import { Decoder, field, number, succeed } from 'jsonous';
import { resourceDecoder } from '../Resource/Decoders';
import { ProgramModuleProgress, ProgramModuleProgressResource } from './Types';

export const programModuleProgressDecoder: Decoder<ProgramModuleProgress> = succeed({})
  .assign('id', field('course_module_id', number))
  .assign('totalCount', field('total_count', number))
  .assign('currentPosition', field('current_position', number));

export const programModuleProgressResourceDecoder: Decoder<ProgramModuleProgressResource> =
  resourceDecoder(programModuleProgressDecoder);
