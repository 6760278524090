import { List, ListItem } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { useCaseLink } from '../../../../../ClientRoutes';
import { discoveryStore } from '../../../../../DiscoveryStore';
import useDiscoveryStore from '../../../../../DiscoveryStore/UseDiscoveryStore';
import History from '../../../../../History';
import { UseCaseItem } from '../../../../Dropdown/Types';
import UseCaseInfo from '../../Common/NavigationMenuItems/UseCaseMenuItem/UseCaseInfo';
import { dropdownMenuItems } from '../../UseCaseMenu/Types';

export interface Props {
  onClick: () => void;
}

function UseCaseMenuList({ onClick }: Props) {
  useDiscoveryStore();
  const handleMenuItemClick = (item: UseCaseItem) => () => {
    onClick();
    History.push(useCaseLink(item));
  };

  return (
    <List role="menu">
      {dropdownMenuItems(discoveryStore.learningCollections).map((item) => {
        return (
          <ListItem
            key={item.name.text}
            role="menuitem"
            className="group"
            onClick={handleMenuItemClick(item)}
          >
            <UseCaseInfo data={item.content} />
          </ListItem>
        );
      })}
    </List>
  );
}

export default observer(UseCaseMenuList);
