import { Decoder, array, field, number, string, succeed } from 'jsonous';
import { resourceDecoder } from '../../../../Resource/Decoders';
import { Option, Options, TeamsProgramsOptions, TeamsProgramsOptionsResource } from './Types';

const optionDecoder: Decoder<Option> = succeed({})
  .assign('value', field('value', number))
  .assign('label', field('label', string));

export const optionsDecoder: Decoder<Options> = array(optionDecoder);

export const teamsProgramsOptionDecoder: Decoder<TeamsProgramsOptions> = succeed({})
  .assign('programOptions', field('programs', optionsDecoder))
  .assign('teamOptions', field('teams', optionsDecoder));

export const teamsProgramsOptionsResourceDecoder: Decoder<TeamsProgramsOptionsResource> =
  resourceDecoder(teamsProgramsOptionDecoder);
