import { observer } from 'mobx-react';
import * as React from 'react';
import Loader from '../../../components/Loader';
import WhenUserDataLoaded from '../../../CurrentUser/Context/WhenUserDataLoaded';
import { currentUserStore } from '../../../CurrentUser/Store';
import { contingentProfileStoreWrapper } from '../../../ProfileStore';
import ThemeContext, {
  currentGlobalTheme,
  orgSpecificLoginPageTheme,
} from '../../../Themes/ThemeContext';
import SharedOpenEnrollmentImplementation from './Implementation';
import { InvitationStore } from './InvitationStore';
import { SharedInvitationResourceStore } from './SharedInvitationResourceStore';
import SharedInvitationResourceReactions from './SharedInvitationResourceStore/Reactions';
import WhenSharedInvitationResource from './SharedInvitationResourceStore/WhenSharedInvitationResource';

interface Props {
  opaqueUseCaseIdentifier: string;
}

class SharedOpenEnrollment extends React.Component<Props> {
  invitationStore = new InvitationStore();
  sharedInvitationResourceStore = new SharedInvitationResourceStore(this.invitationStore);

  componentDidMount() {
    this.sharedInvitationResourceStore.loading(this.props.opaqueUseCaseIdentifier);
  }

  getOrgTheme = () => {
    switch (this.sharedInvitationResourceStore.state.kind) {
      case 'ready':
        return orgSpecificLoginPageTheme(this.sharedInvitationResourceStore.state.resource);
      case 'error':
      case 'loading':
      case 'shared-invitation-link-invalid':
      case 'waiting':
        return currentGlobalTheme();
    }
  };

  render() {
    return (
      <ThemeContext.Provider value={this.getOrgTheme()}>
        <SharedInvitationResourceReactions store={this.sharedInvitationResourceStore} />
        <WhenSharedInvitationResource store={this.sharedInvitationResourceStore}>
          {(sharedInvitationResource) => {
            const props = {
              invitationStore: this.invitationStore,
              sharedInvitationResource: sharedInvitationResource,
              opaqueUseCaseIdentifier: this.props.opaqueUseCaseIdentifier,
            };

            switch (currentUserStore.state.kind) {
              case 'ready':
              case 'refreshing':
                return (
                  <WhenUserDataLoaded
                    currentUserResource={currentUserStore.state.currentUserResource}
                    contingencies={[contingentProfileStoreWrapper]}
                  >
                    <SharedOpenEnrollmentImplementation
                      {...props}
                      currentUserState={currentUserStore.state}
                    />
                  </WhenUserDataLoaded>
                );
              case 'anonymous':
                return (
                  <SharedOpenEnrollmentImplementation
                    {...props}
                    currentUserState={currentUserStore.state}
                  />
                );
              case 'waiting':
              case 'loading':
              case 'logging-out':
              case 'errored':
                return <Loader delay={0} />;
            }
          }}
        </WhenSharedInvitationResource>
      </ThemeContext.Provider>
    );
  }
}

export default observer(SharedOpenEnrollment);
