import { first } from '@execonline-inc/collections';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import ExperienceProductLabel from '../../../Native/AEP/Common/Experience/ExperienceFooter/ExperienceProductBadge/ExperienceProductLabel';
import {
  OnDemandProgramIcon,
  ScheduledProgramIcon,
} from '../../../Native/AEP/Common/Experience/ExperienceFooter/ExperienceProductBadge/Icons';
import { T } from '../../../Translations';
import useAvailabilitiesStore from '../Availabilities/AvailabilitiesStore/UseAvailabilitiesStore';
import { Availability } from '../Availabilities/AvailabilitiesStore';

function ProgramProductBadge() {
  const { availabilities } = useAvailabilitiesStore();

  return fromEmpty(availabilities)
    .andThen(first)
    .map((availability) => (
      <ExperienceProductLabel>
        <_FormattedAvailability availability={availability} />
      </ExperienceProductLabel>
    ))
    .getOrElse(emptyFragment);
}

const _FormattedAvailability = observer(({ availability }: { availability: Availability }) => {
  switch (availability.kind) {
    case 'scheduled':
      return (
        <>
          <ScheduledProgramIcon size="md" />
          <T kind="Scheduled Program" />
        </>
      );
    case 'on-demand':
      return (
        <>
          <OnDemandProgramIcon size="md" />
          <T kind="On Demand" />
        </>
      );
  }
});

export default observer(ProgramProductBadge);
