import { action, computed, makeObservable, observable } from 'mobx';

import { just, Maybe, nothing } from 'maybeasy';
import { AppyError, callApi } from '../Appy';
import { Link } from '../Resource/Types';
import { discoveryResourceDecoder } from './Decoders';
import { DiscoveryStoreLoaded, DiscoveryStoreState } from './States';
import { DiscoveryResource, UseCaseExploreResource } from './Types';

class DiscoveryStore {
  @observable state: DiscoveryStoreState = {
    kind: 'discovery-store-initialized',
  };

  constructor() {
    makeObservable(this);
  }

  @computed get isLoading(): boolean {
    return this.state.kind === 'discovery-store-loading';
  }

  @computed get isLoaded(): boolean {
    return this.state.kind === 'discovery-store-loaded';
  }

  @computed get resource(): Maybe<DiscoveryResource> {
    return this.isLoadedGuard() ? just(this.state.resource) : nothing();
  }

  @computed get learningCollections(): ReadonlyArray<UseCaseExploreResource> {
    return this.isLoadedGuard() ? this.state.resource.payload.learningCollections : [];
  }

  @action reset = (): void => {
    this.state = { kind: 'discovery-store-initialized' };
  };

  @action load = (link: Link, reload = false): void => {
    if (this.isLoading) {
      return;
    }

    if (this.isLoaded && !reload) {
      return;
    }

    this.loading(link);

    callApi(discoveryResourceDecoder, {}, link).fork(this.errored, (resource) =>
      this.ready(resource, link),
    );
  };

  @action refresh = (): void => {
    if (this.isLoadedGuard()) {
      this.load(this.state.sourceLink, true);
    }
  };

  @action ready = (resource: DiscoveryResource, sourceLink: Link): void => {
    this.state = { kind: 'discovery-store-loaded', resource, sourceLink };
  };

  @action errored = (error: AppyError): void => {
    this.state = { kind: 'discovery-store-errored', error };
  };

  @action private loading = (link: Link): void => {
    this.state = { kind: 'discovery-store-loading', sourceLink: link };
  };

  private isLoadedGuard(): this is DiscoveryStore & { state: DiscoveryStoreLoaded } {
    return this.isLoaded;
  }
}

export type { DiscoveryStore };

export const discoveryStore = new DiscoveryStore();
