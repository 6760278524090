import { emptyFragment } from '@execonline-inc/execonline-ui';
import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { succeed } from 'jsonous';
import { observer } from 'mobx-react';
import { fromArray } from 'nonempty-list';
import { Task } from 'taskarian';
import SsoLoginStore from '.';
import { AppyError, callApi } from '../../../../Appy';
import ErrorActionableReaction, { EAProps } from '../../../../ErrorActionableReaction';
import { handleAppyError } from '../../../../ErrorHandling';
import { MissingLinkError, findLinkT } from '../../../../LinkyLinky';
import { Link } from '../../../../Resource/Types';
import { SsoAuth } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import { validationErrorsDecoder } from './Decoders';
import SingleSignOn from './SingleSignOn';
import { State } from './Types';

export interface Props extends EAProps<SsoLoginStore> {
  store: SsoLoginStore;
  registerResourceLinks: ReadonlyArray<Link>;
  auth: SsoAuth;
}

type RegisterUserAccountError = MissingLinkError | AppyError;

const handleRegisterUserAccountError =
  (store: SsoLoginStore) => (err: RegisterUserAccountError) => {
    switch (err.kind) {
      case 'missing-link-error':
        store.error('Missing Link');
        break;
      case 'bad-status':
        validationErrorsDecoder
          .decodeJson(err.response.body)
          .andThen((b) => fromArray(b.errors).map(({ first }) => first.detail))
          .do((err) => {
            warn(`Account creation failed: ${err}`);
            store.serverError({ kind: 'already-translated-text', text: err });
          })
          .elseDo(() => store.error(handleAppyError(err)));
        break;
      default:
        store.error(handleAppyError(err));
    }
  };

class SsoLoginStoreReactions extends ErrorActionableReaction<SsoLoginStore, State, Props> {
  tester = (): State => this.props.store.state;

  effect = (state: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'creating':
        Task.succeed<RegisterUserAccountError, ReadonlyArray<Link>>(
          this.props.registerResourceLinks,
        )
          .andThen(findLinkT('update'))
          .andThen(callApi(succeed({}), {}))
          .fork(handleRegisterUserAccountError(store), store.created);
        break;
      case 'created':
        break;
      case 'error':
        warn(state.message);
        store.readyWithClientErrors(state.termsAcceptance, state.message, state.emailAddress);
        break;
      case 'server-error':
        warn(state.message.text);
        store.readyWithServerErrors(state.termsAcceptance, state.message, state.emailAddress);
        break;
      case 'waiting':
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
        break;
      default:
        assertNever(state);
    }
  };

  render() {
    if (this.props.store.state.kind === 'created') {
      return (
        <SingleSignOn
          store={this.props.store}
          state={this.props.store.state}
          auth={this.props.auth}
        />
      );
    }
    return emptyFragment();
  }
}

export default observer(SsoLoginStoreReactions);
