import { emptyFragment, IconButton, Menu, useOpenable } from '@execonline-inc/execonline-ui';
import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { useRef } from 'react';
import Person from '../../../components/Person';
import { NavigationMenuItems } from '../../../components/Platform/NavigationBar/Common';
import { profileStore } from '../../../ProfileStore';
import useProfileStore from '../../../ProfileStore/UseProfileStore';
import { useTranslationsContext } from '../../../Translations/UseTranslationsContext';
import navigationStore from './NavigationStore';

const menuId = 'accountNavigationMenu';

function AccountMenu() {
  useProfileStore();
  const anchorElementRef = useRef<HTMLButtonElement>(null);
  const { isOpen, onClose, onOpenChange } = useOpenable();
  const { translate } = useTranslationsContext();

  return profileStore.personStoreContract
    .map((personStore) => (
      <>
        <IconButton
          data-testid="menu-bar_account-menu-item"
          id={menuId}
          aria-haspopup="menu"
          aria-expanded={isOpen}
          ref={anchorElementRef}
          className="ml-auto"
          radius="full"
          title={translate('Account Navigation Dropdown Menu')}
          aria-label={translate('Account Navigation')}
          onClick={onOpenChange}
        >
          <Person personStore={personStore} presenceStore={nothing()} />
        </IconButton>
        <Menu
          data-testid="account-menu_dropdown"
          aria-labelledby={menuId}
          open={isOpen}
          anchorElement={anchorElementRef.current}
          placement="bottom-end"
          onClose={onClose}
        >
          <NavigationMenuItems items={navigationStore.accountDropDownItems} />
        </Menu>
      </>
    ))
    .getOrElse(emptyFragment);
}

export default observer(AccountMenu);
