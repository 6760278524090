import { just, Maybe, nothing } from 'maybeasy';
import { BehaviorSubject } from 'rxjs';
import { Task } from 'taskarian';
import { callApi } from '../../../../Appy';
import { findLink } from '../../../../LinkyLinky';
import { SchoolPartner } from '../../../../Native/AEP/Common/Experience/SchoolPartner';
import {
  schoolPartnerResourceDecoder,
  schoolPartnerResourcesDecoder,
} from '../../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Experiences/Decoders';
import { Link, Resource } from '../../../../Resource/Types';

type SchoolPartnersStoreEvent = {
  observable: BehaviorSubject<Maybe<Resource<SchoolPartner>>>;
};

interface SchoolPartnersStoreState {
  behaviors: Record<string, SchoolPartnersStoreEvent>;
}

class SchoolPartnersStore {
  private data: SchoolPartnersStoreState = {
    behaviors: {},
  };

  private state: 'initialized' | 'loading' | 'loaded' = 'initialized';

  loadAllSchoolPartnerResources = (schoolPartnerIndexLink: Link): Promise<void> => {
    if (this.state === 'loaded' || this.state === 'loading') {
      return Promise.resolve();
    }

    this.state = 'loading';

    return callApi(schoolPartnerResourcesDecoder, {}, schoolPartnerIndexLink)
      .map((resources) => {
        resources.payload.forEach((resource) => {
          findLink('self', resource.links).do((link) => {
            this.getSchoolPartner(link).next(just(resource));
          });
        });
        return schoolPartnerIndexLink;
      })
      .orElse((err) => {
        console.warn('Parner resource failed to fetch', err);
        return Task.succeed(schoolPartnerIndexLink);
      })
      .map(() => {
        this.state = 'loaded';
      })
      .resolve();
  };

  getSchoolPartner = (schoolPartnerLink: Link): BehaviorSubject<Maybe<Resource<SchoolPartner>>> => {
    const { href } = schoolPartnerLink;
    const behavior = this.data.behaviors[href];
    if (!behavior) {
      this.data.behaviors[href] = {
        observable: new BehaviorSubject(nothing()),
      };
    }
    return this.data.behaviors[href].observable;
  };

  loadSchoolPartnerResource = (
    schoolPartnerLink: Link,
  ): BehaviorSubject<Maybe<Resource<SchoolPartner>>> => {
    const { href } = schoolPartnerLink;
    const behavior = this.data.behaviors[href];
    if (behavior) {
      return behavior.observable;
    }

    this.data.behaviors[href] = {
      observable: new BehaviorSubject(nothing()),
    };

    const task: Task<never, Maybe<Resource<SchoolPartner>>> = callApi(
      schoolPartnerResourceDecoder,
      {},
      schoolPartnerLink,
    )
      .map((resource) => {
        this.data.behaviors[href].observable.next(just(resource));
        return just(resource);
      })
      .orElse((err) => {
        console.warn('Parner resource failed to fetch', err);
        this.data.behaviors[href].observable.next(nothing());
        return Task.succeed(nothing());
      });

    task.resolve();

    return this.data.behaviors[href].observable;
  };
}

const schoolPartnersStore = new SchoolPartnersStore();
export default schoolPartnersStore;
