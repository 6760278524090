import { observer } from 'mobx-react';
import { Experience } from '../../../../Common/Experience/Types';
import { LearnMoreProgramHero } from '../../../LearnMoreProgram';
import ExperienceProductDetails from './ExperienceProductDetails';
import ExperiencesStore from '../../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';

interface Props {
  experience: Experience;
  experiencesStore: ExperiencesStore;
}

function HeroBody({ experience, experiencesStore }: Props) {
  switch (experience.offeringType) {
    case 'coaching':
    case 'group-coaching':
    case 'program-sequence':
      return (
        <ExperienceProductDetails experience={experience} experiencesStore={experiencesStore} />
      );
    case 'aep':
    case 'epc':
    case 'msuite':
      return <LearnMoreProgramHero experience={experience} experiencesStore={experiencesStore} />;
  }
}

export default observer(HeroBody);
