import { observer } from 'mobx-react';
import * as React from 'react';
import { ConferenceRoomResource } from '../../Conference/Types';
import { EventResource } from '../../EventsStore/Types';
import ExternalConference from './ExternalConference';
import LocalConference from './LocalConference';

interface Props {
  eventId: number;
  eventResource: EventResource;
  conferenceRoomResource: ConferenceRoomResource;
}

const LocalOrExternalConference: React.FC<Props> = ({
  eventId,
  eventResource,
  conferenceRoomResource,
}) => {
  const event = eventResource.payload;
  const conferenceRoom = conferenceRoomResource.payload;

  switch (conferenceRoom.kind) {
    case 'daily-live-meeting':
      return (
        <LocalConference
          event={event}
          eventResource={eventResource}
          conferenceRoom={conferenceRoom}
          eventId={eventId}
        />
      );
    case 'gtw-live-meeting':
    case 'external-live-meeting':
      return (
        <ExternalConference conferenceRoomLinks={conferenceRoomResource.links} event={event} />
      );
  }
};

export default observer(LocalOrExternalConference);
