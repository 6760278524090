import { first } from '@execonline-inc/collections';
import { putQueryParam, toUrl } from '@execonline-inc/url';
import { noop } from '@kofno/piper';
import { succeed } from 'jsonous';
import { Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { AppyError, callApi } from '../../Appy';
import { AutoLaunchableSource } from '../../components/AutoLaunchable/AutoLaunchableContext';
import { autoLaunchableDecoder } from '../../components/AutoLaunchable/AutoLaunchableStore/Decoders';
import { findLink } from '../../LinkyLinky';
import { ProgramSegment } from '../../ProgramStore/Types';
import { Link } from '../../Resource/Types';
import { AutoLaunchablesStoreState, AutoLaunchablesStoreStateReady } from './Types';

class AutoLaunchablesStore {
  @observable state: AutoLaunchablesStoreState = {
    kind: 'auto-launchables-store-initialized',
  };

  constructor() {
    makeObservable(this);
  }

  isLoaded(
    source: AutoLaunchableSource,
  ): this is AutoLaunchablesStore & { state: AutoLaunchablesStoreStateReady } {
    return this.state.kind === 'auto-launchables-store-ready' && this.state.source === source;
  }

  @computed get isLoading(): boolean {
    return this.state.kind === 'auto-launchables-store-loading';
  }

  @computed get currentAutoLaunchableSegment(): Maybe<ProgramSegment> {
    if (this.state.kind !== 'auto-launchables-store-ready') {
      return nothing();
    }
    return first(this.state.autoLaunchablesResource.payload.segments);
  }

  @action private loading = (): void => {
    this.state = { kind: 'auto-launchables-store-loading' };
  };

  @action load = (link: Link, source: AutoLaunchableSource): void => {
    if (this.isLoading) {
      return;
    }

    this.loading();

    const href = toUrl(link.href)
      .map(putQueryParam('source', source))
      .map(({ href }) => href)
      .getOrElseValue(link.href);
    callApi(autoLaunchableDecoder, {}, { ...link, href }).fork(
      (err) => {
        if (err.kind === 'bad-status' && err.response.status === 404) {
          this.nothingToLaunch(source);
        } else {
          this.errored(err);
        }
      },
      (resource) => this.ready(resource, source),
    );
  };

  @action reload = (): void => {
    if (this.state.kind !== 'auto-launchables-store-ready') {
      return;
    }
    const { autoLaunchablesResource, source } = this.state;
    const { links } = autoLaunchablesResource;
    const autoLaunchablesLink = findLink('self', links);
    autoLaunchablesLink.do((link) => this.load(link, source));
  };

  @action private ready = (
    resource: AutoLaunchablesStoreStateReady['autoLaunchablesResource'],
    source: AutoLaunchableSource,
  ): void => {
    this.state = {
      kind: 'auto-launchables-store-ready',
      autoLaunchablesResource: resource,
      source,
    };
  };

  @action private nothingToLaunch = (source: AutoLaunchableSource): void => {
    this.state = { kind: 'auto-launchables-store-nothing-to-launch', source };
  };

  @action private errored = (error: AppyError): void => {
    this.state = { kind: 'auto-launchables-store-errored', error };
  };

  @action postpone = (link: Link, close = noop): void => {
    callApi(succeed({}), {}, link).fork(
      (e) => {
        console.warn(e);
        close();
      },
      () => {
        close();
      },
    );
  };
}

const autoLaunchablesStore = new AutoLaunchablesStore();
export default autoLaunchablesStore;
