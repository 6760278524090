import { observer } from 'mobx-react';
import { useMobileFilterSwitcher } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/hooks';
import InlineFilters from './InlineFilters';
import SidebarFilters from './SidebarFilters';

function Filters() {
  return (
    <div className="flex basis-7/12 gap-2">
      <_Filters />
    </div>
  );
}

const _Filters = observer(() => {
  const mode = useMobileFilterSwitcher();

  return mode === 'desktop' ? <InlineFilters /> : <SidebarFilters />;
});

export default observer(Filters);
