import { explicitMaybe } from '@execonline-inc/decoders';
import { array, number, string } from 'jsonous';
import { action, computed, makeObservable, observable } from 'mobx';
import { AppyError, callApi } from '../../../Appy';
import { createDecoderFromStructure, InferType, snakeCase } from '../../../Decoders/Structured';
import { linkDecoder, resourceDecoder } from '../../../Resource/Decoders';
import { Link, Resource } from '../../../Resource/Types';

export interface ExperienceComponentProps {
  experience: ExperienceIDResponse;
}

const experienceIDDecoder = createDecoderFromStructure({
  id: number,
  type: string,
});
export type ExperienceID = InferType<typeof experienceIDDecoder>;

const experienceIDLinksDecoder = createDecoderFromStructure(
  {
    schoolPartner: linkDecoder,
    productDetails: explicitMaybe(linkDecoder),
    overviewPdf: explicitMaybe(linkDecoder),
    digitalCert: explicitMaybe(linkDecoder),
    supportedLanguages: linkDecoder,
    availabilities: linkDecoder,
    experiencePrices: explicitMaybe(linkDecoder),
  },
  snakeCase,
);
export type ExperienceIDLinks = InferType<typeof experienceIDLinksDecoder>;

const experienceIDResponseDecoder = createDecoderFromStructure({
  payload: experienceIDDecoder,
  links: experienceIDLinksDecoder,
});
export type ExperienceIDResponse = InferType<typeof experienceIDResponseDecoder>;

const experiencesPortalPaylodDecoder = createDecoderFromStructure({
  experiences: array(experienceIDResponseDecoder),
});
export type ExperiencesPortalPayload = InferType<typeof experiencesPortalPaylodDecoder>;

export interface ExperiencesStoreStateInitialized {
  kind: 'experiences-store-initialized';
}

export interface ExperiencesStoreStateLoading {
  kind: 'experiences-store-loading';
}

export interface ExperiencesStoreStateLoaded {
  kind: 'experiences-store-loaded';
  link: Link;
  experiences: Resource<ExperiencesPortalPayload>;
}

export interface ExperiencesStoreStateErrored {
  kind: 'experiences-store-errored';
  error: AppyError;
}

export type ExperiencesStoreState =
  | ExperiencesStoreStateInitialized
  | ExperiencesStoreStateLoading
  | ExperiencesStoreStateLoaded
  | ExperiencesStoreStateErrored;

export function isLoaded(
  store: ExperiencesStore,
): store is ExperiencesStore & { state: ExperiencesStoreStateLoaded } {
  return store.isLoaded;
}

export class ExperiencesStore {
  @observable state: ExperiencesStoreState = { kind: 'experiences-store-initialized' };

  constructor() {
    makeObservable(this);
  }

  @action
  loadExperiences = (link: Link) => {
    if (this.isLoading) {
      return;
    }

    if (isLoaded(this) && this.state.link.href === link.href) {
      return;
    }

    this.startLoading();

    callApi(resourceDecoder(experiencesPortalPaylodDecoder), {}, link).fork(
      (err) => {
        console.warn("Experiences portal didn't load", err);
        this.error(err);
      },
      (experiences) => this.loaded(experiences, link),
    );
  };

  @computed
  get isLoading() {
    return this.state.kind === 'experiences-store-loading';
  }

  @computed
  get isLoaded() {
    return this.state.kind === 'experiences-store-loaded';
  }

  @computed
  get links() {
    return this.state.kind === 'experiences-store-loaded' ? this.state.experiences.links : [];
  }

  @action
  private startLoading = () => {
    this.state = { kind: 'experiences-store-loading' };
  };

  @action
  private loaded = (experiences: ExperiencesStoreStateLoaded['experiences'], link: Link) => {
    this.state = { kind: 'experiences-store-loaded', experiences, link };
  };

  @action
  private error = (error: AppyError) => {
    this.state = { kind: 'experiences-store-errored', error };
  };
}

const experiencesStore = new ExperiencesStore();
export default experiencesStore;
