import { emptyFragment } from '@execonline-inc/execonline-ui';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import ProportionalImage from '../../../components/ProportionalImage';
import { findLink } from '../../../LinkyLinky';
import useSchoolPartnersStore from './SchoolPartnersStore/UseSchoolPartnersStore';

interface SchoolPartnerLogoProps {
  height: number;
  logoLinkType?: 'logo-on-white' | 'logo-on-dark';
}

function SchoolPartnerLogo({ height, logoLinkType = 'logo-on-white' }: SchoolPartnerLogoProps) {
  const { schoolPartner } = useSchoolPartnersStore();
  return just({})
    .assign('resource', schoolPartner)
    .assign('link', ({ resource }) => findLink(logoLinkType, resource.links))
    .map(({ link, resource }) => (
      <ProportionalImage href={link.href} alt={resource.payload.name.text} height={height} />
    ))
    .getOrElse(emptyFragment);
}

export default observer(SchoolPartnerLogo);
