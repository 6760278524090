import { observer } from 'mobx-react';
import { T } from '../../../Translations';
import { Availability } from '../Availabilities/AvailabilitiesStore';
import ScheduledAvailabilities from './ScheduledAvailabilities';

interface FormattedUpcomingDatesProps {
  availability: Availability;
}

function FormattedUpcomingDates({ availability }: FormattedUpcomingDatesProps) {
  switch (availability.kind) {
    case 'on-demand':
      return <T kind="On Demand" />;
    case 'scheduled':
      return <ScheduledAvailabilities />;
  }
}

export default observer(FormattedUpcomingDates);
