import { CloudArrowDownIcon, Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import ExternalLink from '../../../Native/AEP/ExternalLink';
import { T } from '../../../Translations';
import { ExperienceIDResponse } from '../ExperiencesStore';

interface Props<T> {
  experienceID: ExperienceIDResponse;
}

function DownloadOverview<T>({ experienceID }: Props<T>) {
  return experienceID.links.overviewPdf
    .map((pdfLink) => (
      <div className="flex items-center gap-x-2.5">
        <CloudArrowDownIcon color="black" size="md" />
        <ExternalLink href={pdfLink.href}>
          <Typography color="teal" variant="bodyMedium">
            <T kind="Download Program Overview" />
          </Typography>
        </ExternalLink>
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(DownloadOverview);
