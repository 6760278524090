import { numberToStringDecoder } from '@execonline-inc/decoders';
import { Decoder, array, field, string, succeed } from 'jsonous';
import { errorDecoder, linksDecoder, resourceDecoder } from '../../Resource/Decoders';
import { Resource, ResourceWithErrors } from '../../Resource/Types';
import { CountryRegionOption, CountryRegionOptions } from '../Types';

export const countryRegionOptionDecoder: Decoder<CountryRegionOption> = succeed({})
  .assign('id', field('value', numberToStringDecoder))
  .assign('name', field('label', string));

const countryRegionOptionsDecoder: Decoder<CountryRegionOptions> = succeed({}).assign(
  'countryRegionOptions',
  field('country_region_options', array(countryRegionOptionDecoder)),
);

export const countryRegionOptionsResourceDecoderWithErrors: Decoder<
  ResourceWithErrors<CountryRegionOptions>
> = succeed({})
  .assign('payload', field('payload', countryRegionOptionsDecoder))
  .assign('links', field('links', linksDecoder))
  .assign('errors', field('errors', array(errorDecoder)));

export const countryRegionOptionsResourceDecoder: Decoder<Resource<CountryRegionOptions>> =
  resourceDecoder(countryRegionOptionsDecoder);
