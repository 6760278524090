import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { Experience } from '../../../../../Common/Experience/Types';
import { LearnMoreGroupCoachingHero } from '../../../../LearnMoreGroupCoaching';
import { LearnMoreLeadershipHero } from '../../../../LearnMoreLeadershipCoaching';
import { LearningJourneyHero } from '../../../../LearnMoreLearningJourney';
import ExperiencesStore from '../../../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';

interface Props {
  experience: Experience;
  experiencesStore: ExperiencesStore;
}

function ExperienceProductDetails({ experience, experiencesStore }: Props) {
  return experience.productDetails
    .map((productDetails) => {
      switch (productDetails.kind) {
        case 'leadership-coaching':
          return (
            <LearnMoreLeadershipHero experience={experience} experiencesStore={experiencesStore} />
          );
        case 'group-coaching':
          return (
            <LearnMoreGroupCoachingHero experience={experience} productDetails={productDetails} />
          );
        case 'program-sequence':
          return <LearningJourneyHero experience={experience} />;
      }
    })
    .getOrElse(emptyFragment);
}

export default observer(ExperienceProductDetails);
