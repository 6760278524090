import { observer } from 'mobx-react';
import * as React from 'react';
import { CurrentUserReactions } from '../../CurrentUser/Reactions';
import { currentUserStore } from '../../CurrentUser/Store';
import CurrentHoneybadgerUserReactions from '../../Honeybadger/CurrentHoneybadgerUserReactions';
import { OrganizationReactions } from '../../Organization/Reactions';
import { organizationStore } from '../../Organization/Store';
import { PreferredLanguageSelectionReactions } from '../../PreferredLanguageSelection/Reactions';
import { preferredLanguageSelectionStore } from '../../PreferredLanguageSelection/Store';
import { programsStore } from '../../ProgramsStore';
import { ProgramsReactions } from '../../ProgramsStore/Reactions';
import { SessionReactions } from '../../Session/Reactions';
import { sessionStore } from '../../Session/Store';
import { toolingsStore } from '../../ToolingsStore';
import { ToolingsReactions } from '../../ToolingsStore/Reactions';
import TimeOutReactions from '../TimeOutReactions';

const AppReactions: React.FC = () => (
  <>
    <SessionReactions store={sessionStore} fireImmediately />
    <TimeOutReactions store={sessionStore} fireImmediately />
    <CurrentUserReactions store={currentUserStore} fireImmediately />
    <OrganizationReactions store={organizationStore} fireImmediately />
    <ProgramsReactions store={programsStore} fireImmediately />
    <ToolingsReactions store={toolingsStore} fireImmediately />
    <PreferredLanguageSelectionReactions store={preferredLanguageSelectionStore} fireImmediately />
    <CurrentHoneybadgerUserReactions store={currentUserStore} fireImmediately={true} />
  </>
);

export default observer(AppReactions);
