import { equals } from '@execonline-inc/collections';
import { cn, useDeviceDetect } from '@execonline-inc/execonline-ui';
import { fromEmpty, fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import React, { ComponentProps, useState } from 'react';
import SearchBoxButton from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/SearchBox/SearchBoxButton';
import SearchBoxField from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/SearchBox/SearchBoxField';

interface SearchBoxProps {
  className?: string;
  value?: string;
  onSubmit: (value: string) => void;
}

function fromBlank(value: string | undefined) {
  return fromNullable(value).andThen(fromEmpty);
}

function SearchBox({ className, value, onSubmit }: SearchBoxProps) {
  const [search, onChange] = useState(fromBlank(value));

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    search.do(onSubmit);
  };

  return (
    <form className={cn('flex basis-5/12 gap-2', className)} onSubmit={handleSubmit}>
      <SearchBoxField search={search} onChange={onChange} />
      <_SearchBoxButton disabled={search.isNothing()} />
    </form>
  );
}

const _SearchBoxButton = observer((props: ComponentProps<typeof SearchBoxButton>) => {
  const deviceType = useDeviceDetect();
  return equals(deviceType, 'desktop') ? <SearchBoxButton {...props} /> : <></>;
});

export default observer(SearchBox);
