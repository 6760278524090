import { stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import { array, date, Decoder, field, number, oneOf, string, succeed } from 'jsonous';
import { Maybe, nothing } from 'maybeasy';
import { sanitizedHtmlDecoder } from '../components/LinkifyWithCharEntities';
import { resourceDecoder } from '../Resource/Decoders';
import { alreadyTranslatedText } from '../Translations';
import {
  ChatMessageOffensiveReport,
  ChatMessageOffensiveReportsResource,
  ChatMessageReaction,
  ChatMessageReactionsResource,
  ChatMessageRepliesResource,
  ChatMessageReply,
  ChatMessageReplyResource,
  ProgramChatMessageReplyNotification,
  ReplyNotification,
  TeamMessageReplyNotification,
} from './Types';

export const chatMessageReactionDecoder: Decoder<ChatMessageReaction> = succeed({})
  .assign('userId', field('userId', number))
  .assign('emoji', field('emoji', string));

export const chatMessageReactionsResourceDecoder: Decoder<ChatMessageReactionsResource> =
  resourceDecoder(array(resourceDecoder(chatMessageReactionDecoder)));

export const chatMessageOffensiveReportDecoder: Decoder<ChatMessageOffensiveReport> = succeed({});

export const chatMessageOffensiveReportsResourceDecoder: Decoder<ChatMessageOffensiveReportsResource> =
  resourceDecoder(array(resourceDecoder(chatMessageOffensiveReportDecoder)));

export const alwaysEmptyRepliesDecoder: Decoder<Maybe<ChatMessageRepliesResource>> =
  succeed(nothing<ChatMessageRepliesResource>());

export const chatMessageReplyDecoder: Decoder<ChatMessageReply> = succeed({})
  .assign('userId', field('user_id', number))
  .assign('content', field('sanitized_content', sanitizedHtmlDecoder))
  .assign('id', field('id', number))
  .assign('createdAt', field('created_at', date))
  .assign('age', field('age', string))
  .assign('reactions', field('reactions', chatMessageReactionsResourceDecoder))
  .assign(
    'offensiveMessageReports',
    field('offensive_message_reports', chatMessageOffensiveReportsResourceDecoder),
  );

export const chatMessageReplyResourceDecoder: Decoder<ChatMessageReplyResource> =
  resourceDecoder(chatMessageReplyDecoder);

const baseNotificationDecoder = succeed({})
  .assign('activeAt', field('active_at', date))
  .assign('inactiveAt', field('inactive_at', date))
  .assign('conversableId', field('conversable_id', number))
  .assign('message', field('message', chatMessageReplyResourceDecoder))
  .assign('title', field('title', alreadyTranslatedText))
  .assign('uuid', field('uuid', string));

const teamMessageReplyDecoder: Decoder<TeamMessageReplyNotification> =
  baseNotificationDecoder.assign(
    'kind',
    field('kind', stringLiteral('team-message-reply-notification')),
  );

const programMessageReplyDecoder: Decoder<ProgramChatMessageReplyNotification> =
  baseNotificationDecoder.assign(
    'kind',
    field('kind', stringLiteral('program-chat-message-reply-notification')),
  );

export const replyNotificationDecoder: Decoder<ReplyNotification> = oneOf<ReplyNotification>([
  teamMessageReplyDecoder.map<ReplyNotification>(identity),
  programMessageReplyDecoder.map<ReplyNotification>(identity),
]);
