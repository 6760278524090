import { NonEmptyList } from 'nonempty-list';
import { UseCaseExploreResource } from '../../../../DiscoveryStore/Types';
import { UseCaseItem } from '../../../Dropdown/Types';

export interface UseCaseMenuItemProps {
  items: NonEmptyList<UseCaseExploreResource>;
}

export const dropdownMenuItems = (items: ReadonlyArray<UseCaseExploreResource>): UseCaseItem[] =>
  items.map(
    (item): UseCaseItem => ({
      kind: 'use-case-item',
      id: item.payload.name.text.toLocaleLowerCase(),
      name: item.payload.name,
      content: item.payload,
    }),
  );
