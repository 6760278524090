import { explicitMaybe, stringLiteral } from '@execonline-inc/decoders';
import { alreadyTranslatedText } from '@execonline-inc/translations';
import { Decoder, array, fail, field, number, oneOf, string, succeed } from 'jsonous';
import { PerPageCount } from '../../../../Native/AEP/Common/Experience/Types';
import { resourceDecoder } from '../../../../Resource/Decoders';
import { ProductCollection, ProductCollectionDisplayType, ProductCollectionKind } from './types';

// Decoder for ProductCollectionKind
export const productCollectionKindDecoder: Decoder<ProductCollectionKind> = oneOf([
  stringLiteral<ProductCollectionKind>('recommendation-engine'),
  stringLiteral<ProductCollectionKind>('learning-design'),
  stringLiteral<ProductCollectionKind>('dynamic-recommendation'),
  stringLiteral<ProductCollectionKind>('group-coaching-groups-recommendation'),
]);

// Decoder for ProductCollectionDisplayType
export const productCollectionDisplayTypeDecoder: Decoder<ProductCollectionDisplayType> = oneOf([
  stringLiteral<ProductCollectionDisplayType>('list'),
  stringLiteral<ProductCollectionDisplayType>('carousel'),
  stringLiteral<ProductCollectionDisplayType>('grid'),
]);

// Decoder for sequencing_rule
export const sequencingRuleDecoder: Decoder<'random' | 'ranked'> = oneOf([
  stringLiteral<'random' | 'ranked'>('random'),
  stringLiteral<'random' | 'ranked'>('ranked'),
]);

const recommendationPerPageCountDecoder: Decoder<PerPageCount> = number.andThen((n) =>
  n === 1 || n === 2 || n === 3 || n === 4
    ? succeed(n)
    : fail('Recommendation per page counts can only be 1, 2, 3 or 4'),
);

// Decoder for ProductCollection
export const productCollectionDecoder: Decoder<ProductCollection> = succeed({})
  .assign('id', field('id', number))
  .assign('kind', field('kind', productCollectionKindDecoder))
  .assign('name', field('name', alreadyTranslatedText))
  .assign('short_name', field('short_name', string))
  .assign('description', field('description', explicitMaybe(alreadyTranslatedText)))
  .assign(
    'display_per_page_count',
    field('display_per_page_count', recommendationPerPageCountDecoder),
  )
  .assign('experiences', field('experiences', array(number)))
  .assign('display_type', field('display_type', productCollectionDisplayTypeDecoder))
  .assign('display_button_label', field('display_button_label', explicitMaybe(string)));

// Decoder for ProductCollectionsResource
// This extracts just the payload array from the response
export const productCollectionsResourceDecoder = resourceDecoder(array(productCollectionDecoder));
