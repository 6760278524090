import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../../../../Translations';
import { ExperienceDictionaryEntry } from '../../../../../../ExperiencesDictionary/Types';

interface Props {
  experience: ExperienceDictionaryEntry;
}

function ProgramHours({ experience }: Props) {
  return experience.totalHours
    .map((count) => (
      <Typography className="text-gray-900" variant="caption">
        <T kind="{{count}} Hour" count={count} />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ProgramHours);
