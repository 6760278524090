import { assertNever } from '@kofno/piper';
import { err, ok, Result } from 'resulty';
import { EventResource } from '../../../EventsStore/Types';
import {
  AlertEmbeddable,
  AlertNode,
  EmbeddableNode,
  EmbeddedDocumentEmbeddable,
  EmbeddedDocumentNode,
  LinkedDocumentEmbeddable,
  LinkedDocumentNode,
  ScriptEmbeddable,
  ScriptTag,
} from '../../ContentEmbed/Types';

export type EventEmbeddableNode = AlertNode | EmbeddedDocumentNode | LinkedDocumentNode | ScriptTag;

export type EventEmbeddable =
  | AlertEmbeddable
  | EmbeddedDocumentEmbeddable
  | LinkedDocumentEmbeddable
  | ScriptEmbeddable;

export const whenEventEmbeddable = (node: EmbeddableNode): Result<string, EventEmbeddableNode> => {
  switch (node.kind) {
    case 'alert-node':
    case 'embedded-document-node':
    case 'linked-document-node':
    case 'script-tag':
      return ok(node);
    case 'conjoint-analysis':
    case 'conjoint-analysis-results':
    case 'freebusy-program':
    case 'freebusy-coaching':
    case 'learning-partners':
    case 'live-forum-dates-node':
    case 'section-event-dates-node':
    case 'team-link-node':
    case 'team-members':
    case 'segment-video-node':
    case 'learning-partner-assignment':
    case 'expert-feedback-node':
    case 'shared-resource':
    case 'digital-certificate-resource':
    case 'announcement-video-node':
    case 'discovery-portal-open-invite-uuid':
    case 'discovery-portal-choose-next':
    case 'embedded-form-field-node':
    case 'embedded-tabular-form-field-group-node':
    case 'coaching-selected-coach-name-node':
    case 'coaching-selected-coach-profile-node':
    case 'display-assigned-coach-node':
    case 'coaching-survey-button-node':
    case 'program-chat-link-node':
    case 'ai-written-feedback-node':
      return err('Not an event embeddable node');
    default:
      return assertNever(node);
  }
};

export const embeddable = (
  node: EventEmbeddableNode,
  eventResource: EventResource,
): Result<string, EventEmbeddable> => {
  switch (node.kind) {
    case 'alert-node':
      return ok({
        kind: 'alert-embeddable',
        node: node,
      });
    case 'embedded-document-node':
      return ok({
        kind: 'embedded-document-embeddable',
        node: node,
        assets: eventResource.payload.embeddedDocumentAssets,
      });
    case 'linked-document-node':
      return ok({
        kind: 'linked-document-embeddable',
        node: node,
        links: eventResource.links,
      });
    case 'script-tag':
      return ok({
        kind: 'script-embeddable',
        node: node,
      });
  }
};
