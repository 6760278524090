import { equals } from '@execonline-inc/collections';
import { asMaybe } from '@execonline-inc/error-handling';
import { warn } from '@execonline-inc/logging';
import { toResult, when } from '@execonline-inc/maybe-adapter';
import { InvalidUrlError, getQueryParam, toUrlR } from '@execonline-inc/url';
import { assertNever, pipe } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { RouteComponentProps } from 'react-router-dom';
import { Result, err, ok } from 'resulty';

export interface UseCaseUuid {
  kind: 'use-case-uuid';
  uuid: string;
}

export interface UseCaseId {
  kind: 'use-case-id';
  id: string;
}

export type UseCaseIdentifier = UseCaseUuid | UseCaseId;

interface WindowPropertyError {
  kind: 'window-property-error';
  error: unknown;
}

interface MissingQueryParamError {
  kind: 'missing-query-param-error';
  key: string;
}

type LocationParsingError = WindowPropertyError | InvalidUrlError | MissingQueryParamError;

const windowLocation = (): Result<WindowPropertyError, Location> => {
  try {
    return ok(window.location);
  } catch (e) {
    return err({ kind: 'window-property-error', error: e });
  }
};

const handleLocationParsingError = (e: LocationParsingError): void => {
  switch (e.kind) {
    case 'invalid-url-error':
    case 'window-property-error':
      warn('Error parsing window location:', e);
      break;
    case 'missing-query-param-error':
      break;
    default:
      assertNever(e);
  }
};

export const readQueryParam = (key: string): Maybe<string> =>
  asMaybe(
    ok<LocationParsingError, {}>({})
      .andThen(windowLocation)
      .map((l) => l.href)
      .andThen(toUrlR)
      .andThen(pipe(getQueryParam(key), toResult({ kind: 'missing-query-param-error', key })))
      .elseDo(handleLocationParsingError),
  );

export const embed: boolean = readQueryParam('embed')
  .andThen(when(equals('true')))
  .map(() => true)
  .getOrElseValue(false);

export const useCaseUuid = (uuid: string): UseCaseUuid => ({ kind: 'use-case-uuid', uuid });

export const useCaseId = (id: string): UseCaseId => ({ kind: 'use-case-id', id });

interface MatchIDParams {
  id: string;
}

interface MatchUUIDParams {
  uuid: string;
}

interface MatchGUIDParams {
  guid: string;
}

export interface MatchIDProps extends RouteComponentProps<MatchIDParams> {}
export interface MatchUUIDProps extends RouteComponentProps<MatchUUIDParams> {}
export interface MatchGUIDProps extends RouteComponentProps<MatchGUIDParams> {}
