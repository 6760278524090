import { just, Maybe, nothing } from 'maybeasy';
import { NonEmptyList } from 'nonempty-list';
import { ChatMessageReplyResource, ChatMessageResource } from '../ConversationStore/Types';
import { Resource, resource } from '../Resource/Types';
import { Breakout } from '../TeamsStore/Types';

export interface Conferenceable {
  conferenceRoom: Maybe<ConferenceRoomResource>;
}

export type ConferenceableResource = Resource<Conferenceable>;

export interface LocalConferenceRoomBase {
  id: number;
  conferenceId: string;
  conferencePin: Maybe<string>;
  conversationResource: ConversationResource;
  breakoutRooms: Maybe<NonEmptyList<Breakout>>;
  messagingChannel: string;
  programId: Maybe<number>;
  token: Maybe<string>;
}

export interface DailyConferenceRoom extends LocalConferenceRoomBase {
  kind: 'daily-live-meeting';
}

export type LocalConferenceRoom = DailyConferenceRoom;

export interface ExternalConferenceRoom {
  kind: 'external-live-meeting';
}

export interface GoToWebinarConferenceRoom {
  kind: 'gtw-live-meeting';
}

export type DailyConferenceRoomResource = Resource<DailyConferenceRoom>;

export type LocalConferenceRoomResource = DailyConferenceRoomResource;

export type GoToWebinarConferenceRoomResource = Resource<GoToWebinarConferenceRoom>;
export type ExternalConferenceRoomResource = Resource<ExternalConferenceRoom>;

export type ConferenceRoom =
  | LocalConferenceRoom
  | ExternalConferenceRoom
  | GoToWebinarConferenceRoom;

export type ConferenceRoomResource =
  | LocalConferenceRoomResource
  | ExternalConferenceRoomResource
  | GoToWebinarConferenceRoomResource;

export type LastReadMessage = ChatMessageResource | ChatMessageReplyResource;

export interface Conversation {
  id: number;
  channelName: string;
  lastReadMessage: Maybe<LastReadMessage>;
}

export type ConversationResource = Resource<Conversation>;

export const whenLocalConferenceRoomResource = (
  conferenceRoomResource: ConferenceRoomResource,
): Maybe<LocalConferenceRoomResource> =>
  whenLocalConferenceRoom(conferenceRoomResource.payload).map((conferenceRoom) => {
    switch (conferenceRoom.kind) {
      case 'daily-live-meeting':
        return resource<DailyConferenceRoom>(conferenceRoomResource.links, conferenceRoom);
    }
  });

export const whenLocalConferenceRoom = (
  conferenceRoom: ConferenceRoom,
): Maybe<LocalConferenceRoom> => {
  switch (conferenceRoom.kind) {
    case 'daily-live-meeting':
      return just(conferenceRoom);
    case 'gtw-live-meeting':
    case 'external-live-meeting':
      return nothing();
  }
};
