import { when } from '@execonline-inc/maybe-adapter';
import { useEffect, useState } from 'react';
import { warnAndNotify } from '../../../../Honeybadger';
import SegmentStore from '../../../../SegmentStore';
import { autoSavingFormFields } from '../../../SegmentReactions/Functions';
import { FieldDescription, FieldValue } from '../../Types';
import { convertToFieldValue } from '../Helpers';
import { fieldHasValue, fieldValueM, validateField, whenMaxLengthValid } from './Functions';

function useFormField(
  segmentStore: SegmentStore,
  fieldDescription: FieldDescription,
  uuid: string,
) {
  const [fieldValue, setFieldValue] = useState<FieldValue[]>([]);

  useEffect(() => {
    const fieldValue = fieldValueM(segmentStore.results, uuid);

    updateFieldValue(fieldValue);
  }, []);

  const updateFieldValue = (value: FieldValue[]) => {
    if (!Array.isArray(value)) {
      warnAndNotify(
        'badPayload',
        `The wrong data is comming from segmentStore results computed`,
        value,
      );
    }
    segmentStore.addEmbeddedFormFieldOutput({
      id: uuid,
      value: value,
      isValid: validateField(value, fieldDescription),
      isRequired: fieldDescription.mode === 'required',
    });

    setFieldValue(value);
  };

  const saveFieldValue = (value: FieldValue[]) => {
    updateFieldValue(value);
    autoSavingFormFields(segmentStore);
  };

  const addValue = (value: FieldValue) => {
    const newFieldValue = fieldValue
      .filter((v) => v.label !== value.label)
      .concat(convertToFieldValue(value.label, value.value));

    saveFieldValue(newFieldValue);
  };

  const removeValue = (value: FieldValue) => {
    const newFieldValue = fieldValue.filter((v) => v.label !== value.label);

    saveFieldValue(newFieldValue);
  };

  const validateAndAddValue = (option: FieldValue) =>
    whenMaxLengthValid(fieldValue, fieldDescription).do(() => addValue(option));

  const onValueChange = (option: FieldValue) =>
    when(fieldHasValue(option, fieldValue), true)
      .do(() => removeValue(option))
      .elseDo(() => validateAndAddValue(option));

  return {
    fieldValue: fieldValue,
    isRequired: fieldDescription.mode === 'required',
    addValue,
    removeValue,
    onValueChange,
    saveFieldValue,
  };
}

export default useFormField;
