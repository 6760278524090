import { whenPositive } from '@execonline-inc/numbers';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { AppyError, callApi } from '../../../Appy';
import { ExperienceFiltersResource } from '../../../Native/AEP/Common/Experience/Types';
import { experienceOptionDecoder } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Experiences/Decoders';
import { CompetencyFilterStore } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/CompetencyFilter';
import { DurationFilterStore } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/DurationFilter';
import { LanguageFilterStore } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/LanguageFilter';
import { SchoolPartnerFilterStore } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/SchoolPartnerFilter';
import { resourceDecoder } from '../../../Resource/Decoders';
import { Link } from '../../../Resource/Types';

export interface FilterStoreStateInitialized {
  kind: 'filter-store-initialized';
}

export interface FilterStoreStateLoading {
  kind: 'filter-store-loading';
}

export interface FilterStoreStateFailed {
  kind: 'filter-store-failed';
  error: AppyError;
}

export interface FilterStoreStateLoaded {
  kind: 'filter-store-loaded';
  filters: ExperienceFiltersResource;
  languageFilterStore: LanguageFilterStore;
  durationFilterStore: DurationFilterStore;
  schoolPartnerFilterStore: SchoolPartnerFilterStore;
  competencyFilterStore: CompetencyFilterStore;
}

export type FilterStoreState =
  | FilterStoreStateInitialized
  | FilterStoreStateLoading
  | FilterStoreStateFailed
  | FilterStoreStateLoaded;

export class FiltersStore {
  @observable state: FilterStoreState = {
    kind: 'filter-store-initialized',
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get isLoading() {
    return this.state.kind === 'filter-store-loading';
  }

  @computed
  get isLoaded() {
    return this.state.kind === 'filter-store-loaded';
  }

  @computed
  get appliedCount(): Maybe<number> {
    if (this.state.kind !== 'filter-store-loaded') {
      return nothing();
    }
    return just(
      this.state.languageFilterStore.appliedCount +
        this.state.durationFilterStore.appliedCount +
        this.state.schoolPartnerFilterStore.appliedCount +
        this.state.competencyFilterStore.appliedCount,
    ).andThen(whenPositive);
  }

  @action
  load = (link: Link) => {
    if (this.isLoading) {
      return;
    }

    this.loading();

    callApi(resourceDecoder(experienceOptionDecoder), {}, link).fork((err) => {
      console.warn('Failed to fetch filters', err);
      this.failed(err);
    }, this.succeeded);
  };

  @action
  private loading = () => {
    this.state = { kind: 'filter-store-loading' };
  };

  @action
  private succeeded = (filters: ExperienceFiltersResource) => {
    this.state = {
      kind: 'filter-store-loaded',
      filters,
      languageFilterStore: new LanguageFilterStore(filters.payload),
      durationFilterStore: new DurationFilterStore(filters.payload),
      schoolPartnerFilterStore: new SchoolPartnerFilterStore(filters.payload),
      competencyFilterStore: new CompetencyFilterStore(filters.payload),
    };
  };

  @action
  private failed = (error: AppyError) => {
    this.state = { kind: 'filter-store-failed', error };
  };

  clearAll = () => {
    if (this.state.kind !== 'filter-store-loaded') {
      return;
    }
    this.state.languageFilterStore.clearSelectedValues();
    this.state.durationFilterStore.clearSelectedValues();
    this.state.schoolPartnerFilterStore.clearSelectedValues();
    this.state.competencyFilterStore.clearSelectedValues();
  };

  apply = () => {
    if (this.state.kind !== 'filter-store-loaded') {
      return;
    }
    this.state.languageFilterStore.applySelectedValues();
    this.state.durationFilterStore.applySelectedValues();
    this.state.schoolPartnerFilterStore.applySelectedValues();
    this.state.competencyFilterStore.applySelectedValues();
  };
}

const experienceFiltersStore = new FiltersStore();
export default experienceFiltersStore;
