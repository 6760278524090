import { CardBody, CardFooter, CardHeader, emptyFragment } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import experiencesStore, { isLoaded } from '../../ExperiencesStore';
import { productCollectionsResourceDecoder } from './Decoder';

import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Task } from 'taskarian';
import { callApi } from '../../../../Appy';
import DiscoveryContext from '../../../../Native/AEP/DiscoveryContext';
import {
  ExperienceSection,
  ExperienceSectionBody,
  ExperienceSectionDescription,
  ExperienceSectionHeader,
  ExperienceSectionTitle,
} from '../../../../Native/AEP/DiscoveryPortal/Common/ExperienceSection';
import UpdateFocusButton from '../../../../Native/AEP/DiscoveryPortal/Common/ExperienceUpdateFocus/UpdateFocusButton';
import { findLinkByT } from '../../../Fetch';
import { PortalExperienceProvider } from '../../PortalExperience/PortalExperienceContextProvider';
import ExperienceBody from '../ExperienceBody';
import ExperienceFooter from '../ExperienceFooter';
import ExperienceHeaderEndIcon from '../ExperienceHeaderEndIcon';
import ExperienceHeaderLogo from '../ExperienceHeaderLogo';
import ProgramExperienceCard from '../ProgramExperienceCard';
import { ProductCollectionsResource } from './types';

function ProductCollections() {
  const { data, isLoading, error } = useQuery<ProductCollectionsResource, Error>({
    queryKey: ['product-collections'],
    queryFn: () =>
      Task.succeed(experiencesStore.links)
        .andThen(findLinkByT({ rel: 'product-collections' }))
        .andThen(callApi(productCollectionsResourceDecoder, {}))
        .resolve(),
    enabled: isLoaded(experiencesStore), // Only run the query when experiencesStore is loaded
  });

  // If the store isn't loaded yet, show loading state
  if (!isLoaded(experiencesStore)) {
    return <div>Loading experiences...</div>;
  }

  if (isLoading) {
    return <div>Loading product collections...</div>; // Consider creating a skeleton component like TasksSkeleton
  }

  if (error || !data) {
    console.error('Failed to load product collections:', error);
    return emptyFragment();
  }

  // For now, just return an empty fragment
  // When ready to implement the view, uncomment and update the ProductCollectionsView component
  return fromArrayMaybe(data.payload)
    .map((list) => (
      <>
        {list.toArray().map((productCollection) => (
          <React.Fragment key={productCollection.id}>
            <div className="bg-white shadow-section">
              <DiscoveryContext.Provider
                value={{
                  kind: productCollection.kind,
                  productCollectionId: productCollection.id,
                }}
              >
                <ExperienceSection>
                  <ExperienceSectionHeader>
                    <div className="flex flex-1 flex-col-reverse items-start gap-4 sm:flex-row">
                      <ExperienceSectionTitle>
                        <AlreadyTranslated content={productCollection.name} />
                      </ExperienceSectionTitle>
                      <UpdateFocusButton
                        productCollectionId={productCollection.id}
                        scopeType={'product-collection'}
                      />
                    </div>
                    {productCollection.description
                      .map((description) => (
                        <ExperienceSectionDescription>
                          <AlreadyTranslated content={description} />
                        </ExperienceSectionDescription>
                      ))
                      .getOrElse(emptyFragment)}
                  </ExperienceSectionHeader>
                  <ExperienceSectionBody>
                    {/* <ExpandExperiences productCollection={productCollection}>
                      {fromEmpty(filteredExperienceResources)
                        .map(() => (
                          <TileCarouselExperiences
                            invitationUuid={invitationUuid}
                            experienceResources={filteredExperienceResources}
                            perPageCount={productCollection.displayPerPageCount}
                            enrollmentStore={enrollmentStore}
                          />
                        ))
                        .getOrElse(() => (
                          <NoExperiences searchValue={nothing()} />
                        ))}
                    </ExpandExperiences> */}
                    {isLoaded(experiencesStore) && (
                      <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        {experiencesStore.state.experiences.payload.experiences
                          .filter((experience) =>
                            productCollection.experiences.includes(experience.payload.id),
                          )
                          .map((experience) => (
                            <PortalExperienceProvider
                              experience={experience}
                              key={experience.payload.id}
                            >
                              <ProgramExperienceCard
                                className={
                                  'pointer-events-none h-full min-h-[14rem] w-[20rem] bg-white text-gray-800 shadow-tile-preview'
                                }
                              >
                                <CardHeader
                                  className={'h-[2.375rem] rounded-t py-1.5'}
                                  endContent={<ExperienceHeaderEndIcon />}
                                >
                                  <ExperienceHeaderLogo />
                                </CardHeader>
                                <CardBody>
                                  <ExperienceBody />
                                </CardBody>
                                <CardFooter>
                                  <ExperienceFooter />
                                </CardFooter>
                              </ProgramExperienceCard>
                            </PortalExperienceProvider>
                          ))}
                      </div>
                    )}
                  </ExperienceSectionBody>
                </ExperienceSection>
              </DiscoveryContext.Provider>
            </div>
          </React.Fragment>
        ))}
      </>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ProductCollections);
