import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { ExperienceDictionaryEntryResponse } from '../../ExperiencesDictionary/Types';
import usePortalExperienceContext from '../PortalExperience/UsePortalExperienceContext';
import ExperienceProductDetails from './ExperienceProductDetails';
import ProgramDetailsItems from './ProgramDetailsItems';
import ProgramOverviewItems from './ProgramOverviewItems';

function ExperienceBodyContent() {
  const experience = usePortalExperienceContext();
  return experience
    .andThen((lookupResults) => lookupResults.experience)
    .map((experience) => <_ExperienceBodyContent experience={experience} />)
    .getOrElse(emptyFragment);
}

interface ExperienceBodyContentProps {
  experience: ExperienceDictionaryEntryResponse;
}

const _ExperienceBodyContent = observer(({ experience }: ExperienceBodyContentProps) => {
  switch (experience.payload.offeringType) {
    case 'aep':
    case 'epc':
    case 'msuite':
      return <_ProgramOverviewAndDetails experience={experience} />;
    case 'coaching':
    case 'group-coaching':
    case 'program-sequence':
      return <ExperienceProductDetails />;
  }
});

const _ProgramOverviewAndDetails = observer(({ experience }: ExperienceBodyContentProps) => {
  return (
    <>
      <ProgramOverviewItems experience={experience.payload} />
      <ProgramDetailsItems />
    </>
  );
});

export default observer(ExperienceBodyContent);
