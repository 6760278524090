import { useEffect } from 'react';
import experiencesStore from '.';
import { findLink } from '../../../LinkyLinky';
import useCaseStore from '../../../RegistrationInvitation/UseCaseStore';

function useExperiencesStore() {
  useEffect(() => {
    findLink('experiences', useCaseStore.links).do(experiencesStore.loadExperiences);
  }, [useCaseStore.links]);
}

export default useExperiencesStore;
