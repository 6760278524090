import { Maybe, nothing } from 'maybeasy';
import { useContext, useEffect, useState } from 'react';
import { Resource } from '../../../Resource/Types';
import { PortalExperienceContext } from './PortalExperienceContext';
import portalExperiencePricesStore from './PortalExperiencePricesStore';

export function usePortalExperienceContext() {
  const experience = useContext(PortalExperienceContext);

  return experience;
}

export function usePortalExperiencePrice() {
  const [experiencePrice, setExperiencePrice] = useState<Maybe<Resource<number>>>(nothing());
  const experience = usePortalExperienceContext();

  useEffect(() => {
    experience
      .andThen((e) => e.experienceID.links.experiencePrices)
      .do((link) => {
        portalExperiencePricesStore.getPrice(link).then(setExperiencePrice);
      });
  }, [experience]);

  return experiencePrice;
}

export default usePortalExperienceContext;
