import {
  emptyFragment,
  Popover,
  useOpenable,
  VerticalDotsIcon,
} from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { findLinkBy } from '../../../../Links';
import ProgramStore from '../../../../ProgramStore';
import { ProgramDetailResource } from '../../../../ProgramStore/Types';
import { T } from '../../../../Translations';
import { Typography } from '../../../UI';
import ConfirmProgramDrop from './ConfirmProgramDrop';

interface Props {
  programDetailResource: ProgramDetailResource;
  programStore: ProgramStore;
}

function ProgramDropping({ programDetailResource, programStore }: Props) {
  const { onOpen, isOpen, onClose } = useOpenable();
  const { onClose: closePopover } = useOpenable();

  return (
    <>
      {findLinkBy({ rel: 'dropping-program' }, programStore.programLinks)
        .map(() => (
          <Popover
            trigger="click"
            placement="bottom"
            renderTrigger={(ref, getReferenceProps) => (
              <div ref={ref} {...getReferenceProps()}>
                <VerticalDotsIcon className="cursor-pointer" />
              </div>
            )}
            children={({ onClose }) => (
              <Typography
                color="dark"
                variant="body2"
                fontWeight="medium"
                onClick={() => {
                  onClose();
                  onOpen();
                  closePopover();
                }}
                className="cursor-pointer"
              >
                <T kind="Drop Program" />
              </Typography>
            )}
          />
        ))
        .getOrElse(emptyFragment)}
      <ConfirmProgramDrop
        isOpen={isOpen}
        onClose={onClose}
        programStore={programStore}
        programDetailResource={programDetailResource}
      />
    </>
  );
}
export default observer(ProgramDropping);
