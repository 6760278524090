import { observer } from 'mobx-react';
import * as React from 'react';
import { AnnouncementResource } from '../../Decoders/Types';
import DefaultContent from './DefaultContent';
import DigitalCertificateContent from './DigitalCertificateContent';

interface Props {
  announcement: AnnouncementResource;
}

const Content: React.FC<Props> = ({ announcement }) => {
  switch (announcement.payload.kind) {
    case 'learning-partner-assignment-announcement':
    case 'expert-feedback-announcement':
    case 'embeddable-assets-announcement':
    case 'upload-manifest-announcement':
    case 'shared-to-personalized-resources-announcement':
    case 'shared-to-live-meeting-recordings-announcement':
    case 'coaching-survey-announcement':
    case 'ai-written-feedback-announcement':
      return <DefaultContent announcement={announcement} />;
    case 'digital-certificate-announcement':
      return (
        <DigitalCertificateContent
          announcement={announcement}
          footerHTML={announcement.payload.footerHTML}
        />
      );
  }
};

export default observer(Content);
