import {
  explicitMaybe,
  nullableBlankString,
  numberToStringDecoder,
  stringLiteral,
} from '@execonline-inc/decoders';
import { Decoder, array, dateISO, field, nullable, number, oneOf, string, succeed } from 'jsonous';
import { fromEmpty } from 'maybeasy';
import { CountryOption } from '../CountryOptionsStore/Types';
import { CountryRegionOption } from '../CountryRegionOptionsStore/Types';
import { Salutation } from '../ProfileFormStore/Types';
import { resourceDecoder } from '../Resource/Decoders';
import { Profile, ProfileResource, TimeZone, WorkPhone } from './Types';

const initialsDecoder = explicitMaybe(string);

/**
 * There is an issue with the Profile API and decoder in that name will
 * always be a Just value, even when the name is an empty string
 * (https://execonline.leankit.com/card/773532815). That's why there's the
 * notWhiteSpaceOnlyDecoder in use here. Work on that defect should include
 * removing the use of this decoder.
 */
const notWhitespaceOnlyDecoder = string.map((s) => s.trim()).map(fromEmpty);

export const timeZoneDecoder: Decoder<TimeZone> = succeed({})
  .assign('fullName', field('full_name', string))
  .assign('name', field('name', string))
  .assign('code', field('code', string));

export const workPhoneDecoder: Decoder<WorkPhone> = succeed({})
  .assign('number', field('work_phone', nullableBlankString))
  .assign('extension', field('work_extension', nullableBlankString));

export const countryDecoder: Decoder<CountryOption> = succeed({})
  .assign('id', field('id', numberToStringDecoder))
  .assign('name', field('name', string));

export const salutationDecoder: Decoder<Salutation> = oneOf([
  stringLiteral<Salutation>('Mr.'),
  stringLiteral<Salutation>('Ms.'),
  stringLiteral<Salutation>('Mx.'),
  stringLiteral<Salutation>('Dr.'),
]);

export const regionDecoder: Decoder<CountryRegionOption> = succeed({})
  .assign('id', field('id', numberToStringDecoder))
  .assign('name', field('name', string));

export const profileDecoder: Decoder<Profile> = succeed({})
  .assign('userId', field('id', number))
  .assign('email', field('email', string))
  .assign('shortName', field('short_name', nullable(string)))
  .assign('name', field('name', notWhitespaceOnlyDecoder))
  .assign('position', field('position', nullable(string)))
  .assign('initials', field('initials', initialsDecoder))
  .assign('division', field('division', nullable(string)))
  .assign('country', field('country', explicitMaybe(countryDecoder)))
  .assign('region', field('region', explicitMaybe(regionDecoder)))
  .assign('cellPhone', field('cell_phone', nullable(string)))
  .assign('organization', field('organization', nullable(string))) // would like to deprecate this version of the field
  .assign('linkedInPage', field('linked_in_page', nullable(string)))
  .assign('facebookPage', field('facebook_page', nullable(string)))
  .assign('twitterPage', field('twitter_page', nullable(string)))
  .assign('timeZone', field('time_zone', nullable(timeZoneDecoder)))
  .assign('createdAt', field('created_at', dateISO))
  .assign('organizationID', field('organization_id', explicitMaybe(number)))
  .assign('organizationName', field('organization_name', explicitMaybe(string)))
  .assign('organizationAccountUID', field('organization_account_uid', explicitMaybe(string)))
  .assign(
    'organizationPlatformOrgUID',
    field('organization_platform_org_uid', explicitMaybe(string)),
  )
  .assign('productLicenseIds', field('product_license_ids', array(number)))
  .assign('productLicenseTypeIds', field('product_license_type_ids', array(number)))
  .assign('workPhone', workPhoneDecoder);

export const profileResourceDecoder: Decoder<ProfileResource> = resourceDecoder(profileDecoder);
