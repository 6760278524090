import { warn } from '@execonline-inc/logging';
import { seconds } from '@execonline-inc/time';
import { Decoder, field, string, succeed } from 'jsonous';
import { observer } from 'mobx-react';
import * as Pusher from 'pusher-js';
import * as React from 'react';
import { warnAndNotify } from '../../../Honeybadger';
import { PusherSubscriptionError } from '../../PresenceChannel/PresenceMembersStore/Types';
import BreakoutChannelStore from '../BreakoutChannelStore';
import { pusherEventBreakoutPayload } from './Decoders';
import { PusherEventBreakoutTime } from './Types';

interface Props {
  channel: Pusher.Channel;
  store: BreakoutChannelStore;
}

export const subscriptionErrorDecoder: Decoder<PusherSubscriptionError> = succeed({})
  .assign('error', field('error', string))
  .assign('type', field('type', string));

class Binding extends React.Component<Props, {}> {
  componentDidMount() {
    const { channel } = this.props;

    channel.bind('pusher:subscription_succeeded', (data: {}) => {
      // Do nothing...
    });

    channel.bind('pusher:subscription_error', (data: unknown) =>
      subscriptionErrorDecoder.decodeAny(data).do((error_data: PusherSubscriptionError) =>
        warn('{PRESENCE_FAIL}', `Failed to subscribe to channel ${channel.name}`, {
          ...error_data,
        }),
      ),
    );

    channel.bind('event:breakout-time', (data: PusherEventBreakoutTime) => {
      pusherEventBreakoutPayload.decodeAny(data).cata({
        Ok: ({ breakoutEndAt }) => {
          this.props.store.timeRemainingStore.startTimer(seconds(breakoutEndAt));
        },
        Err: (e) =>
          warnAndNotify('{BREAKOUT TIME UPDATE FAIL}', 'Unexpected event:breakout-time payload', e),
      });
    });
  }

  componentWillUnmount() {
    const { channel } = this.props;
    channel.unbind_all();
  }

  render() {
    return null;
  }
}

export default observer(Binding);
