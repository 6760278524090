import { explicitMaybe, stringLiteral } from '@execonline-inc/decoders';
import { Decoder, array, at, field, number, oneOf, string, succeed } from 'jsonous';
import { profileResourceDecoder } from '../../ProfileStore/Decoders';
import { resourceDecoder } from '../../Resource/Decoders';
import { assignmentResourceDecoder } from '../../TeamResourceStore/Decoders';
import { sanitizedHtmlDecoder } from '../../components/LinkifyWithCharEntities';
import {
  Coach,
  CoachResource,
  Team,
  TeamChat,
  TeamChatResource,
  TeamLinkNode,
  TeamMember,
  TeamMemberResource,
  TeamPageContent,
  TeamResource,
  TeamsMetadata,
  TeamsResource,
} from '../Types';
import {
  conversationResourceDecoder,
  localConferenceRoomResourceDecoder,
} from './ConferenceRoomDecoders';

const teamMemberDecoder: Decoder<TeamMember> = succeed({})
  .assign('id', field('id', number))
  .assign('email', field('email', string))
  .assign('profile', field('profile', profileResourceDecoder));

export const teamMemberResourceDecoder: Decoder<TeamMemberResource> =
  resourceDecoder(teamMemberDecoder);

const coachDecoder: Decoder<Coach> = succeed({})
  .assign('id', field('id', number))
  .assign('email', field('email', string))
  .assign('participantType', field('participant_type', stringLiteral('Coach')))
  .assign('profile', field('profile', profileResourceDecoder));

export const coachResourceDecoder: Decoder<CoachResource> = resourceDecoder(coachDecoder);

export const teamLinkDecoder: Decoder<TeamLinkNode> = succeed({})
  .assign('kind', succeed<'team-link-node'>('team-link-node'))
  .assign('teamId', at(['attribs', 'data-attr-team-id'], string));

const pinnedMessageDecoder = explicitMaybe(sanitizedHtmlDecoder);

const teamChatDecoder: Decoder<TeamChat> = succeed({})
  .assign('pinnedMessage', field('pinned_message', pinnedMessageDecoder))
  .assign('conversationResource', field('conversation', conversationResourceDecoder))
  .assign(
    'breakoutConversationResource',
    field('breakout_conversation', conversationResourceDecoder),
  )
  .assign('messagingChannel', field('messaging_channel', string));

export const teamChatResourceDecoder: Decoder<TeamChatResource> = resourceDecoder(teamChatDecoder);

const teamPageContentDecoder: Decoder<TeamPageContent> = oneOf([
  stringLiteral<TeamPageContent>('Chat'),
  stringLiteral<TeamPageContent>('Files'),
]);

const teamDecoder: Decoder<Team> = succeed({})
  .assign('name', field('name', string))
  .assign('courseId', field('course_id', number))
  .assign('id', field('id', number))
  .assign('teamEventCount', field('team_events_count', number))
  .assign('teamPageContent', field('team_page_content', teamPageContentDecoder))
  .assign(
    'conferenceRoom',
    field('conference_room', explicitMaybe(localConferenceRoomResourceDecoder)),
  )
  .assign('coaches', field('coaches', array(coachResourceDecoder)))
  .assign('teamMembers', field('team_members', array(teamMemberResourceDecoder)))
  .assign('teamChat', field('team_chat', teamChatResourceDecoder))
  .assign('assignments', field('assignments', array(assignmentResourceDecoder)))
  .assign('associatedEventIds', field('associated_event_ids', array(number)))
  .assign('kind', succeed<'team'>('team'));

const teamsMetadataDecoder: Decoder<
  Pick<TeamsMetadata, Exclude<keyof TeamsMetadata, 'unreadTeamChatMessages'>>
> = succeed({})
  .assign('currentPage', field('current_page', number))
  .assign('pageCount', field('page_count', number))
  .assign('perPage', field('per_page', number))
  .assign('resultsCount', field('results_count', number));

const unreadTeamChatCountsDecoder = succeed({})
  .assign(
    'unreadCount',
    at(
      ['payload', 'team_chat', 'payload', 'conversation', 'payload', 'unread_message_count'],
      explicitMaybe(number),
    ),
  )
  .assign('teamId', at(['payload', 'id'], number))
  .assign('teamPageContent', at(['payload', 'team_page_content'], teamPageContentDecoder));

const teamsMetadataDecoderWithChatCounts: Decoder<TeamsMetadata> = field(
  'metadata',
  teamsMetadataDecoder,
).assign('unreadTeamChatMessages', field('payload', array(unreadTeamChatCountsDecoder)));

export const teamResourceDecoder: Decoder<TeamResource> = resourceDecoder(teamDecoder);

export const teamsResourceDecoder: Decoder<TeamsResource> = resourceDecoder(
  array(teamResourceDecoder),
).assign('metadata', teamsMetadataDecoderWithChatCounts);
