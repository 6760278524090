import { stringLiteral } from '@execonline-inc/decoders';
import { warn } from '@execonline-inc/logging';
import {
  linksDecoder as linksDecoderR,
  resourceDecoder as resourceDecoderR,
  ResourceWithErrors,
  resourceWithErrorsDecoder as resourceWithErrorsDecoderR,
} from '@execonline-inc/resource';
import { identity } from '@kofno/piper';
import { Method } from 'ajaxian';
import { Decoder, oneOf, string } from 'jsonous';
import { err } from 'resulty';
import { createDecoderFromStructure } from '../../Decoders/Structured';
import { warnAndNotify } from '../../Honeybadger';
import { Link, Rel, Resource, toRel } from '../Types';

export const linksDecoder = linksDecoderR(toRel);

const methodDecoder = oneOf([
  stringLiteral('get').map<Method>(identity),
  stringLiteral('post').map<Method>(identity),
  stringLiteral('put').map<Method>(identity),
  stringLiteral('patch').map<Method>(identity),
  stringLiteral('delete').map<Method>(identity),
  stringLiteral('head').map<Method>(identity),
  stringLiteral('options').map<Method>(identity),
]);

export const relDecoder = new Decoder<Rel>((val) => {
  if (typeof val !== 'string') {
    return err(`Expected rel to be a string, got ${typeof val}`);
  }
  return toRel(val);
});

export const linkDecoder: Decoder<Link> = createDecoderFromStructure({
  rel: relDecoder,
  href: string,
  type: string,
  method: methodDecoder,
});

export const resourceDecoder: <T>(payloadDecoder: Decoder<T>) => Decoder<Resource<T>> =
  resourceDecoderR(toRel);

export const resourceWithErrorsDecoder: <T>(
  payloadDecoder: Decoder<T>,
) => Decoder<ResourceWithErrors<T, Rel>> = resourceWithErrorsDecoderR(toRel);

export { errorDecoder } from '@execonline-inc/resource';

export const warnAndNotifyDecoderError =
  (msg: string) =>
  (error: unknown): void => {
    warn('DecoderError', error);
    warnAndNotify('DecoderError', msg, { error });
  };
