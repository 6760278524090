import { just, Maybe, nothing } from 'maybeasy';
import { BehaviorSubject } from 'rxjs';
import { ExperienceDictionaryEntryResponse } from './Types';

type ObservableEntry = BehaviorSubject<Maybe<ExperienceDictionaryEntryResponse>>;

class ExperiencesDictionaryStore {
  private experiences: Record<number, ObservableEntry> = {};

  addExperiences(experiences: ReadonlyArray<ExperienceDictionaryEntryResponse>): void {
    experiences.forEach((experience) => {
      const id = experience.payload.id;
      const experienceEntry = this.getExperience(id);
      experienceEntry.next(just(experience));
    });
  }

  getExperience(id: number): ObservableEntry {
    let experience = this.experiences[id];
    if (!experience) {
      experience = new BehaviorSubject(nothing());
      this.experiences[id] = experience;
    }
    return experience;
  }

  removeExperience(id: number): void {
    delete this.experiences[id];
  }
}

const experiencesDictionaryStore = new ExperiencesDictionaryStore();
export default experiencesDictionaryStore;
