import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from '../../../../Resource/Types';
import { TPlainTextKey } from '../../../../Translations';
import { Auth } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import RegistrationFormHeader from '../../RegistrationFormHeader';
import RegistrationView from '../../RegistrationView';
import { SharedInvitationResource } from '../../SharedOpenEnrollment/SharedInvitationResourceStore/Types';
import { AuthSignIn } from '../Types';
import Form from './Form';
import Instructions from './Instructions';
import SignInReactions from './SignInReactions';

interface Props {
  authForm: AuthSignIn;
  sharedInvitation: SharedInvitationResource;
  auth: Auth;
  links: ReadonlyArray<Link>;
  emailAddress: string;
  readyLockedAccess: (message: Maybe<TPlainTextKey>) => void;
}

const SignIn: React.FC<Props> = ({
  authForm,
  sharedInvitation,
  auth,
  links,
  emailAddress,
  readyLockedAccess,
}) => {
  return (
    <>
      <RegistrationView allowLanguagePicker={true}>
        <RegistrationFormHeader
          notifiableStore={authForm.store}
          sharedInvitationResource={sharedInvitation}
        >
          <Instructions auth={authForm.auth} sharedInvitationResource={sharedInvitation} />
        </RegistrationFormHeader>
        <Form authForm={authForm} auth={auth} emailAddress={emailAddress} />
      </RegistrationView>
      <SignInReactions
        authForm={authForm}
        registerResourceLinks={links}
        readyLockedAccess={readyLockedAccess}
      />
    </>
  );
};

export default observer(SignIn);
