import {
  base64ToUTF8Decoder,
  explicitMaybe,
  jsonParserDecoder,
  pipeD,
} from '@execonline-inc/decoders';
import { Decoder, array, at, field, number, string, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import { NextExperience, NextExperiences, NextExperiencesNode } from './Types';

export const nextExperienceDecoder: Decoder<NextExperience> = succeed({})
  .assign('id', field('id', number))
  .assign('title', field('title', explicitMaybe(string)))
  .assign('description', field('description', explicitMaybe(string)));

export const nextExperienceResourceDecoder = resourceDecoder(nextExperienceDecoder);

export const nextExperiencesDecoder: Decoder<NextExperiences> = succeed({}).assign(
  'programs',
  field('programs', array(nextExperienceResourceDecoder)),
);

export const nextExperiencesResourceDecoder = resourceDecoder(nextExperiencesDecoder);

export const chooseNextExperienceResourceDecoder: Decoder<NextExperiencesNode> = succeed({})
  .assign(
    'nextExperiences',
    at(
      ['attribs', 'data-attr-choose-next'],
      pipeD(jsonParserDecoder(base64ToUTF8Decoder), nextExperiencesDecoder),
    ),
  )
  .assign('kind', succeed<'discovery-portal-choose-next'>('discovery-portal-choose-next'));
