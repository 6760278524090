import { assertNever } from '@kofno/piper';
import {
  ExternalConferenceRoomResource,
  GoToWebinarConferenceRoomResource,
} from '../../Conference/Types';
import {
  EmbedToastConferenceRoomResource,
  ToastConferenceRoomResource,
} from '../../ToastsStore/Types';

const isGoToWebinarConferenceRoom = (
  resource: ToastConferenceRoomResource,
): resource is GoToWebinarConferenceRoomResource => {
  return resource.payload.kind === 'gtw-live-meeting';
};

const isExternalConferenceRoom = (
  resource: ToastConferenceRoomResource,
): resource is ExternalConferenceRoomResource => {
  return resource.payload.kind === 'external-live-meeting';
};

const isEmbedToastConferenceRoom = (
  resource: ToastConferenceRoomResource,
): resource is EmbedToastConferenceRoomResource => {
  return resource.payload.kind === 'embed-toast-live-meeting';
};

type ConferenceRoomProviderCata<T> = {
  gtw: (v: GoToWebinarConferenceRoomResource) => T;
  external: (v: ExternalConferenceRoomResource) => T;
  embedToast: (v: EmbedToastConferenceRoomResource) => T;
};

export const conferenceRoomProviderCata = <T>(
  handlers: ConferenceRoomProviderCata<T>,
  v: ToastConferenceRoomResource,
): T => {
  if (isGoToWebinarConferenceRoom(v)) {
    return handlers.gtw(v);
  } else if (isExternalConferenceRoom(v)) {
    return handlers.external(v);
  } else if (isEmbedToastConferenceRoom(v)) {
    return handlers.embedToast(v);
  } else {
    return assertNever(v);
  }
};
