import { cn } from '@execonline-inc/execonline-ui';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { Experience } from '../../../Common/Experience/Types';
import {
  EnrollmentCard,
  ExperienceActions,
  ExperienceCompetencies,
  ExperienceDescription,
  ExperienceLanguageAvailability,
  ExperienceTitle,
  ExperiencesLogo,
} from '../../Common';
import ExperienceVideo from '../../Common/ExperienceVideo';
import ExperienceOverview from './ExperienceOverview';
import ExperiencesStore from '../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';

interface Props {
  experience: Experience;
  experiencesStore: ExperiencesStore;
}

function LearnMoreProgramHero({ experience, experiencesStore }: Props) {
  return (
    <>
      <div className="flex w-full flex-col gap-x-28 lg:flex-row">
        <div className="w-full lg:w-3/5">
          <ExperiencesLogo experience={experience} />
          <ExperienceTitle title={just(experience.title)} />
          <ExperienceCompetencies
            className="mb-4 sm:mb-7"
            competencies={experience.primaryCompetencies}
          />
          <ExperienceDescription
            className="mb-10 sm:mb-12"
            description={experience.publicDetailedDescriptionHtml}
          />
          <ExperienceLanguageAvailability
            className="mb-4 text-gray-600 sm:mb-8"
            experience={experience}
          />
          <ExperienceOverview experience={experience} />
        </div>
        <div
          className={cn(
            'flex flex-col items-center',
            'mx-auto md:gap-x-4 lg:flex-col lg:justify-normal lg:gap-x-0 lg:gap-y-6',
            'empty:hidden',
          )}
        >
          <ExperienceVideo experiencesStore={experiencesStore} />
          <EnrollmentCard className="mt-10 lg:mt-0" experience={experience} />
        </div>
      </div>
      <ExperienceActions experience={experience} />
    </>
  );
}

export default observer(LearnMoreProgramHero);
