import { BadgeCheckIcon, emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import ViewSample from '../../../Native/AEP/ExperienceDetailView/Common/ExperienceOverview/ViewSample';
import { T } from '../../../Translations';
import usePortalExperienceContext from '../PortalExperience/UsePortalExperienceContext';
import useSchoolPartnersStore from '../SchoolPartner/SchoolPartnersStore/UseSchoolPartnersStore';

function Certificate() {
  const experienceResource = usePortalExperienceContext();
  const { schoolPartner } = useSchoolPartnersStore();

  return just({})
    .assign('schoolPartnerResource', schoolPartner)
    .assign(
      'certLink',
      experienceResource.andThen((er) => er.experienceID.links.digitalCert),
    )
    .map(({ schoolPartnerResource, certLink }) => (
      <div className="flex items-center gap-x-2.5">
        <BadgeCheckIcon color="black" size="md" />
        <Typography className="flex flex-wrap items-center" variant="bodyMedium" color="black">
          <T
            kind="{{schoolName}} Certificate"
            schoolName={schoolPartnerResource.payload.name.text}
          />
          <Typography className="ml-0.5" variant="bodySmall" color="black" as="span">
            (<ViewSample certLink={certLink} />)
          </Typography>
        </Typography>
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(Certificate);
