import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import GroupCoachingProductBadge from '../../../Native/AEP/Common/Experience/ExperienceFooter/ExperienceProductBadge/GroupCoachingProductBadge';
import LeadershipCoachingProductBadge from '../../../Native/AEP/Common/Experience/ExperienceFooter/ExperienceProductBadge/LeadershipCoachingProductBadge';
import useProductDetailsStore from '../ProductDetails/ProductDetailsStore/UseProductDetailsStore';

function ExperienceProductDetailsBadge() {
  const { productDetails } = useProductDetailsStore();

  return productDetails
    .map(({ payload }) => payload)
    .map((productDetails) => {
      switch (productDetails.kind) {
        case 'group-coaching':
          return <GroupCoachingProductBadge />;
        case 'leadership-coaching':
          return <LeadershipCoachingProductBadge />;
        case 'program-sequence':
          return emptyFragment();
      }
    })
    .getOrElse(emptyFragment);
}

export default observer(ExperienceProductDetailsBadge);
