import { emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import Currency from '../../../Currency';
import { usePortalExperiencePrice } from '../PortalExperience/PortalExperiencePricesStore';

function ExperiencePrice() {
  const price = usePortalExperiencePrice();
  return price
    .map(({ payload }) => (
      <Typography className="text-[#0E0E0E]" as="span" fontWeight="semibold">
        <Currency
          priceInDollars={payload / 100}
          options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
        />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ExperiencePrice);
