import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { LearnMoreGroupCoachingDetails } from '../../../Native/AEP/ExperienceDetailView/LearnMoreGroupCoaching';
import { LearnMoreLeadershipDetails } from '../../../Native/AEP/ExperienceDetailView/LearnMoreLeadershipCoaching';
import { usePortalExperienceContext } from '../PortalExperience/UsePortalExperienceContext';
import useProductDetailsStore from '../ProductDetails/ProductDetailsStore/UseProductDetailsStore';
import LearningJourneyDetails from './LearningJourneyDetails';

function ExperienceProductDetails() {
  const experience = usePortalExperienceContext();
  const { productDetails } = useProductDetailsStore();

  return productDetails
    .map((productDetails) => {
      const details = productDetails.payload;
      switch (details.kind) {
        case 'leadership-coaching':
          return <LearnMoreLeadershipDetails productDetails={details} />;
        case 'group-coaching':
          return <LearnMoreGroupCoachingDetails productDetails={details} />;
        case 'program-sequence':
          const pd = details;
          return experience
            .map((experience) => (
              <LearningJourneyDetails experience={experience} productDetails={pd} />
            ))
            .getOrElse(emptyFragment);
      }
    })
    .getOrElse(emptyFragment);
}

export default observer(ExperienceProductDetails);
