import { explicitMaybe, mergeObjectDecoders, stringLiteral } from '@execonline-inc/decoders';
import { alreadyTranslatedText } from '@execonline-inc/translations';
import { identity } from '@kofno/piper';
import { Decoder, array, field, number, oneOf, succeed } from 'jsonous';
import { fromArrayMaybe } from 'nonempty-list';
import { resourceDecoder } from '../../Resource/Decoders';
import { baseResourceDecoder, documentPartsDecoder } from '../../ResourceStore/Decoders';
import {
  DocumentResource,
  Linkable,
  LinkableBase,
  LinkableDiscoveryPortalWizardScope,
  LinkableDiscoveryPortalWizardType,
  LinkableKind,
  LinkableResource,
  LinkableResourceType,
} from './Types';

const documentPartsResourceDecoder: Decoder<DocumentResource> = resourceDecoder(
  mergeObjectDecoders(baseResourceDecoder, documentPartsDecoder),
);

export const linkableKindDecoder: Decoder<LinkableKind> = oneOf([
  stringLiteral<LinkableKind>('resource'),
  stringLiteral<LinkableKind>('discovery-portal-wizard'),
]);

const linkableBaseDecoder: Decoder<LinkableBase> = succeed({})
  .assign('label', field('label', alreadyTranslatedText))
  .map<LinkableBase>(identity);

export const linkableResourceTypeDecoder: Decoder<LinkableResourceType> = linkableBaseDecoder
  .assign('kind', field('kind', stringLiteral('resource')))
  .assign('resource', field('resource', explicitMaybe(documentPartsResourceDecoder)));

const linkableDiscoveryPortalWizardScopeDecoder: Decoder<LinkableDiscoveryPortalWizardScope> =
  succeed({})
    .assign('id', field('id', number))
    .assign('kind', field('kind', stringLiteral('product-collection')));

export const linkableDiscoveryPortalTypeDecoder: Decoder<LinkableDiscoveryPortalWizardType> =
  linkableBaseDecoder
    .assign('kind', field('kind', stringLiteral('discovery-portal-wizard')))
    .assign('scope', field('scope', array(linkableDiscoveryPortalWizardScopeDecoder)));

const linkableDecoder: Decoder<Linkable> = oneOf<Linkable>([
  linkableResourceTypeDecoder.map<Linkable>(identity),
  linkableDiscoveryPortalTypeDecoder.map<Linkable>(identity),
]);

export const linkableResourceDecoder: Decoder<LinkableResource> = resourceDecoder(linkableDecoder);

export const linkablesResourcesDecoder = resourceDecoder(
  array(linkableResourceDecoder).map((a) => fromArrayMaybe(a)),
);
