import { mapMaybe } from '@execonline-inc/collections';
import { IconSize, MediaQueryBreakPoint } from '@execonline-inc/execonline-ui';
import { identity } from '@kofno/piper';
import { just } from 'maybeasy';
import { CurrentUserResource } from '../../../../CurrentUser/Types';
import { findLink } from '../../../../LinkyLinky';
import { Link } from '../../../../Resource/Types';
import ClientPortalGraphic from '../../../Cutesies/ClientPortalGraphic';
import EnterprisePortalAppGraphic from '../../../Cutesies/EnterprisePortalAppGraphic';
import LearningPlatformGraphic from '../../../Cutesies/LearningPlatformGraphic';
import { WaffleIconListItem } from '../../../Dropdown/Types';

export const enterprisePortalMenuItem = (link: Link): WaffleIconListItem => ({
  kind: 'waffle-icon-list-item',
  id: 'enterprise-portal',
  name: 'Enterprise Portal',
  href: link.href,
  icon: <EnterprisePortalAppGraphic />,
  check: false,
});

export const learningPlatformMenuItem: WaffleIconListItem = {
  kind: 'waffle-icon-list-item',
  id: 'learning',
  name: 'Learning',
  href: '#',
  icon: <LearningPlatformGraphic />,
  check: true,
};

export const clientPortalMenuItem = (link: Link): WaffleIconListItem => ({
  kind: 'waffle-icon-list-item',
  id: 'admin-studio',
  name: 'AdminStudio',
  href: link.href,
  icon: <ClientPortalGraphic />,
  check: false,
});

export const dropdownMenuItems = (
  currentUser: CurrentUserResource,
): ReadonlyArray<WaffleIconListItem> =>
  mapMaybe(identity, [
    findLink('client-portal-app', currentUser.links).map(clientPortalMenuItem),
    findLink('enterprise-portal-app', currentUser.links).map(enterprisePortalMenuItem),
    just(learningPlatformMenuItem),
  ]);

export const getIconSize = (breakPoint: MediaQueryBreakPoint): IconSize => {
  switch (breakPoint) {
    case 'xl':
    case 'lg':
      return 'xl';
    case 'md':
    case 'sm':
    case 'xs':
      return 'lg';
  }
};
