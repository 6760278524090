import { Maybe, nothing } from 'maybeasy';
import { useEffect, useState } from 'react';
import schoolPartnersStore from '.';
import { findLink } from '../../../../LinkyLinky';
import { SchoolPartner } from '../../../../Native/AEP/Common/Experience/SchoolPartner';
import { Resource } from '../../../../Resource/Types';
import experiencesStore from '../../ExperiencesStore';
import usePortalExperienceContext from '../../PortalExperience/UsePortalExperienceContext';

function useSchoolPartnersStore() {
  const experience = usePortalExperienceContext();

  const [schoolPartner, setSchoolPartner] = useState<Maybe<Resource<SchoolPartner>>>(nothing());

  useEffect(() => {
    const behavior = findLink('school-partners', experiencesStore.links)
      .do(schoolPartnersStore.loadAllSchoolPartnerResources)
      .andThen(() => experience)
      .map((lookupResults) => lookupResults.experienceID.links.schoolPartner)
      .map((link) => {
        const partnerBehavior = schoolPartnersStore.getSchoolPartner(link);
        return partnerBehavior.subscribe(setSchoolPartner);
      });

    return () => {
      behavior.do((sub) => sub.unsubscribe());
    };
  }, [experience]);

  return {
    schoolPartner,
  };
}

export default useSchoolPartnersStore;
