import { makeAutoObservable } from 'mobx';
import { ReactNode } from 'react';

type Visibility = 'show' | 'hide';

class ToasterStore {
  message: ReactNode;
  type: 'success' | 'error' = 'success';
  visibility: Visibility = 'hide';

  constructor() {
    makeAutoObservable(this);
  }

  showToaster(message: ReactNode, type: 'success' | 'error' = 'success') {
    this.message = message;
    this.type = type;
    this.visibility = 'show';

    setTimeout(() => {
      this.visibility = 'hide';
    }, 8000);
  }
}

export const toasterStore = new ToasterStore();
