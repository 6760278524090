import Appsignal from '@appsignal/javascript';
import { JSClient } from '@appsignal/types';
import { readVarM } from '@execonline-inc/environment';
import { Maybe } from 'maybeasy';

const appsignalKey = readVarM('VITE_APP_APPSIGNAL_PUSH_API_KEY');

export const appsignalP3: Maybe<JSClient> = appsignalKey.map(
  (key: string) =>
    new Appsignal({
      key: key,
      namespace: 'platform',
    }),
);

export const appsignalDiscoveryPortal: Maybe<JSClient> = appsignalKey.map(
  (key: string) =>
    new Appsignal({
      key: key,
      namespace: 'discovery_portal',
    }),
);
