import { emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import { T } from '../../../Translations';
import { ExperienceDictionaryEntry } from '../../ExperiencesDictionary/Types';
import usePrimaryCompetenciesStore from '../PrimaryCompetencies/PrimaryCompetenciesStore/UsePrimaryCompetenciesStore';

interface ProgramCompetenciesProps<T> {
  experience: ExperienceDictionaryEntry;
}

function ProgramCompetencies<T>({ experience }: ProgramCompetenciesProps<T>) {
  const { primaryCompetencies } = usePrimaryCompetenciesStore(experience);

  return fromEmpty(primaryCompetencies)
    .map((competencies) => (
      <Typography className="text-gray-900" variant="caption">
        <T kind={`{{count}} Competency`} count={competencies.length} />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ProgramCompetencies);
