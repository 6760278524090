import { explicitMaybe } from '@execonline-inc/decoders';
import { Decoder, field, succeed } from 'jsonous';
import { resourceDecoder } from '../../../Resource/Decoders';
import { supportedLanguageCodeDecoder } from '../../../SupportedLanguages/Decoders';
import { SubscriptionInfo, SubscriptionResource } from './Types';

export const subscriptionInfoDecoder: Decoder<SubscriptionInfo> = succeed({}).assign(
  'preferredLanguage',
  field('preferred_language', explicitMaybe(supportedLanguageCodeDecoder)),
);

export const subscriptionResourceDecoder: Decoder<SubscriptionResource> =
  resourceDecoder(subscriptionInfoDecoder);
