import { observer } from 'mobx-react';
import { ExperienceVideoPreview } from '../../../Common/ProgramExperienceVideo';
import { PromotableExperience } from '../Types';
import PromotableExperienceTileDescription from './PromotableExperienceTileDescription';
import PromotableExperienceTileFooter from './PromotableExperienceTileFooter';
import PromotableExperienceTileTitle from './PromotableExperienceTileTitle';
import ExperiencesStore from '../../ExperienceSelection/Experiences/ExperiencesStore';

interface Props {
  experience: PromotableExperience;
  experiencesStore: ExperiencesStore;
}

function PromotableLeadershipCoachingTile({ experience, experiencesStore }: Props) {
  const { promotableMarketingTitle, promotableMarketingCopy } = experience;

  return (
    <section className="box-border flex w-full grid-cols-2 gap-x-8">
      <div className="mt-16 flex w-6/12 flex-col">
        <PromotableExperienceTileTitle value={promotableMarketingTitle} />
        <PromotableExperienceTileDescription value={promotableMarketingCopy} />
        <PromotableExperienceTileFooter />
      </div>
      <div className="relative flex w-6/12 items-center justify-center">
        <span className="pointer-events-none absolute inset-0 right-[-1000px] bg-promotable-carousel [clip-path:ellipse(100%_250%_at_100%_54%)]" />
        <ExperienceVideoPreview
          className="h-[12.125rem] w-86 rounded-sm xl:h-[16.25rem] xl:w-115"
          experiencesStore={experiencesStore}
        />
      </div>
    </section>
  );
}

export default observer(PromotableLeadershipCoachingTile);
