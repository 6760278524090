import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react-lite';
import MessageBody from './MessageBody';
import { toasterStore } from './store';

const ExoToaster = observer(() => {
  switch (toasterStore.visibility) {
    case 'hide':
      return emptyFragment();
    case 'show':
      return (
        <div className="fixed left-1/2 top-4 z-50 -translate-x-1/2">
          <MessageBody />
        </div>
      );
  }
});

export default ExoToaster;
