import { MediaQueryBreakPoint, TypographyVariant } from '@execonline-inc/execonline-ui';
import { always, identity } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { whenAny } from '../../../../../Collections';

interface TimeCommitment {
  duration: Maybe<number>;
  hoursPerWeek: Maybe<number>;
  totalHours: Maybe<number>;
}

export const getTimeCommitment = <T extends TimeCommitment>(
  experience: T,
): Maybe<TimeCommitment> => {
  const { duration, hoursPerWeek, totalHours } = experience;

  return whenAny(identity, [duration, hoursPerWeek, totalHours]).map(
    always({ duration, hoursPerWeek, totalHours }),
  );
};

export const getTitleVariant = (breakPoint: MediaQueryBreakPoint): TypographyVariant => {
  switch (breakPoint) {
    case 'xl':
    case 'lg':
    case 'md':
    case 'sm':
      return 'h4';
    case 'xs':
      return 'h5';
  }
};
