import { stringLiteral } from '@execonline-inc/decoders';
import { Decoder, oneOf } from 'jsonous';
import {
  SupportedLanguageCode,
  SupportedLanguageEnglishName,
  SupportedLanguageLocalName,
} from './Types';

export const supportedLanguageEnglishNameDecoder: Decoder<SupportedLanguageEnglishName> = oneOf([
  stringLiteral<SupportedLanguageEnglishName>('English'),
  stringLiteral<SupportedLanguageEnglishName>('French'),
  stringLiteral<SupportedLanguageEnglishName>('Spanish'),
  stringLiteral<SupportedLanguageEnglishName>('Japanese'),
  stringLiteral<SupportedLanguageEnglishName>('German'),
  stringLiteral<SupportedLanguageEnglishName>('Italian'),
  stringLiteral<SupportedLanguageEnglishName>('Portuguese'),
  stringLiteral<SupportedLanguageEnglishName>('Chinese, Simplified'),
  stringLiteral<SupportedLanguageEnglishName>('Korean'),
]);

export const supportedLanguageLocalNameDecoder: Decoder<SupportedLanguageLocalName> = oneOf([
  stringLiteral<SupportedLanguageLocalName>('English'),
  stringLiteral<SupportedLanguageLocalName>('español'),
  stringLiteral<SupportedLanguageLocalName>('français'),
  stringLiteral<SupportedLanguageLocalName>('日本語'),
  stringLiteral<SupportedLanguageLocalName>('Deutsch'),
  stringLiteral<SupportedLanguageLocalName>('italiano'),
  stringLiteral<SupportedLanguageLocalName>('português'),
  stringLiteral<SupportedLanguageLocalName>('中文(简体)'),
  stringLiteral<SupportedLanguageLocalName>('한국어'),
]);

export const supportedLanguageCodeDecoder: Decoder<SupportedLanguageCode> = oneOf([
  stringLiteral<SupportedLanguageCode>('en'),
  stringLiteral<SupportedLanguageCode>('fr'),
  stringLiteral<SupportedLanguageCode>('es'),
  stringLiteral<SupportedLanguageCode>('ja'),
  stringLiteral<SupportedLanguageCode>('de'),
  stringLiteral<SupportedLanguageCode>('it'),
  stringLiteral<SupportedLanguageCode>('pt'),
  stringLiteral<SupportedLanguageCode>('zh'),
  stringLiteral<SupportedLanguageCode>('ko'),
]);
