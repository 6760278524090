import { useEffect } from 'react';
import autoLaunchablesStore from '.';
import { currentUserStore } from '../../CurrentUser/Store';
import { findLink } from '../../LinkyLinky';
import { AutoLaunchableSource } from '../../components/AutoLaunchable/AutoLaunchableContext';

function useAutoLaunchablesStore(source: AutoLaunchableSource) {
  useEffect(() => {
    if (autoLaunchablesStore.isLoaded(source)) {
      return;
    }
    findLink('auto-launchables', currentUserStore.links).do((link) =>
      autoLaunchablesStore.load(link, source),
    );
  }, [source, currentUserStore.links]);
}

export default useAutoLaunchablesStore;
