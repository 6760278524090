import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from '../../../../Resource/Types';
import { TPlainTextKey } from '../../../../Translations';
import SsoLoginStoreReactions from '../SsoLoginStore/Reactions';
import { AuthSignIn } from '../Types';
import UsePasswordStoreReactions from '../UsePasswordStore/Reactions';

interface Props {
  authForm: AuthSignIn;
  registerResourceLinks: ReadonlyArray<Link>;
  readyLockedAccess: (message: Maybe<TPlainTextKey>) => void;
}

const SignInReactions: React.FC<Props> = ({
  authForm,
  registerResourceLinks,
  readyLockedAccess,
}) => {
  switch (authForm.kind) {
    case 'password-sign-in':
      return (
        <UsePasswordStoreReactions
          store={authForm.store}
          links={registerResourceLinks}
          readyLockedAccess={readyLockedAccess}
          fireImmediately
        />
      );
    case 'sso-sign-in':
      return (
        <SsoLoginStoreReactions
          store={authForm.store}
          auth={authForm.auth}
          registerResourceLinks={registerResourceLinks}
          fireImmediately
        />
      );
  }
};

export default observer(SignInReactions);
