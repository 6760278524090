import { emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import usePortalExperienceContext from '../PortalExperience/UsePortalExperienceContext';
import ExperienceBodyContent from './ExperienceBodyContent';

function ExperienceBody() {
  return (
    <>
      <Typography className="mb-3" variant="subtitleMedium" as="h5" fontWeight="medium">
        <_Title />
      </Typography>
      <div className="flex flex-col gap-5">
        <ExperienceBodyContent />
      </div>
    </>
  );
}

const _Title = observer(() => {
  const experience = usePortalExperienceContext();
  return experience
    .andThen(({ experience }) => experience)
    .map((experience) => <AlreadyTranslated content={experience.payload.title} />)
    .getOrElse(emptyFragment);
});

export default observer(ExperienceBody);
