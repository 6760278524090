import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { currentUserStore } from '../../../../CurrentUser/Store';
import { findLink } from '../../../../LinkyLinky';
import { T } from '../../../../Translations';
import BrandLogoIcon from './BrandLogoIcon';

function ApplicationBrand() {
  return (
    <div className="content-center md:mr-5 xl:mr-12" data-test-platform-logo={true}>
      {findLink('dashboard', currentUserStore.links)
        .map(() => (
          <NavLink className="flex h-[inherit] items-center" to="/">
            <BrandLogoIcon />
            <span className="sr-only">
              <T kind="Go to the dashboard" />
            </span>
          </NavLink>
        ))
        .getOrElse(() => (
          <BrandLogoIcon />
        ))}
    </div>
  );
}

export default observer(ApplicationBrand);
