import { observer } from 'mobx-react';
import { L, NotTranslated, T } from '../../Translations';
import DocumentIcon from '../Cutesies/DocumentIcon';
import DownArrowIcon from '../Cutesies/DownArrowIcon';
import DownloadViewModal from '../DownloadViewModal';
import { useOpenable } from '../Openable';
import * as style from '../Resources/style.module.css';
import { AiWrittenFeedbackResource } from './Types';
import * as aiWrittenFeedbackStyle from '../ExpertFeedback/style.module.css'; // fix styling to match new pattern

interface Props {
  feedback: AiWrittenFeedbackResource;
}

function AiWrittenFeedbackContent({ feedback }: Props) {
  const { openableState, open, close } = useOpenable();

  return (
    <div className={style.resource} data-test-document-resource={true}>
      <a onClick={open} data-test-resource-button={true}>
        <div className={style.icon}>
          <DocumentIcon />
        </div>
        <div className={style.details}>
          <h4 className={aiWrittenFeedbackStyle.name} data-test-resource-title={true}>
            <NotTranslated text={feedback.payload.title} />
          </h4>
          <div className={style.date} data-test-resource-added-on={feedback.payload.createdAt}>
            <T
              kind="Document added on <date/>"
              date={
                <span className={style.timestamp}>
                  <L localizeable={feedback.payload.createdAt} format="long-month-day-year" />
                </span>
              }
            />
          </div>
        </div>
        <div className={aiWrittenFeedbackStyle.download}>
          <div className={aiWrittenFeedbackStyle.downloadIcon}>
            <DownArrowIcon />
          </div>
        </div>
      </a>
      <DownloadViewModal resource={feedback} modalState={openableState} close={close} />
    </div>
  );
}

export default observer(AiWrittenFeedbackContent);
