import { observer } from 'mobx-react';
import { ExperienceDictionaryEntry } from '../../ExperiencesDictionary/Types';
import ProgramHours from '../Experience/ExperienceBody/ExperienceOverview/Common/ProgramHours';
import ProgramCompetencies from './ProgramCompetencies';
import ProgramUpcomingDates from './ProgramUpcomingDates';

interface ProgramOverviewItemsProps {
  experience: ExperienceDictionaryEntry;
}

function ProgramOverviewItems({ experience }: ProgramOverviewItemsProps) {
  return (
    <div className="flex flex-wrap gap-x-3 gap-y-1 empty:hidden sm:gap-x-6">
      <ProgramCompetencies experience={experience} />
      <ProgramHours experience={experience} />
      <ProgramUpcomingDates />
    </div>
  );
}

export default observer(ProgramOverviewItems);
