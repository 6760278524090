import { AlreadyTranslatedText } from '@execonline-inc/translations';
import { Decoder, array, field, number, succeed } from 'jsonous';
import { BehaviorSubject } from 'rxjs';
import { Task } from 'taskarian';
import { callApi } from '../../../../Appy';
import { alreadyTranslatedTextDecoder } from '../../../../Decoders';
import { resourceDecoder } from '../../../../Resource/Decoders';
import { Link } from '../../../../Resource/Types';

export interface Competency {
  id: number;
  name: AlreadyTranslatedText;
  experienceIds: number[];
}

const competencyDecoder: Decoder<Competency> = succeed({})
  .assign('id', field('id', number))
  .assign('name', field('name', alreadyTranslatedTextDecoder))
  .assign('experienceIds', field('experience_ids', array(number)));

interface PrimaryCompetenciesStoreEvent {
  observable: BehaviorSubject<Competency[]>;
}

interface PrimaryCompetenciesStoreState {
  behaviors: Record<number, PrimaryCompetenciesStoreEvent>;
}

class PrimaryCompetenciesStore {
  private data: PrimaryCompetenciesStoreState = {
    behaviors: {},
  };

  private state: 'initialized' | 'loading' | 'loaded' = 'initialized';

  loadAllPrimaryCompetenciesResources = (competenciesIndexLink: Link): Promise<void> => {
    if (this.state === 'loaded' || this.state === 'loading') {
      return Promise.resolve();
    }

    this.state = 'loading';

    return callApi(resourceDecoder(array(competencyDecoder)), {}, competenciesIndexLink)
      .map((resources) => {
        resources.payload.forEach((competency) => {
          const { experienceIds } = competency;
          experienceIds.forEach((experienceId) => {
            const behavior = this.getPrimaryCompetencies(experienceId);
            const currentCompetencies = behavior.getValue();
            behavior.next([...currentCompetencies, competency]);
          });
        });
        return competenciesIndexLink;
      })
      .orElse((err) => {
        console.warn('Competencies resource failed to fetch', err);
        return Task.succeed(competenciesIndexLink);
      })
      .map(() => {
        this.state = 'loaded';
      })
      .resolve();
  };

  getPrimaryCompetencies = (experienceId: number): BehaviorSubject<Competency[]> => {
    const behavior = this.data.behaviors[experienceId];
    if (!behavior) {
      this.data.behaviors[experienceId] = {
        observable: new BehaviorSubject<Competency[]>([]),
      };
    }
    return this.data.behaviors[experienceId].observable;
  };
}

const primaryCompetenciesStore = new PrimaryCompetenciesStore();
export default primaryCompetenciesStore;
