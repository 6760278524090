import { stringLiteral } from '@execonline-inc/decoders';
import { AlreadyTranslatedText } from '@execonline-inc/translations';
import { array, Decoder, field, number, oneOf, string, succeed } from 'jsonous';
import { just, Maybe, nothing } from 'maybeasy';
import { alreadyTranslatedTextDecoder } from '../../Decoders';
import { OfferingType } from '../../Native/AEP/Common/Experience/Types';
import { offeringTypeDecoder } from '../../Native/AEP/DiscoveryPortal/ExperienceSelection/Experiences/Decoders';
import { linkDecoder, resourceDecoder } from '../../Resource/Decoders';
import { Link, Resource } from '../../Resource/Types';

export interface ExperienceDictionaryEntry {
  id: number;
  experienceId: string;
  offeringType: OfferingType;
  primaryColor: string;
  secondaryColor: string;
  shortName: Maybe<AlreadyTranslatedText>;
  descriptor: Maybe<AlreadyTranslatedText>;
  title: AlreadyTranslatedText;
  description: AlreadyTranslatedText;
  facultyCount: Maybe<number>;
  duration: Maybe<number>;
  totalHours: Maybe<number>;
  hoursPerWeek: Maybe<number>;
  publicDetailedDescriptionHtml: Maybe<AlreadyTranslatedText>;
  whoShouldAttendHtml: Maybe<AlreadyTranslatedText>;
  keyTakeawaysHtml: Maybe<AlreadyTranslatedText>;
  howItWorksHtml: Maybe<AlreadyTranslatedText>;
  programStructureAndFeaturesHtml: Maybe<AlreadyTranslatedText>;
}

function explicitMaybeDecoder<T>(decoder: Decoder<T>): Decoder<Maybe<T>> {
  const justDecoder: Decoder<Maybe<T>> = field('kind', stringLiteral('just'))
    .andThen(() => field('value', decoder))
    .map(just);
  const nothingDecoder: Decoder<Maybe<T>> = field('kind', stringLiteral('nothing')).map(() =>
    nothing(),
  );
  return oneOf([justDecoder, nothingDecoder]);
}

export interface ExperienceDictionaryEntryResponse {
  payload: ExperienceDictionaryEntry;
  links: {
    self: Readonly<Link>;
    schoolPartner: Readonly<Link>;
  };
}

const experienceDictionaryEntryPayloadDecoder: Decoder<ExperienceDictionaryEntry> = succeed({})
  .assign('id', field('id', number))
  .assign('experienceId', field('experience_id', string))
  .assign('offeringType', field('offering_type', offeringTypeDecoder))
  .assign('primaryColor', field('primary_color', string))
  .assign('secondaryColor', field('secondary_color', string))
  .assign('shortName', field('short_name', explicitMaybeDecoder(alreadyTranslatedTextDecoder)))
  .assign('descriptor', field('descriptor', explicitMaybeDecoder(alreadyTranslatedTextDecoder)))
  .assign('title', field('title', alreadyTranslatedTextDecoder))
  .assign('description', field('description', alreadyTranslatedTextDecoder))
  .assign('facultyCount', field('faculty_count', explicitMaybeDecoder(number)))
  .assign('duration', field('duration', explicitMaybeDecoder(number)))
  .assign('totalHours', field('total_hours', explicitMaybeDecoder(number)))
  .assign('hoursPerWeek', field('hours_per_week', explicitMaybeDecoder(number)))
  .assign(
    'publicDetailedDescriptionHtml',
    field('public_detailed_description_html', explicitMaybeDecoder(alreadyTranslatedTextDecoder)),
  )
  .assign(
    'whoShouldAttendHtml',
    field('who_should_attend_html', explicitMaybeDecoder(alreadyTranslatedTextDecoder)),
  )
  .assign(
    'keyTakeawaysHtml',
    field('key_takeaways_html', explicitMaybeDecoder(alreadyTranslatedTextDecoder)),
  )
  .assign(
    'howItWorksHtml',
    field('how_it_works_html', explicitMaybeDecoder(alreadyTranslatedTextDecoder)),
  )
  .assign(
    'programStructureAndFeaturesHtml',
    field(
      'program_structure_and_features_html',
      explicitMaybeDecoder(alreadyTranslatedTextDecoder),
    ),
  );

const experienceDictionaryEntryLinksDecoder: Decoder<ExperienceDictionaryEntryResponse['links']> =
  succeed({})
    .assign('self', field('self', linkDecoder))
    .assign('schoolPartner', field('school_partner', linkDecoder));

const experienceDictionaryEntryDecoder: Decoder<ExperienceDictionaryEntryResponse> = succeed({})
  .assign('payload', field('payload', experienceDictionaryEntryPayloadDecoder))
  .assign('links', field('links', experienceDictionaryEntryLinksDecoder));

export const experiencesDictionaryDecoder: Decoder<Resource<ExperienceDictionaryEntryResponse[]>> =
  resourceDecoder(field('experiences', array(experienceDictionaryEntryDecoder)));

export function isLeadershipCoachingExperience(
  experience: ExperienceDictionaryEntry,
): experience is ExperienceDictionaryEntry & { offeringType: 'coaching' } {
  return experience.offeringType === 'coaching';
}

export function isGroupCoachingExperience(
  experience: ExperienceDictionaryEntry,
): experience is ExperienceDictionaryEntry & { offeringType: 'group-coaching' } {
  return experience.offeringType === 'group-coaching';
}
