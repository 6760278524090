import { useEffect } from 'react';
import { profileStore } from '.';
import { currentUserStore } from '../CurrentUser/Store';
import { findLink } from '../LinkyLinky';

function useProfileStore() {
  useEffect(() => {
    if (profileStore.isLoaded) {
      return;
    }
    findLink('profile', currentUserStore.links).do(profileStore.load);
  }, [currentUserStore.links]);
}

export default useProfileStore;
