import { Card, CardBody, CardHeader, cn } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import EnrollmentStore from '../../../DiscoveryPortal/Enrollment/Store';
import ExperiencesStore from '../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { ExperienceContextProvider } from '../../Experience/ExperienceContext';
import ExperienceFooter from '../../Experience/ExperienceFooter';
import { ExperienceResource } from '../../Experience/Types';
import { ProgramExperiencePopover } from '../../PopoverProgramExperience';
import ProgramExperienceActions from '../../ProgramExperienceActions';
import { ExperienceEnrollmentContextProvider } from '../../ProgramExperienceEnrollment';
import { handleEnterKeyDown, handleMouseDown } from '../../Types';
import { ProgramExperienceCardVideo } from '../Common';
import { getExperienceHeaderBackgroundByType } from './Common';
import ExperienceBody from './ExperienceBody';

interface Props {
  invitationUuid: string;
  experienceResource: ExperienceResource;
  experiencesStore: ExperiencesStore;
  enrollmentStore: EnrollmentStore;
}

function ListViewProgramExperience({
  invitationUuid,
  experienceResource,
  experiencesStore,
  enrollmentStore,
}: Props) {
  return (
    <ExperienceEnrollmentContextProvider
      value={{ invitationUuid, experienceResource, enrollmentStore, experiencesStore }}
    >
      {({ onOpen }) => (
        <ExperienceContextProvider value={{ invitationUuid, experienceResource }}>
          <ProgramExperiencePopover
            experiencesStore={experiencesStore}
            placement="top"
            onEnroll={onOpen}
          >
            {(ref, getTriggerProps) => (
              <ProgramExperienceActions>
                {(onClick) => (
                  <div ref={ref} tabIndex={-1} className="flex w-full">
                    <Card
                      tabIndex={0}
                      className="min-h-46 w-full bg-white sm:flex-row"
                      radius="none"
                      {...getTriggerProps()}
                      onClick={handleMouseDown(onClick)}
                      onKeyDown={handleEnterKeyDown(onClick)}
                    >
                      <CardHeader className="items-start p-0 shadow-none sm:py-6 sm:pl-6 sm:pr-2">
                        <ProgramExperienceCardVideo
                          className="flex h-[11.625rem] w-full sm:h-35 sm:w-[15.5rem] sm:rounded-md"
                          height={20}
                          experiencesStore={experiencesStore}
                        />
                      </CardHeader>
                      <CardBody
                        className={cn(
                          'gap-10 px-4 pb-3 pt-4 sm:gap-5 sm:p-6 sm:pl-2',
                          getExperienceHeaderBackgroundByType(experienceResource.payload),
                        )}
                      >
                        <ExperienceBody />
                        <ExperienceFooter />
                      </CardBody>
                    </Card>
                  </div>
                )}
              </ProgramExperienceActions>
            )}
          </ProgramExperiencePopover>
        </ExperienceContextProvider>
      )}
    </ExperienceEnrollmentContextProvider>
  );
}

export default observer(ListViewProgramExperience);
