import { Card, CardProps, emptyFragment } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import { forwardRef } from 'react';
import useProductDetailsStore from '../ProductDetails/ProductDetailsStore/UseProductDetailsStore';

const ProgramExperienceCard = forwardRef<HTMLDivElement, CardProps>(
  function ProgramExperienceCard(props, ref) {
    return (
      <div ref={ref} tabIndex={-1} className="relative flex w-full flex-col overflow-visible">
        <>
          <Card {...props} />
          <_LearningJourneyExperience />
        </>
      </div>
    );
  },
);

const _LearningJourneyExperience = observer(() => {
  const { productDetails } = useProductDetailsStore();
  return productDetails
    .map(({ payload }) => payload)
    .andThen(when((payload) => payload.kind === 'program-sequence'))
    .map(() => (
      <div className="flex w-full flex-col items-center">
        <div className="h-2 w-[96%] rounded-b-[3px] bg-[#F4F4F4] shadow-program-card" />
        <div className="h-2 w-[93%] rounded-b-[3px] bg-[#F4F4F4] shadow-program-card" />
      </div>
    ))
    .getOrElse(emptyFragment);
});

export default observer(ProgramExperienceCard);
