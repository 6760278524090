import { CardCollectionIcon, Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../Translations';
import SupportLink from '../../../Native/AEP/SupportLink/SupportLink';

function NoActiveEnrollment() {
  return (
    <div className="mt-1 flex w-full items-center justify-center border border-gray-500 p-[16px]">
      <div className="flex w-full flex-col items-center justify-center">
        <CardCollectionIcon color="black" className="flex w-full justify-center" size="2xl" />
        <Typography
          variant="subtitleSmall"
          fontWeight="semibold"
          color="black"
          className="align-center"
        >
          <T kind="You don’t have any active enrollments" />
        </Typography>
        <Typography
          className="mt-2 w-2/5 text-center"
          variant="bodySmall"
          fontWeight="semibold"
          color="black"
        >
          <T
            kind="It looks like you haven’t enrolled in any programs yet...."
            link={(content) => (
              <SupportLink link="https://www.execonline.com/">
                <Typography
                  variant="bodySmall"
                  fontWeight="bold"
                  color="black"
                  className="underline"
                >
                  {content}
                </Typography>
              </SupportLink>
            )}
          />
        </Typography>
      </div>
    </div>
  );
}

export default observer(NoActiveEnrollment);
