import { explicitMaybe, stringLiteral } from '@execonline-inc/decoders';
import { Decoder, array, field, nullable, number, oneOf, string, succeed } from 'jsonous';
import { assertionBackendResourceDecoder } from '../Login/Decoders';
import { languagesResourceDecoder } from '../ProfileFormStore/Decoders';
import { resourceDecoder } from '../Resource/Decoders';
import { supportedLanguageCodeDecoder } from '../SupportedLanguages/Decoders';
import { CurrentUser, CurrentUserResource, FileUploadType } from './Types';

const fileUploadTypeDecoder: Decoder<FileUploadType> = oneOf([
  stringLiteral<FileUploadType>('Advanced'),
  stringLiteral<FileUploadType>('Simple'),
]);

const currentUserDecoder: Decoder<CurrentUser> = succeed({})
  .assign('id', field('id', number))
  .assign('contactUid', field('contact_uid', nullable(string)))
  .assign(
    'preferredLanguage',
    field('preferred_language', explicitMaybe(supportedLanguageCodeDecoder)),
  )
  .assign('allowedLanguages', field('allowed_languages', languagesResourceDecoder))
  .assign('fileUploadType', field('file_upload_type', fileUploadTypeDecoder))
  .assign('assertionBackend', field('assertion_backend', assertionBackendResourceDecoder))
  .assign('inactivityTimeoutMinutes', field('inactivity_timeout_minutes', number))
  .assign('epPrivilegeIds', field('ep_privilege_ids', array(number)));

export const currentUserResourceDecoder: Decoder<CurrentUserResource> =
  resourceDecoder(currentUserDecoder);
