import { useConst } from '@execonline-inc/react-hooks.private';
import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import DiscoveryPortalImpl from '../../../Native/AEP/DiscoveryPortal/DiscoveryPortalImpl';
import RegisterResourceStore from '../../../Native/AEP/DiscoveryPortal/RegisterResourceStore';
import RegisterResourceReactions from '../../../Native/AEP/DiscoveryPortal/RegisterResourceStore/RegisterResourceReactions';
import WhenRegisterResource from '../../../Native/AEP/DiscoveryPortal/RegisterResourceStore/WhenRegisterResource';

interface Props {
  guid: string;
}

function AuthView({ guid }: Props) {
  const registerResourceStore = useConst(() => new RegisterResourceStore());

  useEffect(() => {
    registerResourceStore.loading(guid);
  }, [guid, registerResourceStore]);

  return (
    <>
      <RegisterResourceReactions store={registerResourceStore} params={nothing()} fireImmediately />
      <WhenRegisterResource store={registerResourceStore}>
        {(registerResource) => (
          <DiscoveryPortalImpl
            registerResource={registerResource}
            registerResourceStore={registerResourceStore}
          />
        )}
      </WhenRegisterResource>
    </>
  );
}
export default observer(AuthView);
