import { Maybe, nothing } from 'maybeasy';
import { useEffect, useState } from 'react';
import { Link } from '../../Resource/Types';
import { useQueryWithDecoder } from '../../UseQueryWithDecoder';
import { ExperienceIDResponse } from '../Registration/ExperiencesStore';
import experiencesDictionaryStore from './Store';
import { ExperienceDictionaryEntryResponse, experiencesDictionaryDecoder } from './Types';

export interface ExperienceEntryLookupResult {
  experienceID: ExperienceIDResponse;
  experience: Maybe<ExperienceDictionaryEntryResponse>;
}

export function useExperiencesDictionary(links: ReadonlyArray<Link>) {
  const { data, error, isLoading } = useQueryWithDecoder(
    'experiences-dictionary',
    links,
    experiencesDictionaryDecoder,
  );

  useEffect(() => {
    if (data) {
      experiencesDictionaryStore.addExperiences(data.payload);
    }
  }, [data]);

  return {
    experiencesDictionary: data,
    error,
    isLoading,
  };
}

export function useExperienceEntry(experienceID: ExperienceIDResponse) {
  const [experienceEntry, setExperienceEntry] = useState<ExperienceEntryLookupResult>({
    experienceID,
    experience: nothing(),
  });

  useEffect(() => {
    const experience = experiencesDictionaryStore.getExperience(experienceID.payload.id);
    const subscription = experience.subscribe((value) =>
      setExperienceEntry({ experienceID, experience: value }),
    );
    return () => {
      subscription.unsubscribe();
    };
  }, [experienceID]);

  return experienceEntry;
}
