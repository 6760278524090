import { Chip, emptyFragment } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import {
  ExperienceDetailsSection,
  ExperienceDetailsSectionHeader,
} from '../../../Native/AEP/ExperienceDetailView/Common';
import usePrimaryCompetenciesStore from '../PrimaryCompetencies/PrimaryCompetenciesStore/UsePrimaryCompetenciesStore';

interface Props<T extends { id: number }> {
  experience: T;
}

function ExperienceYouWillGain<T extends { id: number }>({ experience }: Props<T>) {
  const { primaryCompetencies } = usePrimaryCompetenciesStore(experience);

  return fromEmpty(primaryCompetencies)
    .map((competencies) => (
      <ExperienceDetailsSection>
        <ExperienceDetailsSectionHeader title="Competencies you will gain" />
        <div className="flex flex-wrap gap-2 lg:gap-4">
          {competencies.map((competency) => (
            <Chip
              key={competency.id}
              className="px-3 py-1.5"
              size="sm"
              variant="outlined"
              radius="md"
              color="gray"
            >
              <AlreadyTranslated content={competency.name} />
            </Chip>
          ))}
        </div>
      </ExperienceDetailsSection>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ExperienceYouWillGain);
