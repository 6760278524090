import { explicitMaybe } from '@execonline-inc/decoders';
import { Decoder, array, field, number, string, succeed } from 'jsonous';
import { resourceDecoder } from '../../../../Resource/Decoders';
import { Resource } from '../../../../Resource/Types';
import {
  highProgressiveStreamResourceDecoder,
  lowProgressiveStreamResourceDecoder,
  mediumProgressiveStreamResourceDecoder,
  subtitleResourceDecoder,
} from '../../../../VideoStore/ReqHls/Decoders';
import { preferredVideoPlaybackDecoder } from '../../../../VideoStore/Shared/Decoders';
import { OverviewVideo } from '../../../../components/ReqHlsVideo/Types';

const overviewVideoDecoder: Decoder<OverviewVideo> = succeed({})
  .assign('uuid', field('uuid', string))
  .assign('id', field('id', number))
  .assign(
    'preferredVideoPlayback',
    field('preferred_video_playback', preferredVideoPlaybackDecoder),
  )
  .assign(
    'highProgressiveStream',
    field('high_progressive_stream', highProgressiveStreamResourceDecoder),
  )
  .assign(
    'mediumProgressiveStream',
    field('medium_progressive_stream', mediumProgressiveStreamResourceDecoder),
  )
  .assign(
    'lowProgressiveStream',
    field('low_progressive_stream', lowProgressiveStreamResourceDecoder),
  )
  .assign('vimeoId', field('vimeo_id', explicitMaybe(number)))
  .assign('mediaId', field('media_id', explicitMaybe(string)))
  .assign('title', field('title', explicitMaybe(string)))
  .assign('subtitles', field('subtitles', array(subtitleResourceDecoder)));

export const overviewVideoResourceDecoder: Decoder<Resource<OverviewVideo>> =
  resourceDecoder(overviewVideoDecoder);
