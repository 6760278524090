import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { findLink } from '../../../LinkyLinky';
import registrationInvitationStore, {
  isLoaded,
} from '../../../RegistrationInvitation/RegistrationInvitationStore';
import Filters from './Filters';
import SearchBox from './SeachBox';

const _Search = observer(() => {
  if (!isLoaded(registrationInvitationStore)) return <></>;
  const resource = registrationInvitationStore.state.registrationInvitation;
  return findLink('search-discovery-portal', resource.links)
    .map((link) => <SearchBox onSubmit={(value) => console.log(value)} />)
    .getOrElse(emptyFragment);
});

function FilterSection() {
  return (
    <div className="sm:px-0">
      <div className="mx-auto box-border flex gap-x-2 px-6 pb-8 pt-0 xl:container md:flex-wrap md:gap-x-0 md:px-16">
        <div className="flex w-full flex-col gap-2 lg:flex-row">
          <_Search />
          <Filters />
        </div>
      </div>
    </div>
  );
}

export default observer(FilterSection);
