import { stringLiteral } from '@execonline-inc/decoders';
import { Decoder, oneOf } from 'jsonous';
import { ParticipantType } from '../types';

export const participantTypeDecoder: Decoder<ParticipantType> = oneOf<ParticipantType>([
  stringLiteral<ParticipantType>('Student'),
  stringLiteral<ParticipantType>('Professor'),
  stringLiteral<ParticipantType>('Coach'),
  stringLiteral<ParticipantType>('Auditor'),
  stringLiteral<ParticipantType>('Observer'),
]);
