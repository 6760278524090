import { emptyFragment, Typography, useMediaQuery } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import CourseAvailabilityPerWeek from '../../../Native/AEP/ExperienceDetailView/Common/ExperienceOverview/CourseAvailabilityPerWeek';
import TotalHour from '../../../Native/AEP/ExperienceDetailView/Common/ExperienceOverview/TotalHour';
import {
  getTimeCommitment,
  getTitleVariant,
} from '../../../Native/AEP/ExperienceDetailView/Common/ExperienceOverview/Types';
import { T } from '../../../Translations';
import { ExperienceEntryLookupResult } from '../../ExperiencesDictionary';
import { ExperienceDictionaryEntryResponse } from '../../ExperiencesDictionary/Types';
import Certificate from './Cerificate';
import DownloadOverview from './DownloadOverview';
import ExperienceLanguageAvailability from './ExperienceLanguageAvailability';

interface Props {
  experience: ExperienceEntryLookupResult;
}

function ExperienceOverview({ experience }: Props) {
  const breakPoint = useMediaQuery();

  return (
    <div className="flex flex-col gap-y-4 md:gap-y-6">
      <Typography className="text-gray-900" variant={getTitleVariant(breakPoint)} fontWeight="bold">
        <T kind="Overview" />
      </Typography>
      <div className="flex flex-wrap gap-x-14 gap-y-5">
        {experience.experience
          .map((entry) => <TimeCommitment experience={entry} />)
          .getOrElse(emptyFragment)}
        <div className="flex flex-col gap-y-5">
          <DownloadOverview experienceID={experience.experienceID} />
          <ExperienceLanguageAvailability className="text-gray-600" />
        </div>
      </div>
    </div>
  );
}

const TimeCommitment = observer(
  ({ experience }: { experience: ExperienceDictionaryEntryResponse }) => {
    const timeCommitment = getTimeCommitment(experience.payload);

    return timeCommitment
      .map(({ totalHours, duration, hoursPerWeek }) => {
        return (
          <>
            <CourseAvailabilityPerWeek duration={duration} hoursPerWeek={hoursPerWeek} />
            <TotalHour totalHours={totalHours} />
          </>
        );
      })
      .getOrElse(() => <Certificate />);
  },
);

export default observer(ExperienceOverview);
