import { log } from '@execonline-inc/logging';
import { toResult } from '@execonline-inc/maybe-adapter';
import { noop, pipe } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { Task } from 'taskarian';
import { errorMessage } from '../../../ExceptionHandling';
import { FSLoadError, fsLoadError, handleProgramUpdateError, missingValue } from './Errors';
import { FsFn } from './Types';

const FULLSTORY_CODE = `(function (m, n, e, t, l, o, g, y) {
  if (e in m) {
    if (m.console && m.console.log) {
      m.console.log(
        'FullStory namespace conflict. Please set window["_fs_namespace"].'
      );
    }
    return;
  }
  g = m[e] = function (a, b, s) {
    g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
  };
  g.q = [];
  o = n.createElement(t);
  o.async = 1;
  o.crossOrigin = "anonymous";
  o.src = "https://" + _fs_script;
  y = n.getElementsByTagName(t)[0];
  y.parentNode.insertBefore(o, y);
  g.identify = function (i, v, s) {
    g(l, { uid: i }, s);
    if (v) g(l, v, s);
  };
  g.setUserVars = function (v, s) {
    g(l, v, s);
  };
  g.event = function (i, v, s) {
    g("event", { n: i, p: v }, s);
  };
  g.anonymize = function () {
    g.identify(!!0);
  };
  g.shutdown = function () {
    g("rec", !1);
  };
  g.restart = function () {
    g("rec", !0);
  };
  g.log = function (a, b) {
    g("log", [a, b]);
  };
  g.consent = function (a) {
    g("consent", !arguments.length || a);
  };
  g.identifyAccount = function (i, v) {
    o = "account";
    v = v || {};
    v.acctId = i;
    g(o, v);
  };
  g.clearUserCookie = function () {};
  g._w = {};
  y = "XMLHttpRequest";
  g._w[y] = m[y];
  y = "fetch";
  g._w[y] = m[y];
  if (m[y])
    m[y] = function () {
      return g._w[y].apply(this, arguments);
    };
  g._v = "1.2.0";
})(window, document, window["_fs_namespace"], "script", "user")`;

declare global {
  export interface Window {
    FS?: FsFn;
    _fs_debug?: boolean;
    _fs_host?: string;
    _fs_org?: string;
    _fs_namespace?: string;
    _fs_script?: string;
  }
}

export const loadFS = (
  token: string,
  fsHost: string,
  fsScript: string,
): Task<FSLoadError, FsFn> => {
  return new Task<FSLoadError, FsFn>((reject, resolve) => {
    const rejectException = pipe(errorMessage, fsLoadError, reject);
    try {
      if (window.FS) {
        resolve(window.FS);
      } else {
        window['_fs_debug'] = false;
        window['_fs_host'] = fsHost;
        window['_fs_org'] = token;
        window['_fs_namespace'] = 'FS';
        window['_fs_script'] = fsScript;

        const script = document.createElement('script');
        script.innerHTML = FULLSTORY_CODE;
        script.type = 'text/javascript';
        if (document.head) {
          document.head.appendChild(script);
          if (window.FS) {
            log('Fullstory is up and running');
            resolve(window.FS);
          } else {
            reject(fsLoadError('window.FS not loaded by script'));
          }
        }
      }
    } catch (e) {
      rejectException(e);
    }

    return noop;
  });
};

export const updateFsProgramFields = (title: string, sfProgramUid: Maybe<string>): void => {
  toResult(
    missingValue('sfProgramUid'),
    sfProgramUid.map((sfProgramUid) => ({ sfProgramUid })),
  )
    .map(({ sfProgramUid }) => {
      if (window.FS) {
        window.FS.setUserVars({ course: `${title}: ${sfProgramUid}` });
      }
    })
    .mapError(handleProgramUpdateError(title));
};
