import { observer } from 'mobx-react';
import useCaseStore from '../../../RegistrationInvitation/UseCaseStore';
import useUseCaseStore from '../../../RegistrationInvitation/UseCaseStore/UseUseCaseStore';
import { Link } from '../../../Resource/Types';
import Loader from '../../../components/Loader';
import { useExperiencesDictionary } from '../../ExperiencesDictionary';
import RegistrationUserWorkflow from '../RegistrationUserWorkflow';

function UseCaseView() {
  useUseCaseStore();

  switch (useCaseStore.state.kind) {
    case 'use-case-store-state-initialized':
    case 'use-case-store-state-loading':
      return <Loader delay={1000} />;
    case 'use-case-store-state-loaded':
      return <_RegistrationUserWorkflowView links={useCaseStore.state.useCase.links} />;
    case 'use-case-store-state-error':
      return <div>Error: ${JSON.stringify(useCaseStore.state.error)}</div>;
  }
}

const _RegistrationUserWorkflowView = observer(({ links }: { links: ReadonlyArray<Link> }) => {
  useExperiencesDictionary(links);
  return <RegistrationUserWorkflow />;
});

export default observer(UseCaseView);
