import clsx from 'clsx';
import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { useContext, useEffect, useRef } from 'react';
import WhenUserDataLoaded from '../../CurrentUser/Context/WhenUserDataLoaded';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { CurrentUserResource } from '../../CurrentUser/Types';
import EventsRefresh from '../../EventsRefresh';
import EventsStore from '../../EventsStore';
import EventsReactions from '../../EventsStore/EventsReactions';
import NotificationPresence from '../../NotificationPresence';
import Socket from '../../Socket';
import TeamsReactions from '../../TeamsStore/TeamsReactions';
import { TeamsStoreContext } from '../../TeamsStore/TeamsStoreContext';
import { toolingsStore } from '../../ToolingsStore';
import WithPusherSettings from '../../ToolingsStore/WithPusherSettings';
import PlatformWrapperContext from '../PlatformWrapperContext';
import TeamsPresences from '../TeamsPresences';
import PlatformRouter from './PlatformRouter';
import * as style from './style.module.css';

interface Props {
  currentUserResource: CurrentUserResource;
}

function Platform({ currentUserResource }: Props) {
  const teamsStore = useContext(TeamsStoreContext);
  const eventsStore = useRef(new EventsStore(currentUserResource));
  const platformWrapper: React.LegacyRef<HTMLDivElement> = useRef(null);

  useEffect(() => {
    teamsStore.loading();
  });

  return (
    <PlatformWrapperContext.Provider value={just(platformWrapper)}>
      <div className={clsx('flex min-h-screen flex-col', style.pageLayoutWrapper)}>
        <div
          className={clsx(style.wrapper, 'h-auto min-h-screen')}
          ref={platformWrapper}
          style={{ width: 'fit-content', minHeight: '100vh', height: 'auto' }}
        >
          <WithCurrentUser
            children={(currentUserResource) => (
              <WhenUserDataLoaded
                currentUserResource={currentUserResource}
                contingencies={[toolingsStore]}
              >
                <WithPusherSettings
                  children={(pusherSettingsResource) => (
                    <Socket pusherSettingsResource={pusherSettingsResource}>
                      <TeamsPresences teamsResource={nothing()} />
                      <TeamsReactions store={teamsStore} resourceWithTeams={currentUserResource} />
                      <EventsReactions store={eventsStore.current} fireImmediately={true} />
                      <EventsRefresh
                        eventsStore={eventsStore.current}
                        pusherSettingsResource={pusherSettingsResource}
                      />
                      <NotificationPresence
                        channelName={pusherSettingsResource.payload.channels.notificationChannel}
                      />
                      <PlatformRouter eventsStore={eventsStore.current} />
                    </Socket>
                  )}
                />
              </WhenUserDataLoaded>
            )}
          />
        </div>
      </div>
    </PlatformWrapperContext.Provider>
  );
}

export default observer(Platform);
