import { useEffect } from 'react';
import { discoveryStore } from '.';
import { currentUserStore } from '../CurrentUser/Store';
import { findLink } from '../LinkyLinky';

function useDiscoveryStore(): void {
  useEffect(() => {
    if (discoveryStore.isLoaded) {
      return;
    }
    const link = findLink('discovery-portal', currentUserStore.links);
    link.do(discoveryStore.load);
  }, [discoveryStore.isLoaded, currentUserStore.links]);
  return;
}

export default useDiscoveryStore;
