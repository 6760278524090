import { Decoder, field, maybe, number, string, succeed } from 'jsonous';
import { registrationTypeDecoder } from '../../ProgramsStore/Decoders';
import { Breakout } from '../Types';

export const breakoutDecoder: Decoder<Breakout> = succeed({})
  .assign('participantType', field('participant_type', registrationTypeDecoder))
  .assign('endAt', field('end_at', maybe(number)))
  .assign('eventChannelName', field('event_channel_name', string))
  .assign('eventId', field('event_id', number))
  .assign('teamId', field('team_id', number));
