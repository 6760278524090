import { first } from '@execonline-inc/collections';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { targetedInvitationRoute } from '../../../ClientRoutes';
import { currentUserStore } from '../../../CurrentUser/Store';
import { discoveryStore } from '../../../DiscoveryStore';
import useDiscoveryStore from '../../../DiscoveryStore/UseDiscoveryStore';
import Exit from '../../Exit';
import PageLayoutWithAuthenticationWrapper from '../../PageLayoutWithAuthenticationWrapper';
import { exitToDashboard } from '../Common/Experience/Actions/Exitable/Types';

const Explore: React.FC = () => {
  useDiscoveryStore();

  switch (currentUserStore.state.kind) {
    case 'ready':
    case 'refreshing':
      return (
        <PageLayoutWithAuthenticationWrapper>
          {first(discoveryStore.learningCollections)
            .map(({ payload }) => payload)
            .cata({
              Just: (value) => <Redirect to={targetedInvitationRoute(value.uuid)} />,
              Nothing: () => <Exit exitContext={exitToDashboard()} />,
            })}
        </PageLayoutWithAuthenticationWrapper>
      );
    case 'logging-out':
    case 'anonymous':
    case 'errored':
    case 'loading':
    case 'waiting':
      return <PageLayoutWithAuthenticationWrapper />;
  }
};

export default observer(Explore);
