import { observer } from 'mobx-react';

function RegistrationAuth() {
  return (
    <div>
      <h1>Registration Auth</h1>
    </div>
  );
}

export default observer(RegistrationAuth);
