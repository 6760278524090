import { action, computed, makeObservable, observable } from 'mobx';
import { AppyError, callApi } from '../../Appy';
import { Link } from '../../Resource/Types';
import { registrationInvitationDecoder } from '../Decoders';
import { RegistrationInvitationResource } from '../Types';
import { RegistrationInvitationStoreState, RegistrationInvitationStoreStateLoaded } from './Types';

export class RegistrationInvitationStore {
  @observable
  state: RegistrationInvitationStoreState = {
    kind: 'registration-invitation-store-state-initialized',
  };

  constructor() {
    makeObservable(this);
  }

  @action
  load = (link: Link) => {
    if (this.isLoading) {
      return;
    }

    if (isLoaded(this) && this.state.link.href === link.href) {
      return;
    }

    this.setLoading(link);

    callApi(registrationInvitationDecoder, {}, link).fork(
      (err) => this.setError(link, err),
      (registrationInvitation) => this.setLoaded(link, registrationInvitation),
    );
  };

  @computed
  get isLoading() {
    return this.state.kind === 'registration-invitation-store-state-loading';
  }

  @computed
  get links(): ReadonlyArray<Link> {
    switch (this.state.kind) {
      case 'registration-invitation-store-state-initialized':
      case 'registration-invitation-store-state-loading':
      case 'registration-invitation-store-state-error':
        return [];
      case 'registration-invitation-store-state-loaded':
        return this.state.registrationInvitation.links;
    }
  }

  @action
  private setLoading(link: Link) {
    this.state = {
      kind: 'registration-invitation-store-state-loading',
      link,
    };
  }

  @action
  private setLoaded(link: Link, registrationInvitation: RegistrationInvitationResource) {
    this.state = {
      kind: 'registration-invitation-store-state-loaded',
      link,
      registrationInvitation,
    };
  }

  @action
  private setError(link: Link, error: AppyError) {
    this.state = {
      kind: 'registration-invitation-store-state-error',
      link,
      error,
    };
  }
}

export function isLoaded(
  store: RegistrationInvitationStore,
): store is RegistrationInvitationStore & {
  state: RegistrationInvitationStoreStateLoaded;
} {
  return store.state.kind === 'registration-invitation-store-state-loaded';
}

export function isDirectEnrollment(
  store: RegistrationInvitationStore,
): store is RegistrationInvitationStore & {
  state: RegistrationInvitationStoreStateLoaded & {
    registrationInvitation: RegistrationInvitationResource & {
      payload: RegistrationInvitationResource['payload'] & { source: 'direct-enrollment' };
    };
  };
} {
  return (
    isLoaded(store) && store.state.registrationInvitation.payload.source === 'direct-enrollment'
  );
}

const registrationInvitationStore = new RegistrationInvitationStore();
export default registrationInvitationStore;
