import { observer } from 'mobx-react';
import { useContext } from 'react';
import { PreviewModeContext } from '../../../Native/AEP/DiscoveryPortal/PreviewMode/PreviewModeContext';
import RegistrationNavigation from '../RegistrationNavigation';

function Header() {
  return (
    <div className="bg-white sm:px-0">
      <_NavigationBar />
    </div>
  );
}

const _NavigationBar = observer(() => {
  const previewMode = useContext(PreviewModeContext);
  switch (previewMode) {
    case 'preview':
      return <></>;
    case 'default':
      return <RegistrationNavigation className="mx-auto box-border xl:container" />;
  }
});

export default observer(Header);
