import { BarsIcon, cn, IconButton, NavBar, NavBarContent } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import ApplicationBrand from '../../../components/Platform/NavigationBar/ApplicationBrand';
import useNavbarOpenable from '../../../components/Platform/NavigationBar/hooks/useNavbarOpenable';
import MainListMenu from '../../../components/Platform/NavigationBar/MainListMenu';
import UseCaseMenu from '../../../components/Platform/NavigationBar/UseCaseMenu';
import PositionContext from '../../../components/PositionContext';
import AccountMenu from './AccountMenu';
import ApplicationMenu from './ApplicationMenu';
import SidebarNavigation from './SidebarNavigation';
import SupportMenu from './SupportMenu';
interface RegistrationNavigationProps {
  className?: string;
}

function RegistrationNavigation({ className }: RegistrationNavigationProps) {
  return (
    <PositionContext.Provider value={{ kind: 'nav' }}>
      <NavBar data-testid="header" className={cn('w-full px-6 shadow-none md:px-12', className)}>
        <_NavbarContents />

        <AccountMenu />
      </NavBar>
    </PositionContext.Provider>
  );
}

const _NavbarContents = observer(({}) => {
  const { isOpen, mode, onOpen, onClose } = useNavbarOpenable();

  if (mode === 'desktop') {
    return (
      <>
        <ApplicationMenu />
        <ApplicationBrand />
        <NavBarContent className="md:gap-x-5 xl:gap-x-10">
          <UseCaseMenu />
          <MainListMenu />
          <SupportMenu />
        </NavBarContent>
      </>
    );
  } else {
    return (
      <>
        <IconButton onClick={onOpen}>
          <BarsIcon />
        </IconButton>
        <SidebarNavigation isOpen={isOpen} onClose={onClose} />
      </>
    );
  }
});

export default observer(RegistrationNavigation);
