import { useMediaQuery } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { PromotableExperience } from '../Types';
import MobilePromotableExperienceTileView from './MobilePromotableExperienceTileView';
import PromotableExperienceTileView from './PromotableExperienceTileView';
import ExperiencesStore from '../../ExperienceSelection/Experiences/ExperiencesStore';

interface Props {
  experience: PromotableExperience;
  experiencesStore: ExperiencesStore;
}

function PromotableExperienceCarouselTile({ experience, experiencesStore }: Props) {
  const breakpoint = useMediaQuery();

  switch (breakpoint) {
    case 'xl':
    case 'lg':
      return (
        <PromotableExperienceTileView experience={experience} experiencesStore={experiencesStore} />
      );
    case 'md':
    case 'sm':
    case 'xs':
      return (
        <MobilePromotableExperienceTileView
          experience={experience}
          experiencesStore={experiencesStore}
        />
      );
  }
}

export default observer(PromotableExperienceCarouselTile);
