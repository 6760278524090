import { base64Decoder, explicitMaybe, jsonParserDecoder, pipeD } from '@execonline-inc/decoders';
import { Decoder, at, field, string, succeed } from 'jsonous';
import { DiscoveryPortalOpenInviteUuid, DiscoveryPortalOpenInviteUuidNode } from './Types';

export const discoveryPortalOpenInviteUuidDecoder: Decoder<DiscoveryPortalOpenInviteUuid> = succeed(
  {},
).assign('uuid', field('uuid', explicitMaybe(string)));

export const dpResourceDecoder: Decoder<DiscoveryPortalOpenInviteUuidNode> = succeed({})
  .assign(
    'openInviteUuid',
    at(
      ['attribs', 'data-attr-open-invite-uuid'],
      pipeD(jsonParserDecoder(base64Decoder), discoveryPortalOpenInviteUuidDecoder),
    ),
  )
  .assign(
    'kind',
    succeed<'discovery-portal-open-invite-uuid'>('discovery-portal-open-invite-uuid'),
  );
