import { Decoder, array, field, string, succeed } from 'jsonous';
import {
  HtmlANode,
  HtmlANodeAttribs,
  HtmlANodeChild,
  HtmlImgNode,
  HtmlImgNodeAttribs,
} from './Types';

export const htmlImgNodeAttribsDecoder: Decoder<HtmlImgNodeAttribs> = succeed({}).assign(
  'src',
  field('src', string),
);

export const htmlANodeAttribsDecoder: Decoder<HtmlANodeAttribs> = succeed({}).assign(
  'href',
  field('href', string),
);

export const htmlANodeChildDecoder: Decoder<HtmlANodeChild> = succeed({}).assign(
  'data',
  field('data', string),
);

export const htmlANodeDecoder: Decoder<HtmlANode> = succeed({})
  .assign('name', field('name', string))
  .assign('attribs', field('attribs', htmlANodeAttribsDecoder))
  .assign('children', field('children', array(htmlANodeChildDecoder)));

export const htmlImgNodeDecoder: Decoder<HtmlImgNode> = succeed({})
  .assign('name', field('name', string))
  .assign('attribs', field('attribs', htmlImgNodeAttribsDecoder));
