import { CircleCheckIcon, CloseIcon, cn } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { toasterStore } from './store';

function MessageBody() {
  switch (toasterStore.type) {
    case 'error':
      return (
        <div
          className={cn(
            'transform rounded-lg px-4 py-2 shadow-md transition-all duration-300 ease-in-out',
            {
              'translate-y-0 opacity-100': true,
              '-translate-y-full opacity-0': false,
              'bg-[#299504] text-white': false,
              'bg-red-500 text-white': true,
            },
          )}
        >
          <div className="flex items-center gap-2">
            <CloseIcon color="white" />
            <span>{toasterStore.message}</span>
          </div>
        </div>
      );
    case 'success':
      return (
        <div
          className={cn(
            'transform rounded-lg px-4 py-2 shadow-md transition-all duration-300 ease-in-out',
            {
              'translate-y-0 opacity-100': true,
              '-translate-y-full opacity-0': false,
              'bg-[#299504] text-white': true,
              'bg-red-500 text-white': false,
            },
          )}
        >
          <div className="flex items-center gap-2">
            <CircleCheckIcon color="white" />
            <span>{toasterStore.message}</span>
          </div>
        </div>
      );
  }
}

export default observer(MessageBody);
