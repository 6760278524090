import { explicitMaybe, stringLiteral } from '@execonline-inc/decoders';
import { Decoder, field, number, oneOf, string, succeed } from 'jsonous';
import { resourceDecoder } from '../../../../../../Resource/Decoders';
import { PaymentIntent, PaymentIntentResource, PaymentIntentStatus } from '../Types';

export const statusDecoder: Decoder<PaymentIntentStatus> = oneOf([
  stringLiteral<PaymentIntentStatus>('requires_payment_method'),
  stringLiteral<PaymentIntentStatus>('requires_confirmation'),
  stringLiteral<PaymentIntentStatus>('requires_action'),
  stringLiteral<PaymentIntentStatus>('processing'),
  stringLiteral<PaymentIntentStatus>('requires_capture'),
  stringLiteral<PaymentIntentStatus>('canceled'),
  stringLiteral<PaymentIntentStatus>('succeeded'),
]);

const paymentIntentDecoder: Decoder<PaymentIntent> = succeed({})
  .assign('id', field('id', number))
  .assign('status', field('status', statusDecoder))
  .assign('clientSecret', field('client_secret', explicitMaybe(string)))
  .assign('stripePaymentIntentId', field('stripe_payment_intent_id', explicitMaybe(string)));

export const paymentIntentResourceDecoder: Decoder<PaymentIntentResource> =
  resourceDecoder(paymentIntentDecoder);
