import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../../../Translations';
import { CoachProfileResource } from '../../../../EmbeddedFormFieldAsset/Types';
import ModalStore from '../../../../Modal/Store';
import * as style from '../../style.module.css';
import CoachLabel from '../CoachLabel';
import SectionTitle from '../SectionTitle';

interface Props {
  coachProfile: CoachProfileResource;
  modalStore: ModalStore;
}

const CoachInfo: React.FC<Props> = ({ coachProfile, modalStore }) => (
  <div className={style.coachRow}>
    <div className={'flex justify-between'}>
      <SectionTitle text="Your coach" />
    </div>
    <div className={style.coachContentBox}>
      <CoachLabel coachProfile={coachProfile}>
        <div>
          <button className={style.readMoreButton} onClick={modalStore.open}>
            <span>
              <T kind="view profile" />
            </span>
          </button>
        </div>
      </CoachLabel>
    </div>
  </div>
);

export default observer(CoachInfo);
