import { explicitMaybe, stringLiteral } from '@execonline-inc/decoders';
import { alreadyTranslatedText } from '@execonline-inc/translations';
import { identity } from '@kofno/piper';
import { Decoder, array, field, number, oneOf, string, succeed } from 'jsonous';
import { fromArrayMaybe } from 'nonempty-list';
import { resourceDecoder } from '../../../../Resource/Decoders';
import {
  experienceDecoder,
  recommendationDecoder,
} from '../ExperienceSelection/Experiences/Decoders';
import { isProductCollection, isProgramFamily } from './Functions';
import {
  PromotableExperience,
  PromotableGroup,
  PromotableGroupMember,
  PromotableGroupMemberBase,
  PromotableGroupMemberKind,
  PromotableGroupMemberResource,
  PromotableGroupProductCollection,
  PromotableGroupProgramFamily,
  PromotableGroupResource,
  PromotableGroupsItem,
  Style,
} from './Types';

const styleDecoder: Decoder<Style> = oneOf<Style>([stringLiteral<Style>('carousel')]);

export const promotableGroupMemberKindDecoder: Decoder<PromotableGroupMemberKind> = oneOf([
  stringLiteral<PromotableGroupMemberKind>('program-family'),
  stringLiteral<PromotableGroupMemberKind>('product-collection'),
]);

const promotableBaseGroupMemberDecoder: Decoder<PromotableGroupMemberBase> = succeed({})
  .assign('id', field('id', number))
  .assign('name', field('name', string))
  .assign('style', field('style', styleDecoder));

const promotableGroupProgramFamilyDecoder: Decoder<PromotableGroupProgramFamily> =
  promotableBaseGroupMemberDecoder
    .assign('kind', field('kind', stringLiteral('program-family')))
    .assign('description', field('description', string));

const promotableGroupProductCollectionDecoder: Decoder<PromotableGroupProductCollection> =
  promotableBaseGroupMemberDecoder
    .assign('kind', field('kind', stringLiteral('product-collection')))
    .assign('description', field('description', explicitMaybe(string)));

const promotableGroupMemberDecoder: Decoder<PromotableGroupMember> = oneOf<PromotableGroupMember>([
  promotableGroupProductCollectionDecoder.map<PromotableGroupMember>(identity),
  promotableGroupProgramFamilyDecoder.map<PromotableGroupMember>(identity),
]);

const promotableGroupMemberResourceDecoder: Decoder<PromotableGroupMemberResource> =
  resourceDecoder(promotableGroupMemberDecoder);

const promotableGroupDecoder: Decoder<PromotableGroup> = succeed({})
  .assign('id', field('id', number))
  .assign('name', field('name', string))
  .assign('description', field('description', string))
  .assign(
    'programFamilies',
    field(
      'members',
      array(promotableGroupMemberResourceDecoder).map((members) =>
        fromArrayMaybe(members.filter(isProgramFamily)),
      ),
    ),
  )
  .assign(
    'productCollections',
    field(
      'members',
      array(promotableGroupMemberResourceDecoder).map((members) =>
        fromArrayMaybe(members.filter(isProductCollection)),
      ),
    ),
  );

export const promotableGroupResourceDecoder: Decoder<PromotableGroupResource> =
  resourceDecoder(promotableGroupDecoder);

const promotableGroupsItemDecoder: Decoder<PromotableGroupsItem> = succeed({}).assign(
  'id',
  field('id', number),
);

const promotableGroupsItemResourceDecoder = resourceDecoder(promotableGroupsItemDecoder);

export const promotableGroupsResourceDecoder = resourceDecoder(
  array(promotableGroupsItemResourceDecoder),
);

export const promotableProductCollectionResourceDecoder = resourceDecoder(recommendationDecoder);

const promotableProgramFamilyDecoder: Decoder<PromotableExperience> = experienceDecoder
  .assign(
    'promotableMarketingTitle',
    field('promotable_marketing_title', explicitMaybe(alreadyTranslatedText)),
  )
  .assign(
    'promotableMarketingCopy',
    field('promotable_marketing_copy', explicitMaybe(alreadyTranslatedText)),
  );

export const promotableProgramFamilyResourceDecoder = resourceDecoder(
  promotableProgramFamilyDecoder,
);
