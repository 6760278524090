import { Button, Modal, ModalBody, ModalFooter, Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { useState } from 'react';
import ProgramStore from '../../../../ProgramStore';
import { ProgramDetailResource } from '../../../../ProgramStore/Types';
import { T } from '../../../../Translations';
import SelectOptions from './SelectOptions';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  programStore: ProgramStore;
  programDetailResource: ProgramDetailResource;
}

function OptionsProgramModal({ isOpen, onClose, programStore, programDetailResource }: Props) {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [otherReason, setOtherReason] = useState<string>('');

  return (
    <Modal open={isOpen} size="3xl">
      <Typography variant="subtitleLarge" fontWeight="semibold" className="px-8 pt-6">
        <T kind="Why do you want to drop this program?" />
      </Typography>
      <ModalBody className="flex flex-col !px-8">
        <Typography
          color="dark"
          variant="bodyMedium"
          fontWeight="normal"
          className="mb-6 tracking-wide"
        >
          <T kind="Dropping the program will remove it from your dashboard. You can always re-enroll to the program from your Learning Collection at a later date and pick back up where you left off." />
        </Typography>
        <SelectOptions
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          otherReason={otherReason}
          setOtherReason={setOtherReason}
        />
      </ModalBody>
      <ModalFooter className="pb- flex justify-end">
        <Button variant="text" onClick={onClose}>
          <Typography color="black">
            <T kind="Cancel" />
          </Typography>
        </Button>
        <Button
          onClick={() => {
            programStore.dropProgram(
              programDetailResource,
              selectedOption === 'Other (Please explain)' ? otherReason : selectedOption,
            );
            onClose();
          }}
        >
          <Typography color="white">
            <T kind="Submit" />
          </Typography>
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default observer(OptionsProgramModal);
