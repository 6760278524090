import { observer } from 'mobx-react';
import ExperiencePrice from './ExperiencePrice';
import ExperienceProductBadge from './ExperienceProductBadge';

function ExperienceFooter() {
  return (
    <div className="flex w-full items-center justify-between">
      <ExperienceProductBadge />
      <ExperiencePrice />
    </div>
  );
}

export default observer(ExperienceFooter);
