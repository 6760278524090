import { emptyFragment, ListItem } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../Translations';
import navigationStore from './NavigationStore';

export interface Props {
  onClick?: () => void;
}

function SupportMenu({ onClick }: Props) {
  const handleClick = (href: string) => () => {
    if (onClick !== undefined) onClick();
    window.open(href, '_blank', 'noopener noreferrer');
  };

  return navigationStore.supportMenuHref
    .map((href) => (
      <ListItem role="menuitem" onClick={handleClick(href)}>
        <T kind="Support" />
      </ListItem>
    ))
    .getOrElse(emptyFragment);
}

export default observer(SupportMenu);
