import { Maybe } from 'maybeasy';
import { Resource } from '../Resource/Types';

type RegistrationType = 'Auditor' | 'Student';

type InvitationStatus = 'active' | 'inactive';

type CommunicationPreference = 'Mute' | 'Send';

type Source =
  | 'direct-enrollment'
  | 'open-enrollment'
  | 'shared-open-enrollment'
  | 'program-family-shared-open-enrollment';
export interface RegistrationInvitation {
  id: number;
  guid: string;
  useCaseId: number;
  registrationType: RegistrationType;
  invitationStatus: InvitationStatus;
  programFamilyId: Maybe<number>;
  autoEnrollProgramId: Maybe<number>;
  communicationPreference: CommunicationPreference;
  source: Source;
  allowDiscoveryPortalFilters: boolean;
}

export type RegistrationInvitationResource = Resource<RegistrationInvitation>;

export function autoEnrollProgramId(
  registrationInvitation: RegistrationInvitationResource,
): Maybe<number> {
  return registrationInvitation.payload.autoEnrollProgramId;
}
