import { assertNever, pipe } from '@kofno/piper';
import { Maybe, just, nothing } from 'maybeasy';
import { action, computed, makeObservable, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { FlashAlert, errorAlert } from '../Notifications/Types';
import { organizationStore } from '../Organization/Store';
import { programsStore } from '../ProgramsStore';
import { Link } from '../Resource/Types';
import { SupportedLanguageCode } from '../SupportedLanguages/Types';
import { toolingsStore } from '../ToolingsStore';
import { TPlainTextKey } from '../Translations';
import { Errored, State } from './States';
import { CurrentUserResource } from './Types';

class CurrentUserStore {
  readonly childStores = [organizationStore, programsStore, toolingsStore];

  @observable state: State = { kind: 'waiting' };

  constructor() {
    makeObservable(this);
  }

  @action anonymous = (message: Maybe<TPlainTextKey>): void => {
    switch (this.state.kind) {
      case 'ready':
      case 'waiting':
      case 'refreshing':
      case 'anonymous':
      case 'logging-out':
      case 'errored':
      case 'loading':
        this.state = { kind: 'anonymous', message };
        break;
      default:
        assertNever(this.state);
    }
  };

  @action loading = (): void => {
    switch (this.state.kind) {
      case 'ready':
      case 'waiting':
      case 'refreshing':
      case 'anonymous':
      case 'logging-out':
      case 'errored':
        this.state = { kind: 'loading' };
        break;
      case 'loading':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action refreshing = (): void => {
    switch (this.state.kind) {
      case 'ready':
        this.state = { kind: 'refreshing', currentUserResource: this.state.currentUserResource };
        break;
      case 'waiting':
      case 'loading':
      case 'refreshing':
      case 'anonymous':
      case 'logging-out':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action ready = (currentUserResource: CurrentUserResource): void => {
    switch (this.state.kind) {
      case 'loading':
      case 'ready':
      case 'refreshing':
      case 'logging-out':
        this.state = { kind: 'ready', currentUserResource };
        break;
      case 'waiting':
      case 'anonymous':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action loggingOut = (message: TPlainTextKey): void => {
    switch (this.state.kind) {
      case 'ready':
        this.state = {
          kind: 'logging-out',
          currentUserResource: this.state.currentUserResource,
          message,
        };
        break;
      case 'waiting':
      case 'loading':
      case 'refreshing':
      case 'anonymous':
      case 'logging-out':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action errored = (next: Omit<Errored, 'kind'>): void => {
    switch (this.state.kind) {
      case 'ready':
      case 'refreshing':
      case 'logging-out':
      case 'loading':
        this.state = { kind: 'errored', ...next };
        break;
      case 'waiting':
      case 'anonymous':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'errored':
        return just(errorAlert(error(this.state.message)));
      case 'anonymous':
        return this.state.message.map(pipe(error, errorAlert));
      case 'ready':
      case 'refreshing':
      case 'logging-out':
      case 'loading':
      case 'waiting':
        return nothing();
      default:
        assertNever(this.state);
    }
  }

  @computed get links(): ReadonlyArray<Link> {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
      case 'logging-out':
      case 'errored':
      case 'anonymous':
        return [];
      case 'ready':
      case 'refreshing':
        return this.state.currentUserResource.links;
    }
  }

  @computed get preferredLanguage(): Maybe<SupportedLanguageCode> {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
      case 'logging-out':
      case 'errored':
      case 'anonymous':
        return nothing();
      case 'ready':
      case 'refreshing':
        return this.state.currentUserResource.payload.preferredLanguage;
    }
  }
}

export type { CurrentUserStore };

export const currentUserStore = new CurrentUserStore();
