import { EnvelopeCheckIcon, Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import registrationInvitationStore, {
  isDirectEnrollment,
} from '../../../RegistrationInvitation/RegistrationInvitationStore';
import { T } from '../../../Translations';

function ProgramDetailsItems() {
  if (isDirectEnrollment(registrationInvitationStore)) {
    return (
      <div className="flex gap-x-1 text-[#006177]">
        <EnvelopeCheckIcon size="md" color="inherit" />
        <Typography color="inherit" variant="bodySmall" fontWeight="medium" as="span">
          <T kind="Invited" />
        </Typography>
      </div>
    );
  }

  return <></>;
}

export default observer(ProgramDetailsItems);
