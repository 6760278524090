import { explicitMaybe, stringLiteral } from '@execonline-inc/decoders';
import { Decoder, array, dateISO, field, number, oneOf, succeed } from 'jsonous';
import { Maybe } from 'maybeasy';
import { BehaviorSubject } from 'rxjs';
import { Task } from 'taskarian';
import { callApi } from '../../../../Appy';
import { resourceDecoder } from '../../../../Resource/Decoders';
import { Link, Resource } from '../../../../Resource/Types';

type Kind = 'on-demand' | 'scheduled';
export interface Availability {
  kind: 'on-demand' | 'scheduled';
  programId: number;
  programFamilyId: ProgramFamilyId;
  date: Maybe<Date>;
}

type AvailabilityResource = Resource<Availability>;
type AvailabilityResources = AvailabilityResource[];

export type AvailabilitiesResource = Resource<AvailabilityResources>;

const kindDecoder: Decoder<Kind> = oneOf<Kind>([
  stringLiteral<Kind>('on-demand'),
  stringLiteral<Kind>('scheduled'),
]);

const availabilityDecoder: Decoder<Availability> = succeed({})
  .assign('kind', field('kind', kindDecoder))
  .assign('programId', field('program_id', number))
  .assign('programFamilyId', field('program_family_id', number))
  .assign('date', field('date', explicitMaybe(dateISO)));

const availabilityResourceDecoder: Decoder<AvailabilityResource> =
  resourceDecoder(availabilityDecoder);

const availabilityResourcesDecoder: Decoder<AvailabilityResources> = array(
  availabilityResourceDecoder,
);

const availabilitiesResourceDecoder: Decoder<AvailabilitiesResource> = resourceDecoder(
  availabilityResourcesDecoder,
);

interface AvailabilitiesStoreEvent {
  observable: BehaviorSubject<Availability[]>;
}

type ProgramFamilyId = number;

interface AvailabilitiesStoreState {
  behaviors: Record<ProgramFamilyId, AvailabilitiesStoreEvent>;
}

export class AvailabilitiesStore {
  private data: AvailabilitiesStoreState = {
    behaviors: {},
  };

  private state: 'initialized' | 'loading' | 'loaded' = 'initialized';

  getAvailabilities = (programFamilyId: ProgramFamilyId): BehaviorSubject<Availability[]> => {
    const behavior = this.data.behaviors[programFamilyId];
    if (behavior) {
      return behavior.observable;
    }
    this.data.behaviors[programFamilyId] = {
      observable: new BehaviorSubject<Availability[]>([]),
    };
    return this.data.behaviors[programFamilyId].observable;
  };

  loadAllAvailabilities = (availabilityIndexLink: Link): Promise<void> => {
    if (this.state === 'loaded' || this.state === 'loading') {
      return Promise.resolve();
    }

    this.state = 'loading';

    return callApi(availabilitiesResourceDecoder, {}, availabilityIndexLink)
      .map((resources) => {
        resources.payload.forEach(({ payload }) => {
          const behavior = this.getAvailabilities(payload.programFamilyId);
          behavior.next([...behavior.getValue(), payload]);
        });
        return availabilityIndexLink;
      })
      .orElse((err) => {
        console.warn('Availability resource failed to fetch', err);
        return Task.succeed(availabilityIndexLink);
      })
      .map(() => {})
      .resolve();
  };
}

const availabilitiesStore = new AvailabilitiesStore();
export default availabilitiesStore;
