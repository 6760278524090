import { observer } from 'mobx-react';
import {
  ExperienceSection,
  ExperienceSectionBody,
  ExperienceSectionHeader,
  ExperienceSectionTitle,
} from '../../../Native/AEP/DiscoveryPortal/Common/ExperienceSection';
import { T } from '../../../Translations';
import ExperienceTiles from './ExperienceTiles';
import ProductCollections from './ProductCollections';

function ExperiencesAndProductCollections() {
  return (
    <>
      <ProductCollections />
      <ExperienceSection className="bg-inherit pb-6 pt-0 !shadow-none sm:py-8">
        <ExperienceSectionHeader>
          <ExperienceSectionTitle>
            <T kind="All Programs in this Catalog" />
          </ExperienceSectionTitle>
        </ExperienceSectionHeader>
        <ExperienceSectionBody>
          <ExperienceTiles />
        </ExperienceSectionBody>
      </ExperienceSection>
    </>
  );
}

export default observer(ExperiencesAndProductCollections);
