import { mapMaybe } from '@execonline-inc/collections';
import { always, identity } from '@kofno/piper';
import { just, Maybe } from 'maybeasy';
import { computed } from 'mobx';
import { DropdownListItem } from '../../../components/Dropdown/Types';
import {
  logoutMenuItem,
  notesMenuItem,
  profileMenuItem,
} from '../../../components/Platform/NavigationBar/AccountMenu/Types';
import {
  clientPortalMenuItem,
  enterprisePortalMenuItem,
  learningPlatformMenuItem,
} from '../../../components/Platform/NavigationBar/ApplicationMenu/Types';
import { createLanguageUrl } from '../../../CreateLanguageUrl';
import { currentUserStore } from '../../../CurrentUser/Store';
import { findLink } from '../../../LinkyLinky';

class NavigationStore {
  @computed
  get accountDropDownItems(): ReadonlyArray<DropdownListItem> {
    const links = currentUserStore.links;
    if (links.length === 0) {
      return [];
    } else {
      return mapMaybe(identity, [
        findLink('notes', links).map(always(notesMenuItem)),
        findLink('profile', links).map(always(profileMenuItem)),
        just(logoutMenuItem),
      ]);
    }
  }

  @computed
  get applicationDropDownItems(): ReadonlyArray<DropdownListItem> {
    const links = currentUserStore.links;
    if (links.length === 0) {
      return [];
    } else {
      return mapMaybe(identity, [
        findLink('client-portal-app', links).map(clientPortalMenuItem),
        findLink('enterprise-portal-app', links).map(enterprisePortalMenuItem),
        just(learningPlatformMenuItem),
      ]);
    }
  }

  @computed
  get supportMenuHref(): Maybe<string> {
    return findLink('support', currentUserStore.links)
      .andThen((link) => createLanguageUrl(link.href, currentUserStore.preferredLanguage))
      .map(({ href }) => href);
  }
}

const navigationStore = new NavigationStore();
export default navigationStore;
