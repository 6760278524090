import { first } from '@execonline-inc/collections';
import { emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import useAvailabilitiesStore from '../Availabilities/AvailabilitiesStore/UseAvailabilitiesStore';
import FormattedUpcomingDates from './FormattedUpcomingDates';

function ProgramUpcomingDates() {
  const { availabilities } = useAvailabilitiesStore();

  return first(availabilities)
    .map((availability) => {
      return (
        <Typography className="text-gray-900" variant="caption">
          <FormattedUpcomingDates availability={availability} />
        </Typography>
      );
    })
    .getOrElse(emptyFragment);
}

export default observer(ProgramUpcomingDates);
