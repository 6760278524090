import { Decoder, array, field, number, string, succeed } from 'jsonous';
import { profileResourceDecoder } from '../../ProfileStore/Decoders';
import { resourceDecoder } from '../../Resource/Decoders';
import { LearningPartner, LearningPartnerResource, LearningPartnersResource } from '../Types';

const learningPartnerDecoder: Decoder<LearningPartner> = succeed({})
  .assign('id', field('id', number))
  .assign('email', field('email', string))
  .assign('profile', field('profile', profileResourceDecoder));

export const learningPartnerResourceDecoder: Decoder<LearningPartnerResource> =
  resourceDecoder(learningPartnerDecoder);

export const learningPartnersResourceDecoder: Decoder<LearningPartnersResource> = resourceDecoder(
  array(learningPartnerResourceDecoder),
);
