import { ValidationError, validationErrorDecoder } from '@execonline-inc/resource';
import { identity } from '@kofno/piper';
import { Decoder, array, field, succeed } from 'jsonous';
import { resourceDecoder } from '../../../../Resource/Decoders';
import { Resource } from '../../../../Resource/Types';

type ValidationErrors = Resource<{}> & {
  errors: ReadonlyArray<ValidationError>;
};

export const validationErrorsDecoder: Decoder<ValidationErrors> = resourceDecoder(succeed({}))
  .assign('errors', field('errors', array(validationErrorDecoder)))
  .map<ValidationErrors>(identity);
