import { find } from '@execonline-inc/collections';
import { noop } from '@kofno/piper';
import { Header } from 'ajaxian';
import { Decoder, field, string, succeed } from 'jsonous';
import Pusher from 'pusher-js';
import { Task } from 'taskarian';
import { AppyError, clientHeadersT } from '../Appy';
import { errorMessage } from '../ExceptionHandling';
import { Link } from '../Resource/Types';
import { PusherSettingsResource, ToolConfigResource } from '../ToolingsStore/Types';

export interface PusherError {
  error: {
    data: { code: string };
  };
}

const pusherErrorCodeDecoder = succeed({}).assign('code', field('code', string));

const pusherErrorDataDecoder = succeed({}).assign('data', field('data', pusherErrorCodeDecoder));

export const pusherErrorDecoder: Decoder<PusherError> = succeed({}).assign(
  'error',
  field('error', pusherErrorDataDecoder),
);

export interface MissingPusherSettings {
  kind: 'missing-pusher-settings';
}

export const missingPusherSettings = (): MissingPusherSettings => ({
  kind: 'missing-pusher-settings',
});

export interface PusherConnectionError {
  kind: 'pusher-connection-error';
  message: string;
}

export const pusherConnectionError = (message: string): PusherConnectionError => ({
  kind: 'pusher-connection-error',
  message,
});

const isPusherSettings = (t: ToolConfigResource): t is PusherSettingsResource =>
  t.payload.kind === 'pusher-settings';
export interface PusherAuthHeaderError {
  kind: 'pusher-auth-header-error';
  error: AppyError;
}

export const pusherAuthHeaderError = (err: AppyError): PusherAuthHeaderError => ({
  kind: 'pusher-auth-header-error',
  error: err,
});

export const pusherSettings = find(isPusherSettings);

export interface PusherConnectionOptions {
  key: string;
  cluster: string;
  link: Link;
}

const headersToRecord = (headers: ReadonlyArray<Header>): Record<string, string> =>
  headers.reduce((current, next) => {
    return { ...current, [next.field]: next.value };
  }, {});
const createPusherConnection =
  ({ key, cluster, link }: PusherConnectionOptions) =>
  (headers: Record<string, string>): Pusher => {
    return new Pusher(key, {
      cluster: cluster,
      channelAuthorization: { endpoint: link.href, transport: 'ajax', headers: { ...headers } },
    });
  };
export const connectToPusher = (
  pusherOptions: PusherConnectionOptions,
): Task<PusherConnectionError | PusherAuthHeaderError, Pusher> =>
  new Task((reject, resolve) => {
    try {
      clientHeadersT()
        .map(headersToRecord)
        .map(createPusherConnection(pusherOptions))
        .fork(
          (err) => reject(pusherAuthHeaderError(err)),
          (socket) => resolve(socket),
        );
    } catch (error) {
      reject(pusherConnectionError(errorMessage(error)));
    }
    return noop;
  });
