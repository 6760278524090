import { equals } from '@execonline-inc/collections';
import { BarsIcon, cn, IconButton, NavBar, NavBarContent } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import WithCurrentUser from '../../../CurrentUser/Context/WithCurrentUser';
import PositionContext from '../../PositionContext';
import AccountMenu from './AccountMenu';
import ApplicationBrand from './ApplicationBrand';
import ApplicationMenu from './ApplicationMenu';
import MainListMenu from './MainListMenu';
import SidebarNavigation from './SidebarNavigation';
import SupportMenu from './SupportMenu';
import UseCaseMenu from './UseCaseMenu';
import { useNavbarOpenable } from './hooks';

interface Props {
  className?: string;
}

function NavigationBar({ className }: Props) {
  const { isOpen, mode, onOpen, onClose } = useNavbarOpenable();

  return (
    <WithCurrentUser>
      {(user) => (
        <PositionContext.Provider value={{ kind: 'nav' }}>
          <NavBar
            data-testid="header"
            className={cn('w-full px-6 shadow-none md:px-12', className)}
          >
            {when(equals(mode, 'desktop'), true)
              .map(() => (
                <>
                  <ApplicationMenu user={user} />
                  <ApplicationBrand />
                  <NavBarContent className="md:gap-x-5 xl:gap-x-10">
                    <UseCaseMenu />
                    <MainListMenu />
                    <SupportMenu user={user} />
                  </NavBarContent>
                </>
              ))
              .getOrElse(() => (
                <>
                  <IconButton onClick={onOpen}>
                    <BarsIcon />
                  </IconButton>
                  <SidebarNavigation user={user} isOpen={isOpen} onClose={onClose} />
                </>
              ))}

            <AccountMenu user={user} />
          </NavBar>
        </PositionContext.Provider>
      )}
    </WithCurrentUser>
  );
}

export default observer(NavigationBar);
