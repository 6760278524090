import { useEffect, useState } from 'react';
import primaryCompetenciesStore, { Competency } from '.';
import { findLink } from '../../../../LinkyLinky';
import experiencesStore from '../../ExperiencesStore';

function usePrimaryCompetenciesStore<T extends { id: number }>(experience: T) {
  const [primaryCompetencies, setPrimaryCompetencies] = useState<Competency[]>([]);

  useEffect(() => {
    findLink('primary-competencies', experiencesStore.links).do((link) => {
      primaryCompetenciesStore.loadAllPrimaryCompetenciesResources(link);
    });

    const behavior = primaryCompetenciesStore.getPrimaryCompetencies(experience.id);
    const subscription = behavior.subscribe(setPrimaryCompetencies);
    return () => {
      subscription.unsubscribe();
    };
  }, [experiencesStore.links]);

  return {
    primaryCompetencies,
  };
}

export default usePrimaryCompetenciesStore;
