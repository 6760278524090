import { identity } from '@kofno/piper';
import { Decoder, field, succeed } from 'jsonous';
import { Result } from 'resulty';

/**
 * Creates a decoder from a given structure of decoders or nested structures.
 *
 * This function takes a structure where each value is either a `Decoder` or another
 * nested structure of decoders, and returns a `Decoder` that can decode objects
 * matching the given structure.
 *
 * @template T - The type of the structure, which is a record where each value is either
 * a `Decoder` or another nested structure.
 *
 * @param {T} structure - The structure of decoders or nested structures.
 *
 * @returns {Decoder<InferStructure<T>>} - A decoder that can decode objects matching the given structure.
 */
export function createDecoderFromStructure<T extends Structure>(
  structure: T,
  keyToLookup: (key: string) => string = identity,
): Decoder<InferStructure<T>> {
  return Object.entries(structure).reduce(
    (acc, [key, decoderOrStructure]) => {
      const decoder = isDecoder(decoderOrStructure)
        ? field(keyToLookup(key), decoderOrStructure)
        : field(keyToLookup(key), createDecoderFromStructure(decoderOrStructure));
      return acc.assign(key, decoder);
    },
    succeed({}) as Decoder<any>,
  );
}

function isDecoder(value: any): value is Decoder<any> {
  return value instanceof Decoder;
}

type Structure = { [key: string]: Decoder<any> | Structure };

type InferStructure<T extends Structure> = {
  [K in keyof T]: T[K] extends Decoder<infer U>
    ? U
    : T[K] extends Structure
      ? InferStructure<T[K]>
      : never;
};

/**
 * Infers the resulting type of a Decoder.
 *
 * This type utility takes a Decoder type and extracts the type it decodes to.
 *
 * @template D - The Decoder type.
 * @returns The type that the Decoder decodes to.
 */
export type InferType<D extends Decoder<any>> = D extends Decoder<infer T> ? T : never;

/**
 * Infers the resulting type of a Decoder function.
 *
 * This type utility takes a Decoder function and extracts the type it decodes to.
 *
 * @template F - The Decoder function type.
 * @returns The type that the Decoder function decodes to.
 */
export type InferTypeFromFn<F extends (value: any) => Result<string, any>> = F extends (
  value: any,
) => Result<string, infer T>
  ? T
  : never;

/**
 * Converts a camelCase string to snake_case.
 *
 * @param str - The camelCase string to be converted.
 * @returns The converted snake_case string.
 *
 * @example
 * ```typescript
 * const result = camelCaseToSnakeCase('camelCaseString');
 * console.log(result); // Outputs: camel_case_string
 * ```
 */
export function snakeCase(str: string): string {
  return str.replace(/([A-Z])/g, (m) => `_${m.toLowerCase()}`);
}
