import { alreadyTranslatedText } from '@execonline-inc/translations';
import { Decoder, array, field, number, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import { resourceResourceDecoder } from '../../ResourceStore/Decoders';
import { segmentResourcesResourceDecoder } from '../../SegmentResourceStore/Decoders';
import { teamResourcesResourceDecoder } from '../../TeamResourceStore/Decoders';
import {
  ProgramResource,
  ProgramResourceResource,
  ProgramResourcesResource,
  Resources,
  ResourcesResource,
} from '../Types';

export const programResourceDecoder: Decoder<ProgramResource> = succeed({})
  .assign('id', field('id', number))
  .assign('title', field('title', alreadyTranslatedText))
  .assign('resources', field('resources', array(resourceResourceDecoder)))
  .assign('segmentResources', field('segment_resources', segmentResourcesResourceDecoder));

export const programResourceResourceDecoder: Decoder<ProgramResourceResource> =
  resourceDecoder(programResourceDecoder);

export const programResourcesResourceDecoder: Decoder<ProgramResourcesResource> = resourceDecoder(
  array(programResourceResourceDecoder),
);

export const resourcesDecoder: Decoder<Resources> = succeed({})
  .assign('programResources', field('course_resources', programResourcesResourceDecoder))
  .assign('teamResources', field('team_resources', teamResourcesResourceDecoder));

export const resourcesResourceDecoder: Decoder<ResourcesResource> =
  resourceDecoder(resourcesDecoder);
