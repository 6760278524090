import { CardBody, CardFooter, CardHeader, cn, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import EnrollmentStore from '../../../DiscoveryPortal/Enrollment/Store';
import ExperiencesStore from '../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import {
  ExperienceFooter,
  ExperienceHeaderEndIcon,
  ExperiencesHeaderLogo,
  getExperienceHeaderBackgroundByType,
} from '../../Experience';
import { ExperienceContextProvider } from '../../Experience/ExperienceContext';
import { ExperienceResource, groupCoachingExperience } from '../../Experience/Types';
import { ProgramExperiencePopover } from '../../PopoverProgramExperience';
import { useProgramPopoverPlacement } from '../../PopoverProgramExperience/hooks';
import ProgramExperienceActions from '../../ProgramExperienceActions';
import { ExperienceEnrollmentContextProvider } from '../../ProgramExperienceEnrollment';
import { handleEnterKeyDown, handleMouseDown } from '../../Types';
import { ProgramExperienceCard, ProgramExperienceCardVideo } from '../Common';
import ExperienceBody from './ExperienceBody';
import { getExperienceClassNameByType } from './Types';

interface Props {
  className?: string;
  invitationUuid: string;
  experienceResource: ExperienceResource;
  experiencesStore: ExperiencesStore;
  enrollmentStore: EnrollmentStore;
}

function TileViewProgramExperience({
  className,
  invitationUuid,
  experienceResource,
  experiencesStore,
  enrollmentStore,
}: Props) {
  const placement = useProgramPopoverPlacement();

  return (
    <ExperienceEnrollmentContextProvider
      value={{ invitationUuid, experienceResource, enrollmentStore, experiencesStore }}
    >
      {({ onOpen }) => (
        <ExperienceContextProvider value={{ invitationUuid, experienceResource }}>
          <ProgramExperiencePopover
            placement={placement}
            experiencesStore={experiencesStore}
            onEnroll={onOpen}
          >
            {(ref, getTriggerProps) => (
              <ProgramExperienceActions>
                {(onClick) => (
                  <ProgramExperienceCard
                    ref={ref}
                    tabIndex={0}
                    role="button"
                    className={cn(
                      'bg-white text-gray-800',
                      'box-border w-full flex-grow',
                      'xl:min-w-68 xl:max-w-[26.625rem]',
                      getExperienceClassNameByType(experienceResource.payload.offeringType),
                      className,
                    )}
                    {...getTriggerProps()}
                    onClick={handleMouseDown(onClick)}
                    onKeyDown={handleEnterKeyDown(onClick)}
                  >
                    {groupCoachingExperience(experienceResource.payload)
                      .map(emptyFragment)
                      .getOrElse(() => (
                        <ProgramExperienceCardVideo
                          experiencesStore={experiencesStore}
                          className="h-[11.125rem] w-full flex-none rounded-t"
                          height={40}
                        />
                      ))}

                    <CardHeader
                      className={cn(
                        'h-[2.375rem] py-1.5',
                        getExperienceHeaderBackgroundByType(experienceResource.payload),
                      )}
                      endContent={<ExperienceHeaderEndIcon />}
                    >
                      <ExperiencesHeaderLogo />
                    </CardHeader>
                    <CardBody>
                      <ExperienceBody />
                    </CardBody>
                    <CardFooter>
                      <ExperienceFooter />
                    </CardFooter>
                  </ProgramExperienceCard>
                )}
              </ProgramExperienceActions>
            )}
          </ProgramExperiencePopover>
        </ExperienceContextProvider>
      )}
    </ExperienceEnrollmentContextProvider>
  );
}

export default observer(TileViewProgramExperience);
