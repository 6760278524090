import { observer } from 'mobx-react';
import * as React from 'react';
import { Auth } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import { AuthSignIn } from '../Types';
import PasswordForm from './PasswordForm';
import SsoForm from './SsoForm';

interface Props {
  authForm: AuthSignIn;
  auth: Auth;
  emailAddress: string;
}

const Form: React.FC<Props> = ({ authForm, auth, emailAddress }) => {
  switch (authForm.kind) {
    case 'password-sign-in':
      return (
        <PasswordForm usePasswordStore={authForm.store} auth={auth} emailAddress={emailAddress} />
      );
    case 'sso-sign-in':
      return <SsoForm ssoLoginStore={authForm.store} emailAddress={emailAddress} />;
  }
};

export default observer(Form);
