import { cn, CommentLanguageIcon, emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import FormattedLanguages from '../../../Native/AEP/ExperienceDetailView/Common/ExperienceLanguageAvailability/FormattedLanguages';
import { formatLanguagesList } from '../../../Native/AEP/ExperienceDetailView/Common/ExperienceLanguageAvailability/Types';
import { LanguagesResource } from '../../../ProfileFormStore/Types';
import { T } from '../../../Translations';
import useAvailableLanguages from '../AvailableLanguages/AvailableLanguagesStore/UseAvailableLanguages';

export interface LanguageAvailabilityProps {
  className?: string;
}

function ExperienceLanguageAvailability({ className }: LanguageAvailabilityProps) {
  const { languages } = useAvailableLanguages();

  return languages
    .map((ls) => <_ExperienceLanguageAvailability languages={ls} className={className} />)
    .getOrElse(emptyFragment);
}

const _ExperienceLanguageAvailability = observer(
  ({ languages, className }: { languages: LanguagesResource; className?: string }) => {
    const languagesList = languages.payload.map(({ payload }) => payload.englishName);
    const formattedLanguages = formatLanguagesList(languagesList);
    return (
      <div className={cn('flex items-center gap-x-2.5', className)}>
        <CommentLanguageIcon size="md" color="inherit" />
        <Typography variant="bodyMedium" color="inherit">
          <T
            kind="Available in <span>{{languages}}</span>"
            languages={formattedLanguages.join(', ')}
            span={() => <FormattedLanguages languages={formattedLanguages} />}
          />
        </Typography>
      </div>
    );
  },
);

export default observer(ExperienceLanguageAvailability);
