import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import {
  GroupCoachingIcon,
  LeadershipCoachingIcon,
} from '../../../Native/AEP/Common/Experience/ExperienceHeader/Icons';
import {
  ExperienceDictionaryEntryResponse,
  isGroupCoachingExperience,
  isLeadershipCoachingExperience,
} from '../../ExperiencesDictionary/Types';
import usePortalExperienceContext from '../PortalExperience/UsePortalExperienceContext';

function ExperienceHeaderEndIcon() {
  const experience = usePortalExperienceContext();
  return experience
    .andThen((lookupResults) => lookupResults.experience)
    .map((experience) => <_ExerienceHeaderEndIcon experience={experience} />)
    .getOrElse(emptyFragment);
}

const _ExerienceHeaderEndIcon = observer(
  ({ experience }: { experience: ExperienceDictionaryEntryResponse }) => {
    if (isLeadershipCoachingExperience(experience.payload)) {
      return <LeadershipCoachingIcon className="absolute bottom-0 right-4 h-[35px] w-[62px]" />;
    }

    if (isGroupCoachingExperience(experience.payload)) {
      return <GroupCoachingIcon className="absolute bottom-0 right-4 h-[35px] w-[62px]" />;
    }

    return <></>;
  },
);

export default observer(ExperienceHeaderEndIcon);
