import { useEffect } from 'react';
import { findLink } from '../../../LinkyLinky';
import useCaseStore from '../../../RegistrationInvitation/UseCaseStore';
import experienceFiltersStore, { FilterStoreStateLoaded } from './FiltersStore';

function filtersAreLoaded(
  store: typeof experienceFiltersStore,
): store is typeof experienceFiltersStore & { state: FilterStoreStateLoaded } {
  return store.isLoaded;
}

function useFilterStores() {
  useEffect(() => {
    if (experienceFiltersStore.isLoaded) {
      return;
    }
    findLink('experience-filters', useCaseStore.links).do(experienceFiltersStore.load);
  }, [useCaseStore.links]);

  return {
    filtersAreLoaded,
  };
}

export default useFilterStores;
