import { numberToStringDecoder } from '@execonline-inc/decoders';
import { Decoder, array, field, string, succeed } from 'jsonous';
import { errorDecoder, linksDecoder, resourceDecoder } from '../../Resource/Decoders';
import { Resource, ResourceWithErrors } from '../../Resource/Types';
import { CountryOption, CountryOptions } from '../Types';

export const countryOptionDecoder: Decoder<CountryOption> = succeed({})
  .assign('id', field('value', numberToStringDecoder))
  .assign('name', field('label', string));

const countryOptionsDecoder: Decoder<CountryOptions> = succeed({}).assign(
  'countryOptions',
  field('country_options', array(countryOptionDecoder)),
);

export const countryOptionsResourceDecoderWithErrors: Decoder<ResourceWithErrors<CountryOptions>> =
  succeed({})
    .assign('payload', field('payload', countryOptionsDecoder))
    .assign('links', field('links', linksDecoder))
    .assign('errors', field('errors', array(errorDecoder)));

export const countryOptionsResourceDecoder: Decoder<Resource<CountryOptions>> =
  resourceDecoder(countryOptionsDecoder);
