import { emptyFragment } from '@execonline-inc/execonline-ui';
import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import { T } from '../../../Translations';
import useAvailabilitiesStore from '../Availabilities/AvailabilitiesStore/UseAvailabilitiesStore';

function ScheduledAvailability() {
  const { availabilities } = useAvailabilitiesStore();
  return fromEmpty(availabilities)
    .map((availabilities) => availabilities.length)
    .map((count) => <T kind="{{count}} Date" count={count} />)
    .getOrElse(emptyFragment);
}

export default observer(ScheduledAvailability);
