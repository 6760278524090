import { Decoder, array, field, number, string, succeed } from 'jsonous';
import { profileResourceDecoder } from '../../ProfileStore/Decoders';
import { resourceDecoder } from '../../Resource/Decoders';
import { Professor, ProfessorResource, ProfessorsResource } from '../Types';

export const professorDecoder: Decoder<Professor> = succeed({})
  .assign('id', field('id', number))
  .assign('email', field('email', string))
  .assign('profile', field('profile', profileResourceDecoder));

export const professorResourceDecoder: Decoder<ProfessorResource> =
  resourceDecoder(professorDecoder);
export const professorsResourceDecoder: Decoder<ProfessorsResource> = resourceDecoder(
  array(professorResourceDecoder),
);
