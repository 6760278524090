import { stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import { Decoder, field, number, oneOf, succeed } from 'jsonous';
import { resourceDecoder } from '../Resource/Decoders';
import { Resource } from '../Resource/Types';
import { NoResumer, ResumeSegment, Resumer } from './Types';

const resumeSegmentDecoder: Decoder<ResumeSegment> = succeed({})
  .assign('kind', field('kind', stringLiteral('resume-segment')))
  .assign('segmentId', field('segment_id', number))
  .assign('moduleId', field('module_id', number))
  .assign('programId', field('program_id', number));

const noResumerDecoder: Decoder<NoResumer> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('no-resumer')),
);

const resumerDecoder: Decoder<Resumer> = oneOf<Resumer>([
  resumeSegmentDecoder.map<Resumer>(identity),
  noResumerDecoder.map<Resumer>(identity),
]);

export const resumerResourceDecoder: Decoder<Resource<Resumer>> = resourceDecoder(resumerDecoder);
