import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from '../../../../Resource/Types';
import { Auth } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import RegistrationFormHeader from '../../RegistrationFormHeader';
import RegistrationView from '../../RegistrationView';
import { SharedInvitationResource } from '../../SharedOpenEnrollment/SharedInvitationResourceStore/Types';
import { AuthSignUp } from '../Types';
import Form from './Form';
import SignUpReactions from './SignUpReactions';
import UserAuthInstructions from './UserAuthInstructions';

interface Props {
  authForm: AuthSignUp;
  sharedInvitation: SharedInvitationResource;
  auth: Auth;
  links: ReadonlyArray<Link>;
  emailAddress: string;
}

const SignUp: React.FC<Props> = ({ authForm, sharedInvitation, auth, links, emailAddress }) => {
  return (
    <>
      <RegistrationView allowLanguagePicker={true}>
        <RegistrationFormHeader
          notifiableStore={authForm.store}
          sharedInvitationResource={sharedInvitation}
        >
          <UserAuthInstructions sharedInvitationResource={sharedInvitation} authForm={authForm} />
        </RegistrationFormHeader>
        <Form auth={auth} emailAddress={emailAddress} authForm={authForm} />
      </RegistrationView>
      <SignUpReactions authForm={authForm} registerResourceLinks={links} />
    </>
  );
};

export default observer(SignUp);
