import { just, Maybe, nothing } from 'maybeasy';
import { action, makeObservable, observable } from 'mobx';
import { Task } from 'taskarian';
import { callApi } from '../../../../Appy';
import { languagesResourceDecoder } from '../../../../ProfileFormStore/Decoders';
import { LanguagesResource } from '../../../../ProfileFormStore/Types';
import { Link } from '../../../../Resource/Types';

export class AvailableLanguagesStore {
  @observable
  languages: Record<string, Maybe<LanguagesResource>> = {};

  constructor() {
    makeObservable(this);
  }

  getLanguages = async (link: Link): Promise<Maybe<LanguagesResource>> => {
    const { href } = link;
    const resource = this.languages[href];
    if (resource) {
      return resource;
    }

    return callApi(languagesResourceDecoder, {}, link)
      .andThen((resource) => {
        const result = just(resource);
        this.setLanguages(link, result);
        return Task.succeed(result);
      })
      .orElse((err) => {
        const result = nothing<LanguagesResource>();
        console.warn('Languages resource failed to fetch', err);
        this.setLanguages(link, result);
        return Task.succeed(result);
      })
      .resolve();
  };

  @action
  setLanguages = (link: Link, languages: Maybe<LanguagesResource>): void => {
    this.languages[link.href] = languages;
  };
}

const availableLanguagesStore = new AvailableLanguagesStore();
export default availableLanguagesStore;
