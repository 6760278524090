import { observer } from 'mobx-react';
import * as React from 'react';
import RegistrationFormHeader from '../../RegistrationFormHeader';
import RegistrationView from '../../RegistrationView';
import { SharedInvitationResource } from '../../SharedOpenEnrollment/SharedInvitationResourceStore/Types';
import LockedAccessStore from '../LockedAccessStore';
import Instructions from './Instructions';
import Notice from './Notice';

interface Props {
  sharedInvitation: SharedInvitationResource;
  store: LockedAccessStore;
}

const LockedAccess: React.FC<Props> = ({ store, sharedInvitation }) => (
  <>
    <RegistrationView allowLanguagePicker={true}>
      <RegistrationFormHeader notifiableStore={store} sharedInvitationResource={sharedInvitation}>
        <Instructions organizationName={sharedInvitation.payload.organization.payload.name} />
      </RegistrationFormHeader>
      <Notice />
    </RegistrationView>
  </>
);

export default observer(LockedAccess);
