import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Typography,
  useOpenable,
} from '@execonline-inc/execonline-ui';
import { OpenableAction } from '@execonline-inc/execonline-ui/dist/hooks/useOpenable/Types';
import { observer } from 'mobx-react';
import ProgramStore from '../../../../ProgramStore';
import { ProgramDetailResource } from '../../../../ProgramStore/Types';
import { T } from '../../../../Translations';
import OptionsProgramModel from './OptionsProgramModel';

interface Props {
  isOpen: boolean;
  onClose: OpenableAction;
  programStore: ProgramStore;
  programDetailResource: ProgramDetailResource;
}

function ConfirmProgramDrop({ isOpen, onClose, programStore, programDetailResource }: Props) {
  const { onOpen, isOpen: optionsIsOpen, onClose: optionsOnClose } = useOpenable();

  return (
    <>
      <Modal size="2xl" open={isOpen} onClose={onClose} className="flex flex-col">
        <Typography variant="subtitleLarge" fontWeight="semibold" className="px-8 pt-6">
          <T kind="Do you want to drop this program?" />
        </Typography>
        <ModalBody className="flex flex-col !px-8">
          <Typography
            color="dark"
            variant="bodyMedium"
            fontWeight="normal"
            className="tracking-wide"
          >
            <T kind="Dropping the program will remove it from your dashboard. You can always re-enroll to the program from your Learning Collection at a later date and pick back up where you left off." />
          </Typography>
        </ModalBody>
        <ModalFooter className="flex flex-row items-center justify-end px-0 !pt-0">
          <Button variant="text" className="hover:bg-transparent" onClick={onClose}>
            <Typography color="black">
              <T kind="No" />
            </Typography>
          </Button>
          <Button
            onClick={() => {
              onOpen();
              onClose();
            }}
          >
            <Typography className="px-6" color="white">
              <T kind="Yes" />
            </Typography>
          </Button>
        </ModalFooter>
      </Modal>
      <OptionsProgramModel
        isOpen={optionsIsOpen}
        onClose={optionsOnClose}
        programStore={programStore}
        programDetailResource={programDetailResource}
      />
    </>
  );
}

export default observer(ConfirmProgramDrop);
