import { nothing } from 'maybeasy';
import { useEffect, useState } from 'react';
import productDetailsStore, { ProductDetailsValue } from '.';
import usePortalExperienceContext from '../../PortalExperience/UsePortalExperienceContext';

function useProductDetailsStore() {
  const experience = usePortalExperienceContext();
  const [productDetails, setProductDetails] = useState<ProductDetailsValue>(nothing());
  useEffect(() => {
    experience
      .andThen((lookupResult) => lookupResult.experienceID.links.productDetails)
      .do((link) => {
        productDetailsStore.getProductDetails(link).then(setProductDetails);
      });
  }, [experience]);

  return {
    productDetails,
  };
}

export default useProductDetailsStore;
