import { stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import { Decoder, array, field, number, oneOf, string, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import { teamSummaryDecoder } from '../../TeamsSummaryStore/Decoders';
import {
  CommunitiesMetadata,
  CommunitiesResource,
  Community,
  CommunityProgram,
  CommunityResource,
  CommunityTeam,
} from '../Types';

const communityTeamDecoder: Decoder<CommunityTeam> = teamSummaryDecoder.assign(
  'kind',
  field('kind', stringLiteral('team')),
);

const communityProgramDecoder: Decoder<CommunityProgram> = succeed({})
  .assign('kind', field('kind', stringLiteral('program')))
  .assign('id', field('id', number))
  .assign('name', field('name', string));

const communityDecoder: Decoder<Community> = oneOf([
  communityTeamDecoder.map<Community>(identity),
  communityProgramDecoder.map<Community>(identity),
]);

const communityResourceDecoder: Decoder<CommunityResource> = resourceDecoder(communityDecoder);

const communitiesMetadataDecoder: Decoder<CommunitiesMetadata> = succeed({})
  .assign('perPage', field('per_page', number))
  .assign('pageCount', field('page_count', number))
  .assign('currentPage', field('current_page', number))
  .assign('resultsCount', field('results_count', number));

export const communitiesResourceDecoder: Decoder<CommunitiesResource> = resourceDecoder(
  array(communityResourceDecoder),
).assign('metadata', field('metadata', communitiesMetadataDecoder));
