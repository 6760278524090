import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from '../../../../Resource/Types';
import SetPasswordStoreReactions from '../SetPasswordStore/Reactions';
import SsoLoginStoreReactions from '../SsoLoginStore/Reactions';
import { AuthSignUp } from '../Types';

interface Props {
  authForm: AuthSignUp;
  registerResourceLinks: ReadonlyArray<Link>;
}

const SignUpReactions: React.FC<Props> = ({ authForm, registerResourceLinks }) => {
  switch (authForm.kind) {
    case 'password-sign-up':
      return <SetPasswordStoreReactions store={authForm.store} links={registerResourceLinks} />;
    case 'sso-sign-up':
      return (
        <SsoLoginStoreReactions
          store={authForm.store}
          auth={authForm.auth}
          registerResourceLinks={registerResourceLinks}
          fireImmediately
        />
      );
  }
};

export default observer(SignUpReactions);
