import { TPlainTextKey } from '../../../../Translations';

export const listOfOptions: TPlainTextKey[] = [
  'I don’t have enough time',
  'It’s not what I expected or needed',
  'The content isn’t engaging or useful',
  'I’m having technical or accessibility issues',
  'It’s too advanced for my needs',
  'It’s not advanced enough for me',
  'Accidental enrollment',
  'Other (Please explain)',
];
