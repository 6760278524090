import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { Decoder, field, string, succeed } from 'jsonous';
import { observer } from 'mobx-react';
import { Task } from 'taskarian';
import { AppyError, callApi } from '../Appy';
import { Base64ConversionError, b64toBlobT } from '../Base64';
import ErrorActionableReaction, { EAProps } from '../ErrorActionableReaction';
import { error, handleAppyError } from '../ErrorHandling';
import { SaveAsFailed, saveAsT } from '../FileSave';
import PdfStore, { State } from '../PdfStore';
import { resourceDecoder } from '../Resource/Decoders';
import { Link, Resource } from '../Resource/Types';

type NotesDownloadFailure = SaveAsFailed | Base64ConversionError | AppyError;

interface Props extends EAProps<PdfStore> {
  store: PdfStore;
  link: Link;
  fileName: string;
}

interface Pdf {
  pdf: string;
}

type PdfResource = Resource<Pdf>;

export const notesPdfDecoder: Decoder<Pdf> = succeed({}).assign('pdf', field('pdf', string));

export const notesPdfResourceDecoder: Decoder<PdfResource> = resourceDecoder(notesPdfDecoder);

const handleDownloadError = (store: PdfStore) => (e: NotesDownloadFailure) => {
  switch (e.kind) {
    case 'b64-conversion-error':
    case 'save-as-failed':
      store.errored(e);
      break;
    default:
      store.errored(error(handleAppyError(e)));
  }
};

@observer
class PdfReactions extends ErrorActionableReaction<PdfStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    switch (state.kind) {
      case 'downloading':
        Task.succeed<NotesDownloadFailure, Link>(state.sourceLink)
          .andThen(callApi(notesPdfResourceDecoder, {}))
          .map((data) => data.payload.pdf)
          .andThen(b64toBlobT('application/pdf'))
          .andThen(saveAsT(state.fileName))
          .fork(handleDownloadError(this.props.store), () => {
            this.props.store.ready(state.fileName, state.sourceLink);
          });
        break;
      case 'waiting':
        this.props.store.ready(this.props.fileName, this.props.link);
        break;
      case 'ready':
        break;
      case 'errored':
        warn(`Unable to download PDF: ${state.error.message}`);
        break;
      default:
        assertNever(state);
    }
  };
}

export default PdfReactions;
