import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { discoveryPortalRoute } from '../../ClientRoutes';
import { discoveryStore } from '../../DiscoveryStore';
import { programsStore } from '../../ProgramsStore';
import { T } from '../../Translations';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import MoreExperiencesGraphic from '../Cutesies/MoreExperiencesGraphic';
import * as style from './style.module.css';

const MoreExperiences: React.FC = () =>
  programsStore.resource
    .map(({ payload }) => {
      switch (payload.userRegistrationsState) {
        case 'no-upcoming-one-or-less-active':
          return (
            <>
              {discoveryStore.learningCollections.map(({ payload }) => {
                return (
                  <div className={style.support} data-test-support-ad={true} key={payload.uuid}>
                    <h2>
                      <T kind="More Experiences Available" />
                    </h2>
                    <div className={style.container}>
                      <div className={style.graphic}>
                        <div className={style.moreExperiencesImage}>
                          <MoreExperiencesGraphic />
                        </div>
                      </div>
                      <div className={style.content}>
                        <h3 className={style.title}>
                          <T kind="Reach your full potential, on your schedule" />
                        </h3>
                        <p className={style.description}>
                          <T kind="Find the next experience to add ..." />
                        </p>
                        <Link
                          to={discoveryPortalRoute(payload.uuid)}
                          className={style.buttonSecondaryWithIcon}
                        >
                          <T kind="Choose Now" />
                          <div className={style.buttonIcon}>
                            <ArrowRightIcon />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          );
        case 'default':
          return <></>;
      }
    })
    .getOrElse(emptyFragment);

export default observer(MoreExperiences);
