import { Maybe } from 'maybeasy';
import { NonEmptyList } from 'nonempty-list';
import { Resource } from '../../Resource/Types';
import { DocumentAsset, DocumentAssetResource } from '../../SegmentStore/Types';
import { ExpertFeedbackResource } from '../../components/ExpertFeedback/Types';
import { LearningPartnerAssignmentResource } from '../../components/LearningPartnerAssignment/Types';
import {
  AnnouncementVideoAsset,
  AnnouncementVideoAssetResource,
} from '../../components/Video/Types';

export interface AnnouncementBase {
  id: string;
  subject: string;
  description: string;
  descriptionHtml: Maybe<string>;
  createdAt: Date;
  programId: number;
}

export interface EmbeddableAssetsAnnouncement extends AnnouncementBase {
  kind: 'embeddable-assets-announcement';
  embeddedDocumentAssets: DocumentAssetResource[];
  announcementVideoAssets: AnnouncementVideoAssetResource[];
}

export interface LearningPartnerAssignmentAnnouncement extends AnnouncementBase {
  kind: 'learning-partner-assignment-announcement';
  embeddedAssignment: LearningPartnerAssignmentResource;
}

export interface ExpertFeedbackAnnouncement extends AnnouncementBase {
  kind: 'expert-feedback-announcement';
  embeddedFeedback: ExpertFeedbackResource;
}

export interface AiWrittenFeedbackAnnouncement extends AnnouncementBase {
  kind: 'ai-written-feedback-announcement';
  embeddedFeedback: ExpertFeedbackResource;
}

export interface UploadManifestAnnouncement extends AnnouncementBase {
  kind: 'upload-manifest-announcement';
}

export type AnnouncementSharedResource =
  | Resource<AnnouncementSharedVideo>
  | Resource<EmbeddedSharedDocument>;

export interface DigitalCertificateAnnouncement extends AnnouncementBase {
  kind: 'digital-certificate-announcement';
  footer: string;
  footerHTML: string;
  announcementSharedResource: Resource<EmbeddedSharedDocument>;
}

const isDocumentResource = (
  resource: AnnouncementSharedResource,
): resource is Resource<EmbeddedSharedDocument> => {
  switch (resource.payload.kind) {
    case 'shared-document':
      return true;
    case 'shared-video':
      return false;
  }
};

interface AnnouncementSharedResourceCata<T> {
  document: (r: Resource<EmbeddedSharedDocument>) => T;
  video: (r: Resource<AnnouncementSharedVideo>) => T;
}

export function announcementSharedResourceCata<T>(
  fold: AnnouncementSharedResourceCata<T>,
  r: AnnouncementSharedResource,
): T {
  return isDocumentResource(r) ? fold.document(r) : fold.video(r);
}

export interface AnnouncementSharedVideo extends AnnouncementVideoAsset {
  kind: 'shared-video';
}

export interface EmbeddedSharedDocument extends DocumentAsset {
  kind: 'shared-document';
}

export type SharedResourceAnnouncement =
  | SharedToLiveMeetingRecordingsAnnouncement
  | SharedToPersonalizedResourcesAnnouncement;

export interface SharedToLiveMeetingRecordingsAnnouncement extends AnnouncementBase {
  kind: 'shared-to-live-meeting-recordings-announcement';
  announcementSharedResource: AnnouncementSharedResource;
}

export interface SharedToPersonalizedResourcesAnnouncement extends AnnouncementBase {
  kind: 'shared-to-personalized-resources-announcement';
  announcementSharedResource: AnnouncementSharedResource;
}

export interface CoachingSurveyAnnouncement extends AnnouncementBase {
  kind: 'coaching-survey-announcement';
}

export type Announcement =
  | EmbeddableAssetsAnnouncement
  | LearningPartnerAssignmentAnnouncement
  | ExpertFeedbackAnnouncement
  | UploadManifestAnnouncement
  | DigitalCertificateAnnouncement
  | SharedToPersonalizedResourcesAnnouncement
  | SharedToLiveMeetingRecordingsAnnouncement
  | CoachingSurveyAnnouncement
  | AiWrittenFeedbackAnnouncement;

export type AnnouncementResource = Resource<Announcement>;

export type Announcements = Maybe<NonEmptyList<AnnouncementResource>>;
export type AnnouncementsResource = Resource<Announcements>;
