import { assertNever } from '@kofno/piper';
import { action, makeObservable, observable } from 'mobx';

interface Waiting {
  kind: 'waiting';
}

interface Initializing {
  kind: 'initializing';
}

interface Expandable {
  kind: 'expandable';
}

interface CollapseStart {
  kind: 'collapse-start';
}
interface Collapsed {
  kind: 'collapsed';
}

interface NonExpandable {
  kind: 'non-expandable';
}

interface ExpandStart {
  kind: 'expand-start';
}

interface Expanded {
  kind: 'expanded';
}

const waiting = (): Waiting => ({ kind: 'waiting' });

const initializing = (): Initializing => ({
  kind: 'initializing',
});

const expandable = (): Expandable => ({ kind: 'expandable' });

const collapseStart = (): CollapseStart => ({ kind: 'collapse-start' });

const collapsed = (): Collapsed => ({ kind: 'collapsed' });

const nonExpandable = (): NonExpandable => ({ kind: 'non-expandable' });

const expandStart = (): ExpandStart => ({ kind: 'expand-start' });

const expanded = (): Expanded => ({ kind: 'expanded' });

export type State =
  | Waiting
  | Initializing
  | Expandable
  | CollapseStart
  | Collapsed
  | NonExpandable
  | ExpandStart
  | Expanded;

class AnnouncementStore {
  @observable
  state: State = waiting();

  constructor() {
    makeObservable(this);
  }

  @action
  initialize = () => {
    this.state = initializing();
  };

  @action
  expandable = () => {
    this.state = expandable();
  };

  @action
  nonExpandable = () => {
    this.state = nonExpandable();
  };

  @action
  collapse = () => {
    switch (this.state.kind) {
      case 'expanded':
      case 'expand-start':
        this.state = collapseStart();
        break;
      case 'waiting':
      case 'initializing':
      case 'expandable':
      case 'non-expandable':
      case 'collapse-start':
      case 'collapsed':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  collapsed = () => {
    switch (this.state.kind) {
      case 'collapse-start':
        this.state = collapsed();
        break;
      case 'expanded':
      case 'expand-start':
      case 'waiting':
      case 'initializing':
      case 'expandable':
      case 'non-expandable':
      case 'collapsed':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  expand = (ref: React.RefObject<HTMLDivElement>) => {
    switch (this.state.kind) {
      case 'expandable':
      case 'collapsed':
      case 'collapse-start':
        this.state = expandStart();
        break;
      case 'expanded':
      case 'expand-start':
      case 'waiting':
      case 'initializing':
      case 'non-expandable':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  expanded = () => {
    switch (this.state.kind) {
      case 'expand-start':
        this.state = expanded();
        break;
      case 'expandable':
      case 'collapsed':
      case 'collapse-start':
      case 'expanded':
      case 'waiting':
      case 'initializing':
      case 'non-expandable':
        break;
      default:
        assertNever(this.state);
    }
  };
}

export default AnnouncementStore;
