import { mapMaybe } from '@execonline-inc/collections';
import { always, identity } from '@kofno/piper';
import { just } from 'maybeasy';
import { CurrentUserResource } from '../../../../CurrentUser/Types';
import { findLink } from '../../../../LinkyLinky';
import { DropdownListItem } from '../../../Dropdown/Types';

export const logoutMenuItem: DropdownListItem = {
  kind: 'link-list-item',
  id: 'log-out',
  destination: 'local',
  href: '/logout',
  name: 'Log out',
};

export const notesMenuItem: DropdownListItem = {
  kind: 'link-list-item',
  id: 'my-notes',
  destination: 'local',
  href: '/notes',
  name: 'My Notes',
};

export const profileMenuItem: DropdownListItem = {
  kind: 'link-list-item',
  id: 'my-profile',
  destination: 'local',
  href: '/profile/edit',
  name: 'My Profile',
};

export const dropdownMenuItems = (
  currentUser: CurrentUserResource,
): ReadonlyArray<DropdownListItem> =>
  mapMaybe(identity, [
    findLink('notes', currentUser.links).map(always(notesMenuItem)),
    findLink('profile', currentUser.links).map(always(profileMenuItem)),
    just(logoutMenuItem),
  ]);
