import {
  Accordion,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
} from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { MenuFilter } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/CommonFilter';
import { allCompetencyOptions } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/CompetencyFilter';
import { allDurationOptions } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/DurationFilter';
import { allLanguageOptions } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/LanguageFilter';
import { allSchoolPartnerOptions } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/SchoolPartnerFilter';
import FiltersButton from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/SidebarFilters/FiltersButton';
import SidebarFooter from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/SidebarFilters/SidebarFooter';
import SidebarHeader from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection/Filters/SidebarFilters/SidebarHeader';
import experienceFiltersStore from './FiltersStore';
import useFilterStores from './UseFilterStores';

function SidebarFilters() {
  const { filtersAreLoaded } = useFilterStores();

  if (!filtersAreLoaded(experienceFiltersStore)) {
    return <></>;
  }

  const {
    filters,
    competencyFilterStore,
    durationFilterStore,
    schoolPartnerFilterStore,
    languageFilterStore,
  } = experienceFiltersStore.state;

  const { appliedCount, clearAll, apply } = experienceFiltersStore;

  return (
    <>
      <FiltersButton value={appliedCount} onClear={clearAll}>
        {({ isOpen, onClose }) => (
          <Drawer open={isOpen} onClose={onClose}>
            <DrawerHeader>
              <SidebarHeader onClose={onClose} />
            </DrawerHeader>
            <DrawerBody>
              <Accordion className="px-4">
                <MenuFilter
                  title="Competency"
                  store={competencyFilterStore}
                  filterOptions={allCompetencyOptions(filters)}
                />
                <MenuFilter
                  title="Time Commitment"
                  store={durationFilterStore}
                  filterOptions={allDurationOptions(filters)}
                />
                <MenuFilter
                  title="Partner"
                  store={schoolPartnerFilterStore}
                  filterOptions={allSchoolPartnerOptions(filters)}
                />
                <MenuFilter
                  title="Language"
                  store={languageFilterStore}
                  filterOptions={allLanguageOptions(filters)}
                />
              </Accordion>
            </DrawerBody>
            <DrawerFooter>
              <SidebarFooter onClear={clearAll} onApply={apply} />
            </DrawerFooter>
          </Drawer>
        )}
      </FiltersButton>
    </>
  );
}

export default observer(SidebarFilters);
