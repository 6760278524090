import { mergeObjectDecoders, stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import { Decoder, array, date, field, maybe, number, oneOf, string, succeed } from 'jsonous';
import { fromArrayMaybe } from 'nonempty-list';
import { linksDecoder, resourceDecoder } from '../../Resource/Decoders';
import { Resource } from '../../Resource/Types';
import { documentAssetDecoder, documentAssetResourceDecoder } from '../../SegmentStore/Decoders';
import {
  announcementVideoAssetDecoder,
  announcementVideoAssetResourceDecoder,
} from '../../VideoStore/Legacy/Decoders';
import { ExpertFeedback, ExpertFeedbackResource } from '../../components/ExpertFeedback/Types';
import { learningPartnerAssignmentDecoder } from '../../components/LearningPartnerAssignment/Types';
import {
  AiWrittenFeedbackAnnouncement,
  Announcement,
  AnnouncementBase,
  AnnouncementSharedResource,
  AnnouncementSharedVideo,
  AnnouncementsResource,
  CoachingSurveyAnnouncement,
  DigitalCertificateAnnouncement,
  EmbeddableAssetsAnnouncement,
  EmbeddedSharedDocument,
  ExpertFeedbackAnnouncement,
  LearningPartnerAssignmentAnnouncement,
  SharedToLiveMeetingRecordingsAnnouncement,
  SharedToPersonalizedResourcesAnnouncement,
  UploadManifestAnnouncement,
} from './Types';

const expertFeedbackDecoder: Decoder<ExpertFeedback> = succeed({})
  .assign('id', field('id', number))
  .assign('title', field('title', string))
  .assign('createdAt', field('created_at', date));

const expertFeedbackResourceDecoder: Decoder<ExpertFeedbackResource> = succeed({})
  .assign('payload', field('payload', expertFeedbackDecoder))
  .assign('links', field('links', linksDecoder));

const announcementBaseDecoder: Decoder<AnnouncementBase> = succeed({})
  .assign('id', field('id', string))
  .assign('subject', field('subject', string))
  .assign('description', field('description', string))
  .assign('descriptionHtml', field('description_html', maybe(string)))
  .assign('programId', field('program_id', number))
  .assign('createdAt', field('created_at', date))
  .map<AnnouncementBase>(identity);

const embeddableAssetsAnnouncementDecoder: Decoder<EmbeddableAssetsAnnouncement> =
  announcementBaseDecoder
    .assign('kind', field('kind', stringLiteral('embeddable-assets-announcement')))
    .assign(
      'embeddedDocumentAssets',
      field('embedded_document_assets', array(documentAssetResourceDecoder)),
    )
    .assign(
      'announcementVideoAssets',
      field('embedded_video_assets', array(announcementVideoAssetResourceDecoder)),
    );

const learningPartnerAssignmentAnnouncementDecoder: Decoder<LearningPartnerAssignmentAnnouncement> =
  announcementBaseDecoder
    .assign('kind', field('kind', stringLiteral('learning-partner-assignment-announcement')))
    .assign('embeddedAssignment', field('embedded_assignment', learningPartnerAssignmentDecoder));

const expertFeedbackAnnouncementDecoder: Decoder<ExpertFeedbackAnnouncement> =
  announcementBaseDecoder
    .assign('kind', field('kind', stringLiteral('expert-feedback-announcement')))
    .assign('embeddedFeedback', field('embedded_feedback', expertFeedbackResourceDecoder));

const aiWrittenFeedbackAnnouncementDecoder: Decoder<AiWrittenFeedbackAnnouncement> =
  announcementBaseDecoder
    .assign('kind', field('kind', stringLiteral('ai-written-feedback-announcement')))
    .assign('embeddedFeedback', field('embedded_feedback', expertFeedbackResourceDecoder));

const uploadManifestAnnouncementDecoder: Decoder<UploadManifestAnnouncement> =
  announcementBaseDecoder.assign(
    'kind',
    field('kind', stringLiteral('upload-manifest-announcement')),
  );

const embeddedSharedDocumentDecoder: Decoder<Resource<EmbeddedSharedDocument>> = resourceDecoder(
  mergeObjectDecoders(
    succeed({}).assign('kind', succeed<'shared-document'>('shared-document')),
    documentAssetDecoder,
  ),
);

const announcementSharedVideoDecoder: Decoder<Resource<AnnouncementSharedVideo>> = resourceDecoder(
  mergeObjectDecoders(
    succeed({}).assign('kind', succeed<'shared-video'>('shared-video')),
    announcementVideoAssetDecoder,
  ),
);

const announcementSharedResourceDecoder = oneOf<AnnouncementSharedResource>([
  embeddedSharedDocumentDecoder.map<AnnouncementSharedResource>(identity),
  announcementSharedVideoDecoder.map<AnnouncementSharedResource>(identity),
]);

const sharedToLiveMeetingRecordingsAnnouncementDecoder: Decoder<SharedToLiveMeetingRecordingsAnnouncement> =
  announcementBaseDecoder
    .assign('kind', field('kind', stringLiteral('shared-to-live-meeting-recordings-announcement')))
    .assign(
      'announcementSharedResource',
      field('embedded_shared_resource', announcementSharedResourceDecoder),
    );

const sharedToPersonalizedResourcesAnnouncementDecoder: Decoder<SharedToPersonalizedResourcesAnnouncement> =
  announcementBaseDecoder
    .assign('kind', field('kind', stringLiteral('shared-to-personalized-resources-announcement')))
    .assign(
      'announcementSharedResource',
      field('embedded_shared_resource', announcementSharedResourceDecoder),
    );

const digitalCertificateAnnouncementDecoder: Decoder<DigitalCertificateAnnouncement> =
  announcementBaseDecoder
    .assign('kind', field('kind', stringLiteral('digital-certificate-announcement')))
    .assign('footer', field('footer', string))
    .assign('footerHTML', field('footer_html', string))
    .assign(
      'announcementSharedResource',
      field('embedded_shared_resource', embeddedSharedDocumentDecoder),
    );

const coachingSurveyAnnouncementDecoder: Decoder<CoachingSurveyAnnouncement> =
  announcementBaseDecoder.assign(
    'kind',
    field('kind', stringLiteral('coaching-survey-announcement')),
  );

const announcementDecoder: Decoder<Announcement> = oneOf<Announcement>([
  embeddableAssetsAnnouncementDecoder.map<Announcement>(identity),
  learningPartnerAssignmentAnnouncementDecoder.map<Announcement>(identity),
  expertFeedbackAnnouncementDecoder.map<Announcement>(identity),
  uploadManifestAnnouncementDecoder.map<Announcement>(identity),
  sharedToLiveMeetingRecordingsAnnouncementDecoder.map<Announcement>(identity),
  sharedToPersonalizedResourcesAnnouncementDecoder.map<Announcement>(identity),
  digitalCertificateAnnouncementDecoder.map<Announcement>(identity),
  coachingSurveyAnnouncementDecoder.map<Announcement>(identity),
  aiWrittenFeedbackAnnouncementDecoder.map<Announcement>(identity),
]);

const announcementResourceDecoder = resourceDecoder(announcementDecoder);

export const announcementsResourceDecoder: Decoder<AnnouncementsResource> = resourceDecoder(
  array(announcementResourceDecoder).map((a) => fromArrayMaybe(a)),
).map<AnnouncementsResource>(identity);
