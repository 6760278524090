import { emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import { findLink } from '../../../LinkyLinky';
import { HtmlView } from '../../../Native/AEP/Common/Experience';
import useCaseStore from '../../../RegistrationInvitation/UseCaseStore';

function WelcomeMessage() {
  const useCaseState = useCaseStore.state;

  return useCaseState.kind !== 'use-case-store-state-loaded' ? (
    <></>
  ) : (
    <div className="sm:px-0">
      <div className="container mx-auto box-border w-full items-center justify-between px-6 pb-8 pt-11 md:px-16">
        <Typography className="text-black" variant="h4" as="h1">
          <AlreadyTranslated content={useCaseState.useCase.payload.name} />
        </Typography>
        <div className="flex flex-col justify-between md:flex-row">
          <div className="w-full justify-center">
            <HtmlView description={useCaseState.useCase.payload.description} className="md:mr-20" />
          </div>
          <div className="mt-4 h-full max-h-96 w-full max-w-sm overflow-hidden">
            {findLink('learning-collection-logo', useCaseState.useCase.links)
              .map((link) => (
                <img className="h-auto max-h-96 max-w-96 object-cover" src={link.href} />
              ))
              .getOrElse(emptyFragment)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(WelcomeMessage);
