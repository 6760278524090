import { Button, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { handleEnterKeyPress } from '../../../../Accessibility/KeyboardEvents';
import { T } from '../../../../Translations';
import {
  EmailField,
  ForgotPasswordField,
  PasswordField,
  TermsAndConditionsField,
} from '../../../Form';
import { Auth } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import UsePasswordStore from '../UsePasswordStore';

interface Props {
  usePasswordStore: UsePasswordStore;
  auth: Auth;
  emailAddress: string;
}

function PasswordForm({ usePasswordStore, auth, emailAddress }: Props) {
  const submit = (store: UsePasswordStore) => (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.creating();
  };

  switch (auth.kind) {
    case 'password-auth':
      return (
        <form
          data-testid="sign-in-form"
          className="flex w-[600px] max-w-full flex-col gap-4 md:gap-6"
          onSubmit={submit(usePasswordStore)}
        >
          <div className="flex flex-col gap-4 sm:flex-row md:gap-6">
            <EmailField data-testid="sign-in-form_email-text-field" value={emailAddress} disabled />
            <PasswordField
              data-testid="sign-in-form_password-text-field"
              value={usePasswordStore.password.getOrElseValue('')}
              onKeyDown={handleEnterKeyPress(usePasswordStore.creating)}
              onChange={usePasswordStore.setPassword}
            />
          </div>
          {auth.termsAccepted || (
            <TermsAndConditionsField
              checked={usePasswordStore.termsAcceptance}
              onChange={usePasswordStore.setTermsAcceptance}
            />
          )}
          <div className="mt-6">
            <Button
              data-testid="sign-in-form_continue-button"
              type="submit"
              size="lg"
              radius="sm"
              color="success"
            >
              <T kind="Continue" />
            </Button>
          </div>
          <div>
            <ForgotPasswordField />
          </div>
        </form>
      );
    case 'locked-access-auth':
    case 'sso-auth':
      return emptyFragment();
  }
}

export default observer(PasswordForm);
